export default {
    getCookie (name) {
        try {
            return decodeURIComponent(document.cookie)
                .split(/;/)
                .map(cookie => cookie.trim())
                .find(cookie => cookie.startsWith(name + '='))
                .split(/=/)[1]
        } catch (ex) {
            return ''
        }
    },

    getParam (name) {
        return (new URLSearchParams(window.location.search)).get(name) || ''
    },

    getParams () {
        return Array.from((new URLSearchParams(window.location.search)).entries())
            .reduce((acc, curr) => {
                acc[curr[0]] = curr[1]

                return acc
            }, {})
    }
}
