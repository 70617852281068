<template>
<nav class="recommendation-nav">
    <h3 class="text text-semibold text-center mb-2">{{title}}</h3>
    <div class="recommendation-nav__steps">
        <a
            class="recommendation-nav__link recommendation-nav__link--back"
            :class="{ 'recommendation-nav__link--disabled': !hasPrevious }"
            @click.prevent="navigate('previous')"
        >
            <i class="chevron chevron-dark"></i>
            <span class="sr-only">Voltar</span>
        </a>
        <div class="recommendation-nav__progress-bar">
            <div class="progress-bar green">
                <i :style="{ width: percentage + '%' }"></i>
                <span class="sr-only">Preenchido: {{ percentage }}%</span>
            </div>
        </div>
        <a
            class="recommendation-nav__link recommendation-nav__link--forward"
            :class="{ 'recommendation-nav__link--disabled': !hasNext }"
            @click.prevent="navigate('next')"
        >
            <i class="chevron chevron-dark"></i>
            <span class="sr-only">Seguir</span>
        </a>
    </div>
</nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'Nav',

    data () {
        return {
            slugIndex: undefined,
            index: undefined
        }
    },

    beforeUpdate () {
        this.setIndex()
    },

    props: {
        percentage: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: false
        }
    },

    computed: {
        ...mapState('DAStore', ['questionsHistory']),

        hasNext () {
            const index = this.questionsHistory.indexOf(this.$route.params.slug) + 1
            return index < this.questionsHistory.length
        },

        hasPrevious () {
            return this.index > 1
        }
    },

    methods: {
        ...mapMutations('DAStore', [
            'setNavigationTransition'
        ]),

        navigate (value) {
            this.setNavigationTransition(value === 'previous')
            this.$router.go(value === 'previous' ? -1 : 1)
        },

        setIndex() {
            this.slugIndex = this.questionsHistory.indexOf(this.$route.params.slug)
            this.index = this.slugIndex === -1 ? 1 : this.slugIndex + 1
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/recommendation/settings/_colors.scss";

.recommendation-nav {
    max-width: 100%;
    padding: 8px 16px;
}

.recommendation-nav__steps {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    min-height: 32px;
}

.recommendation-nav__link {
    @apply cursor-pointer;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    flex-basis: 38px;
    height: 32px;
    padding-left: 2px;
    padding-right: 2px;

    &.active {
        .step {
            background-color: $color-success-500;
        }
    }

    &--back, &--forward {
        .chevron {
            position: relative;
            transition: transform .35s ease-out;
        }
    }

    &--back {
        .chevron {
            transform: rotate(-45deg) translate3d(0,0,0);
        }

        &:hover {
            .chevron {
                transform: rotate(-45deg) translate3d(-.2rem,-.2rem,0);
            }
        }
    }

    &--forward {
        .chevron {
            transform: rotate(135deg) translate3d(0,0,0);
        }

        &:hover {
            .chevron {
                transform: rotate(135deg) translate3d(-.2rem,-.2rem,0);
            }
        }
    }
}

.recommendation-nav__link--disabled {
    opacity: 0.3;
    pointer-events: none;
}
</style>
