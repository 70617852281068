import cards from '@/services/cards'

const mutations = {
    CLEAR_SELECTED: 'clearSelected',
    SET_CARDS_LIST: 'setCardsList',
    SET_COMPARISION: 'setComparision',
    TOGGLE_SELECTED: 'toggleSelected'
}

export default {
    namespaced: true,

    state: () => ({
        cards: [],
        comparision: null,
        selected: []
    }),

    actions: {
        compare ({ commit }, slugs) {
            return cards.compare(slugs).then(comparision => {
                commit(mutations.SET_COMPARISION, { comparision })

                return comparision
            })
        },

        listCards ({ commit }) {
            return cards.lists().then(list => {
                commit(mutations.SET_CARDS_LIST, { cards: list })

                return list
            })
        }
    },

    mutations: {
        [mutations.CLEAR_SELECTED] (state) {
            state.selected = []
        },

        [mutations.SET_CARDS_LIST] (state, payload) {
            state.cards = payload.cards
        },

        [mutations.SET_COMPARISION] (state, payload) {
            state.comparision = payload.comparision
        },

        [mutations.TOGGLE_SELECTED] (state, payload) {
            state.selected.includes(payload.slug)
                ? state.selected.splice(state.selected.indexOf(payload.slug), 1)
                : state.selected.push(payload.slug)
        }
    }
}
