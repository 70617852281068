import Dimensions from '../types/dimensions'

function kruxLoaderCallback(err) {
    if (err) {
        console.log("Krux page:load error: " + err); // eslint-disable-line no-console
    }
}

function parseGAValue(question, value) {
    if (question.type === "multiple") {
        value = value.join(",");
    }

    if (question.type === "boolean") {
        value = value ? "yes" : "no";
    }

    return value;
}

export default {
    activateOptimize() {
        if (typeof window === "undefined") return;
        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({ event: "optimize.activate" });
        }
    },

    push(category, action, label = null, event = "eventTracking") { // eslint-disable-line
        if (typeof window === "undefined" || !window.gtag) return;
        if (typeof window.dataLayer !== "undefined") {
            window.gtag("event", action, {
                event_category: category,
                event_label: label
            });
        } else if (typeof window._gaq !== "undefined") {
            window._gaq.push(["_trackEvent", category, action, label]);
        }
    },

    pushInteraction(category, action, label, value = 0, ni = false) {
        if (!window?.dataLayer) return;

        window.dataLayer.push({
            event: "interaction",
            interaction: { category, action, label, value, ni }
        });
    },

    /**
     * @todo Refactoring to this.push
     */
    pushWithValue(
        category,
        action,
        label = null,
        value = null,
        event = "eventTracking" // eslint-disable-line
    ) {
        if (typeof window === "undefined" || !window.gtag) return;
        if (typeof window.dataLayer !== "undefined") {
            window.gtag("event", action, {
                event_category: category,
                event_label: label,
                value
            });
        } else if (typeof _gaq !== "undefined") {
            window._gaq.push(["_trackEvent", category, action, label, value]);
        }
    },

    pushAnswer (statements = [], quiz = 'cartao-credito') {
        if (!window?.dataLayer) return

        window.dataLayer.push({
            event: 'answerQuiz',
            quiz: {
                name: quiz,
                statements
            }
        })
    },

    pushAnswerLegacy(category, question, value) {
        if (typeof window === "undefined" || !window.dataLayer) return;
        this.push(category, question, parseGAValue(question, value));
        window.dataLayer.push({
            question_category: category,
            question_statement: question,
            question_answer: parseGAValue(question, value)
        });
    },

    pushAnswerOnDito(step, question, response) {
        if (typeof window === "undefined") return;
        if (typeof window.dito === "undefined") return;

        window.dito.track({
            action: "respondeu-questao",
            data: {
                app: "plusdin",
                passo: step,
                pergunta: question,
                resposta: response,
                origem: window.location.origin + window.location.pathname
            }
        });
    },

    pushConversion(cardName, url, issuer) {
        if (typeof window === "undefined" || !window.dataLayer) return;
        window.dataLayer.push({
            conversion_card_name: cardName,
            conversion_card_url: url,
            conversion_card_issuer: issuer
        });
        this.trackOnKrux();
    },

    pushDimension(key, value, ni = true) {
        if (!window?.dataLayer) return

        window.dataLayer.push({
            event: "dimension",
            dimension: { [key]: value, ni }
        });
    },

    pushDimensions(dimensions) {
        if (!window?.dataLayer) return

        window.dataLayer.push({
            event: "dimension",
            dimension: { ...dimensions, ni: true }
        })
    },

    pushLead(hash) {
        if (typeof window === "undefined" || !window.dataLayer) return;
        window.dataLayer.push({ hashed_email: hash });
        this.trackOnKrux();
    },

    pushResult(cardName, score, url, issuer) {
        if (typeof window === "undefined" || !window.dataLayer) return;
        window.dataLayer.push({
            result_card_name: cardName,
            result_card_score: score,
            result_card_url: url,
            result_card_issuer: issuer
        });
        this.trackOnKrux();
    },

    async sendLeadToQuizTracker(lead) {
        try {
            const response = await fetch(
                `${process.env.VUE_APP_LEADS_API_URL}/plusdin/leads`,
                {
                    method: "post",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(lead)
                }
            );
            await response.json();
        } catch (error) {
            console.log("Error sending lead to quiz tracker"); // eslint-disable-line no-console
        }
    },

    sendLeadToTD(lead) {
        if (typeof window === "undefined") return;
        if (typeof td === "undefined") return;

        window.td.trackEvent("quiz_data_unum", lead);
    },

    async sendNewsletterLeadToQuizTracker(lead) {
        try {
            const response = await fetch(
                `${process.env.VUE_APP_LEADS_API_URL}/newsletters`,
                {
                    method: "post",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(lead)
                }
            );
            await response.json();
        } catch (error) {
            console.log("Error sending newsletter lead to quiz tracker"); // eslint-disable-line no-console
        }
    },

    trackConversion() {
        if (typeof window === "undefined" || !window.dataLayer || !window.gtag)
            return;
        window.gtag("event", "conversion", {
            send_to: "AW-741735734/_mVDCJTqp7gBELb61-EC"
        });
        window.gtag("event", "conversion", {
            send_to: "AW-638879628/cb5SCI7wnuEBEIyP0rAC"
        });
    },

    trackOnKrux() {
        if (typeof window === "undefined") return;
        if (typeof Krux !== "undefined") {
            window.Krux("page:load", kruxLoaderCallback, { pageView: false });
        }
    },

    trackPage(route) {
        if (typeof window === "undefined") return;
        if (typeof gtag === "undefined") return;

        window.gtag("config", process.env.VUE_APP_GA_ID, {
            custom_map: {
                dimension1: "etus_id",
                dimension2: "cp_session",
                dimension3: "ga_id",
                dimension4: "lead",
                dimension5: "fb_campaign_id",
                dimension6: "client_id",
                dimension7: "session_id",
                dimension8: "adset_id",
                dimension9: "ad_id",
                dimension10: "tb_clid",
                dimension11: "site_an",
                dimension12: "card_requested",
                dimension13: "recommendation_rejected",
                dimension14: "card_recommended",
                dimension15: "cc_quiz_abandoned",
                dimension16: "no_annual_fees",
                dimension17: "travel_miles",
                dimension18: "rewards",
                dimension19: "cashback",
                dimension20: "discounts",
                dimension21: "travel_lounges",
                dimension22: "income",
                dimension23: "credit_status",
                dimension24: "is_international",
                dimension25: "occupation",
                dimension26: "content_author",
                dimension27: "content_category",
                dimension28: "content_publish_at",
                dimension29: "available_time",
                dimension30: "accept_annual_fee",
                dimension31: "airlines_habits",
                dimension32: "favorite_airline",
                dimension33: "access_vip_lounges",
                dimension34: "tickets_discounts",
                dimension35: "preferential_boarding",
                dimension36: "longer_tickets_installment",
                dimension37: "free_travel_insurance",
                dimension38: "credit_card_expenses",
                dimension39: "purchase_on_internet",
                dimension40: "buy_on_stores",
                dimension41: "accounts_on_banks",
                dimension42: "want_credit_and_debit",
                dimension43: "want_money_withdraw",
                dimension44: "want_24h_bank",
                dimension45: "want_android_apple_pay",
                dimension46: "has_credit_card",
                dimension47: "need_bank_branch_near",
                dimension48: "zipcode",
                dimension49: "common_travel",
                dimension50: "travel_purpose",
                dimension51: "aff_id"
            },
            page_path: route.fullPath,
            send_page_view: true,
            transport_type: "beacon"
        });
    },

    trackPageViewDimensions (route) {
        const {
            ga_id: gaId,
            session_id: sessionId,
            etus_id: etusId,
            client_id: clientId,
            adset_id: adsetId,
            ad_id: adId,
            aff_id: affId
        } = route.query
        const dimensions = {}

        if (adId !== undefined) {
            dimensions[Dimensions.AD_ID] = adId
        }

        if (gaId !== undefined) {
            dimensions[Dimensions.GA_ID] = gaId
        }

        if (adsetId !== undefined) {
            dimensions[Dimensions.ADSET_ID] = adsetId
        }

        if (sessionId !== undefined) {
            dimensions[Dimensions.SESSION_ID] = sessionId
        }

        if (etusId !== undefined) {
            dimensions[Dimensions.ETUS_ID] = etusId
        }

        if (clientId !== undefined) {
            dimensions[Dimensions.CLIENT_ID] = clientId
        }

        if (affId !== undefined) {
            dimensions[Dimensions.AFF_ID] = affId
        }

        this.pushDimensions(dimensions)
    },

    trackVirtualPage(slug) {
        if (!window?.dataLayer) return;

        window.dataLayer.push({
            event: "virtual-pageview",
            "virtual-pageview": {
                page: `${document.location.pathname}/${slug}`
            }
        });
    },

    trackPageOnKrux() {
        if (typeof window === "undefined") return;
        if (typeof window.Krux !== "undefined") {
            window.Krux("page:load", kruxLoaderCallback, { pageView: true });
        }
    },

    trackPageOnTD() {
        if (typeof window === "undefined") return;
        if (typeof td === "undefined") return;

        const params = new URLSearchParams(window.location.search)

        window.td.set("pageviews", {
            app: "plusdin",
            kuid: window.Krux.user,
            utm_source: window.gam_utmsource,
            utm_campaign: window.gam_utmcampaign,
            utm_medium: window.gam_utmmedium,
            utm_term: window.gam_utmterm,
            utm_content: window.gam_utmcontent,
            placement: window.gam_placement,
            gclid: window.gam_gclid,
            fbadg: window.gam_fbadg,
            sub_id: params.get("sub_id"),
            aff_id: params.get("aff_id")
        });
        window.td.trackPageview("pageviews");
    }
};
