<template>
<section>
    <div class="card-comparator__dropdown">
        <img
            :src="selected.image_file.url"
            v-if="selected"
            class="card-comparator__dropdown-selected-flag"
        />
        <input
            :class="{ 'card-comparator__dropdown-input--selected': selected }"
            @click="open"
            @input="changed"
            v-model.trim="term"
            class="card-comparator__dropdown-input"
            placeholder="Escolher cartão"
            type="text"
        >
        <div
            v-if="showNotice && !isFiltering"
            class="card-comparator__dropdown-list card-comparator__dropdown-list--large"
        >
            Digite o nome do cartão ou
            <strong @click="showListDropdown">veja uma lista</strong>
            com todos
        </div>
        <div
            v-if="shouldShowList"
            class="card-comparator__dropdown-list"
        >
            <div
                @click="select(card)"
                :key="card.id"
                v-for="card in filteredOptions"
                class="card-comparator__dropdown-item"
            >
                <img
                    :src="card.image_file.url"
                    class="card-comparator__dropdown-item-flag"
                />
                {{ card.name }}
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    props: {
        options: Array,
        value: Object
    },

    data () {
        return {
            selected: null,
            showList: false,
            showNotice: false,
            term: ''
        }
    },

    computed: {
        filteredOptions () {
            return this.options.filter(this.optionsFilter)
        },

        isFiltering () {
            return this.term.length >= 2 && !this.selected
        },

        selectedImage () {
            return this.selected && this.selected.image_file.url
        },

        shouldShowList () {
            return this.isFiltering || this.showList
        }
    },

    methods: {
        changed () {
            this.selected = null
        },

        open () {
            if (this.selected) {
                this.showList = true
            } else {
                this.showNotice = true
            }
        },

        optionsFilter (option) {
            return option.name.toLowerCase().includes(this.term.toLowerCase()) ||
                this.selected
        },

        select (value) {
            this.selected = value
            this.term = value.name
            this.showList = false
            this.showNotice = false

            this.$emit('selected', value)
        },

        setSelected (value) {
            if (value) {
                this.selected = value
                this.term = value.name
            } else {
                this.selected = null
                this.term = ''
            }
        },

        showListDropdown () {
            this.showList = true
            this.showNotice = false
        }
    },

    watch: {
        value: {
            handler: 'setSelected',
            immediate: true
        }
    }
}
</script>

<style lang="scss">
.card-comparator__dropdown {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.card-comparator__dropdown:after {
    @apply pointer-events-none;
    content: '';
    cursor: pointer;
    position: absolute;
    bottom: 30%;
    left: 90%;
    width: 10px;
    height: 10px;
    background: url("~@/images/card-comparator/arrow-select.png") no-repeat;
}

.card-comparator__dropdown-selected-flag {
    @apply absolute;
    @apply object-center;
    @apply object-contain;
    height: 30px;
    left: 7px;
    top: 7px;
    width: 30px;
}

.card-comparator__dropdown-input {
    cursor: pointer;
    width: 100%;
    padding: 10px 20px 10px 10px;
    border: 1px solid #CACACC;
    line-height: 1.5em;
    outline: none;
    border-radius: 4px;
    background-color: transparent;
}

.card-comparator__dropdown-input:focus {
    border-color: #5588F1;
}

.card-comparator__dropdown-input--selected {
    @apply pl-12;
}

.card-comparator__dropdown-button {
    max-width: 72px;
    max-height: 43px;
    margin-top: 35px;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    padding: 0px 41px 8px 15px;
    border: 1px solid #CACACC;
    outline: none;
    border-radius: 4px;
    background-color: transparent;
}

.card-comparator__dropdown-button:after {
    content: '';
    cursor: pointer;
    position: absolute;
    bottom: 20%;
    left: 20%;
    width: 10px;
    height: 10px;
    background: url("~@/images/card-comparator/arrow-select.png") no-repeat;
    z-index: -1;
}

.card-comparator__dropdown-list {
    position: absolute;
    max-height: 250px;
    width: 211%;
    overflow: scroll;
    overflow-x: auto;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    z-index: 5;

    .card-comparator__second-col & {
        right: 0;
    }
}

.card-comparator__dropdown-list--large {
    font-size: 16px;
    line-height: 180%;
    padding: 25px;

    strong {
        cursor: pointer;
        border-bottom: 2px solid #00E169;
    }

    &.focus {
        display: block;
    }
}

.card-comparator__dropdown-item {
    display: flex;
    width: 100%;
    padding: 11px 16px;
    cursor: pointer;
}

.card-comparator__dropdown-item:hover {
    background: #F7F6F5;
}

.card-comparator__dropdown-item-flag {
    max-width: 24px;
    max-height: 18px;
    margin: auto 12px auto 0px;
    padding: 0 !important;
}

.card-comparator__card-image {
    margin: auto;
    margin-top: 18px;
}

.card-comparator__card-close {
    display: none;
}

.card-comparator__card-name {
    @apply mb-6;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    text-align: left;
}

.card-comparator__card-btn {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    background: #00E169;
    border-radius: 4px;
    width: 100%;
    height: 42px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
}

.card-comparator__card-btn--reduced {
    width: 50%;
}

.card-comparator__card-btn:hover {
    background: #00C16D;
    color: #FFFFFF;
}

@media screen and (min-width: $screen-tablet-min-width) {
    .card-comparator__card-name {
        text-align: center;
    }

    .card-comparator__dropdown:after {
        left: 94%;
    }

    .card-comparator__dropdown-list {
        width: 100%;

        .card-comparator__second-col & {
            right: auto;
        }
    }

    .card-comparator__card-close {
        display: block;
        position: absolute;
        left: 87%;
        width: 21px;
        height: 21px;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10.5' cy='10.5' r='10.5' fill='%237F7F85'/%3E%3Cpath d='M15 6l-9 9M6 6l9 9' stroke='%23fff' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50%;
    }
}
</style>
