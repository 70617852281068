import Repository from '@/services/repository'
import axios from 'axios'

class DigitalAccounts extends Repository {
    bySlug (slug) {
        return axios.get(`${this.baseUrl}/bank-accounts/${slug}`)
            .then(response => response.data)
    }
}

export default new DigitalAccounts()
