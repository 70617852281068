import axios from 'axios'

export default function (email) {
    if (!email) return true

    const url = `${process.env.VUE_APP_COMMON_URL}/validations/email`

    return axios.get(`${url}?email=${encodeURIComponent(email)}`)
        .then(response => (
            ['deliverable', 'risky'].includes(response.data.result) &&
            !response.data.disposable
        ))
        .catch(() => true)
}
