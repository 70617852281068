import store from '../../store'

const digitalAccounts = {
    question (to, from, next) {
        if (to.params.slug === 'inicio') {
            next({ query: to.query })
        } else {
            const question = store.state?.DAStore?.questions.find(one => one.slug === to.params.slug)

            if (!question) {
                from.params.slug = 'inicio'
                next({
                    ...from,
                    name: 'v1.digital-accounts.recommendation.questions.question',
                    query: to.query
                })
            } else {
                const response = store.state?.DAStore?.response[question.key]
                const index = store.state?.DAStore?.questions.indexOf(question)
                const shouldContinue = index === 0 || (typeof response !== 'undefined')
    
                if (shouldContinue) {
                    next({ query: to.query })
                }
                else {
                    if (from.params.slug === undefined) {
                        from.params.slug = 'inicio'
                    }

                    next({
                        ...from,
                        name: 'v1.digital-accounts.recommendation.questions.question',
                        query: to.query
                    })
                }
            }
        }
    },

    gate (to, from, next) {
        next()
    }
}

export default digitalAccounts
