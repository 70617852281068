<template>
    <section>
        <Nav :percentage="percentage"/>
        <transition
            mode="out-in"
            :name="navigationTransition"
        >
            <main class="recommendation__main" :key="question.title">
                <header class="recommendation__header wrapper">
                    <h2
                        v-if="isFirstQuestion"
                        class="text-center text leading-6"
                    >
                        Responda às perguntas a seguir para que nossa inteligência artificial possa
                        encontrar o <b>cartão de crédito</b> perfeito para você.
                    </h2>
                    <h1
                        v-html="question.title"
                        class="text-3xl text-center text text-normal"
                    ></h1>
                </header>
                <section
                    :class="classLayout"
                    :key="question.key"
                    class="recommendation__card-list"
                >
                    <template v-if="question.type !== 'range' && question.type !== 'form'">
                        <Option
                            :key="answer.value"
                            :option="answer"
                            :question="question"
                            @answered="answered"
                            @answeredMultiple="answeredMultiple"
                            :value="questionValue"
                            v-for="answer in processedOptions"
                        />
                    </template>
                    <template v-else-if="question.type === 'range'">
                        <Range
                            :key="answer.value"
                            :option="answer"
                            :question="question"
                            @rangeValue="handleRangeValue"
                            v-for="answer in processedOptions"
                        />
                    </template>
                    <template v-else-if="question.type === 'form'">
                        <Form
                            :key="answer.value"
                            :option="answer"
                            :question="question"
                            @formValue="handleFormValue"
                            v-for="answer in processedOptions"
                        />
                    </template>
                    <div
                        v-if="question.by_pass_option"
                        class="recommendation__card-skip"
                    >
                        <button
                            @click.prevent="answer"
                            type="button"
                            class="button--lined"
                        >{{ question.by_pass_option }}
                        </button>
                    </div>
                    <div
                        v-if="shouldShowContinue"
                        class="recommendation__card-continue"
                    >
                        <button
                            :disabled="unavailable"
                            @click.prevent="answer"
                            type="button"
                            class="button button-default button-primary"
                        >
                            Continuar
                        </button>
                    </div>
                </section>
            </main>
        </transition>
    </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Form from '@/components/new-quiz/Form'
import Nav from '@/components/new-quiz/Nav'
import Option from '@/components/new-quiz/Option'
import Range from '@/components/new-quiz/Range'

export default {
    name: 'Quiz',

    components: {
        Form,
        Nav,
        Option,
        Range
    },

    props: {
        percentage: {
            type: Number,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        step: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            unavailable:  true,
            value: []
        }
    },

    mounted() {
        if (window.location.search.indexOf("healthcheck") > -1)
            this.unavailable = false
    },

    computed: {
        ...mapGetters([
            'currentHasResponse',
            'isFirstQuestion',
            'nextQuestion',
            'lastQuizResponse'
        ]),
        
        ...mapState(['response']),

        ...mapState('DAStore', ['navigationTransition']),

        classLayout () {
            return {
                'multiple': this.question.type === 'multiple',
                'small': this.question.style === 'small',
                'large': this.question.style === 'large',
                'vertical': this.question.layout === 'vertical'
            }
        },

        fromValueAsArray () {
            let fromValue = this.response[this.step.exclude_options_from]

            return Array.isArray(fromValue) ? fromValue : [fromValue]
        },

        questionValue () {
            return this.response[this.question.key]
        },

        shouldShowContinue () {
            return !['single', 'boolean'].includes(this.question.type)
        },

        processedOptions () {
            if (!this.step.exclude_options_from) {
                if (Object.keys(this.lastQuizResponse).length === 0) {
                    return this.question.options
                } else {
                    let callable = ''
                    if (this.question.type === 'range' || this.question.type === 'form') {
                        callable = 'processRangeOrFormOption'
                    } else {
                        callable = 'processSingleOption'
                    }
                    return this.question.options.map((item, index) => {
                        if (this.question.key in this.lastQuizResponse) {
                            this[callable](item, index)
                        } else {
                            item.wasChoosen = false
                        }
                        return item
                    })
                }
            }
            return this.question.options.filter(
                option => !this.fromValueAsArray.includes(option.value)
            )
        }
    },

    methods: {
        answer () {
            if (this.question.type === 'form' && !this.value.length) {
                this.value = ''
            }

            this.$emit('answered', this.question.key, this.value)
        },

        answered (key, value) {
            this.$emit('answered', key, value)
        },

        answeredMultiple (value) {
            if (!Array.isArray(this.value)) {
                this.value = []
            }

            if (this.value.includes(value)) {
                this.value = this.value.filter(item => item !== value)

                if (!this.value.length) {
                    this.unavailable = true
                }
            } else {
                this.value.push(value)
                this.unavailable = false
            }
        },

        disabled () {
            return !this.unavailable
        },

        handleRangeValue (...args) {
            const [rangeValue] = args

            this.value = rangeValue
            this.unavailable = false
        },

        handleFormValue (...args) {
            const [formValue] = args

            this.unavailable = formValue.length !== 9
            this.value = formValue
        },

        processRangeOrFormOption (item, index) {
            if (Array.isArray(this.lastQuizResponse[this.question.key])) {
                item.wasChoosen = this.lastQuizResponse[this.question.key][index]
            } else {
                item.wasChoosen = this.lastQuizResponse[this.question.key]
            }
            return item
        },

        processSingleOption (item) {
            if ((Array.isArray(this.lastQuizResponse[this.question.key]) &&
                this.lastQuizResponse[this.question.key].includes(item.value)) ||
                this.lastQuizResponse[this.question.key] === item.value) {
                item.wasChoosen = true
            } else {
                item.wasChoosen = false
            }
        }
    }
}
</script>

<style lang="scss">
.mark {
    font-size: 12px;
    color: #00A26B;
}

.recommendation-v2 {
    .recommendation__header {
        @apply flex;
        @apply flex-col;
        @apply justify-center;
        max-width: 554px;
        min-height: 72px;

        @media screen and (min-width: $screen-tablet-min-width) {
            max-width: 622px;
        }

        .text {
            &-sm {
                padding-top: 8px;
            }

            &-2xl,
            &-3xl {
                padding-top: 24px;
                padding-bottom: 24px;

                small {
                    color: $color-neutral-600;
                    display: block;
                    font-size: 14px;
                    margin-top: 10px;
                }
            }
        }
    }

    .recommendation__card {
        &-list {
            @apply flex;
            @apply flex-wrap;
            @apply justify-center;
            padding: 15px 15px 54px;

            &.vertical {
                @apply flex-col;
                margin-left: auto;
                margin-right: auto;
                max-width: 374px;
            }
        }

        &-continue {
            @apply flex;
            @apply justify-center;
            bottom: 22px;
            flex-basis: 100%;
            margin-top: 40px;
            max-width: 100%;
            padding-left: 7px;
            padding-right: 7px;
            position: sticky;

            @media screen and (min-width: $screen-tablet-min-width) {
                margin-top: 50px;
            }

            .button {
                width: 330px;
            }
        }

        &-skip {
            @apply flex;
            @apply justify-center;
            flex-basis: 100%;
            margin-top: 12px;
            max-width: 100%;
            padding-left: 7px;
            padding-right: 7px;

            .button--lined {
                font-size: 14px;
            }
        }
    }
}
</style>
