export default function (url, query) {
    if (Object.values(query).length !== 0) {
        url = url + '?' +
        
        Object.keys(query)
            .map(key => {
                return (
                    encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
                )
            })
            .join('&')
    }

    return url
}
