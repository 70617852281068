import Repository from './repository'
import axios from 'axios'

class Issuers extends Repository {
    forRecommendation () {
        return axios.get(`${this.baseUrl}/issuers/for-recommendation`)
            .then(response => response.data)
    }
}

export default new Issuers()
