import Repository from './repository'
import axios from 'axios'

class Comments extends Repository {
    create (data) {
        return axios.post(`${this.baseUrl}/comments`, data)
            .then(response => response.data)
    }

    bySlug (slug) {
        return axios.get(`${this.baseUrl}/comments/${slug}`)
            .then(response => response.data)
    }
}

export default new Comments()
