<template>
    <article class="recommendation__card-item">
        <div class="recommendation__card-range range">
            <label for="range" class="range__values">
                <div class="range__value--min">0</div>
                <div class="range__value">
                    <span v-if="rangeTotal >= 30000">mais de </span>R$ {{ rangeTotalFormatted }}
                </div>
                <div class="range__value--max">+30 mil</div>
            </label>
            <input
                v-model="rangeValue"
                :style="backgroundColor"
                @input="updateInputData"
                type="range"
                min="0"
                max="30000"
                name="range"
                ref="range-income"
                :step="step"
                value="0"
            />
        </div>
        <div v-if="option.wasChoosen" class="mark mt-2 text-center">
            <img src="~@/icons/corner-left-up.svg" alt="Indicação do Item" class="inline">
            <span class="ml-2">Sua escolha anterior: R$ {{ formatChoosenValue }}</span>
        </div>
    </article>
</template>

<script>
import currency from '@/filters/currency'

export default {
    name: 'Range',

    props: {
        option: {
            type: Object,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        step: {
            default: 500,
            type: Number
        }
    },

    filters: { currency },

    data () {
        return {
            rangeValue: 0
        }
    },

    computed: {
        backgroundColor () {
            let percentage = Math.round(this.rangeValue * 100 / 30000)

            return {
                background: `linear-gradient(to right, #00e169 ${percentage}%, #00e169 ${percentage}%, #cacacc ${percentage}%)`
            }
        },

        formatChoosenValue () {
            return parseFloat(this.option.wasChoosen).toLocaleString('pt-br', { minimumFractionDigits: 2 })
        },

        rangeTotal () {
            return this.rangeValue
        },

        rangeTotalFormatted () {
            return (
                this.$options.filters.currency(this.rangeValue, 0)
            )
        }
    },

    methods: {
        updateInputData () {
            this.$emit('rangeValue', this.rangeValue, this.question.key, this.option.value)
        }
    }
}
</script>
