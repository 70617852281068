import Gate from '../../views/digital-accounts/Gate'
import guards from './guards'
import Question from '../../views/digital-accounts/Question'

export default [
    {
        path: '/contas-digitais',
        name: 'v1.digital-accounts.recommendation.index',
        redirect: {
            name: 'v1.digital-accounts.recommendation.questions.question',
            params: { slug: 'inicio' }
        }
    },
    {
        path: '/contas-digitais/recomendacao/perguntas/:slug?',
        name: 'v1.digital-accounts.recommendation.questions.question',
        component: Question,
        beforeEnter: guards.question
    },
    {
        path: '/contas-digitais/recomendacao/pre-resultado',
        name: 'v1.digital-accounts.recommendation.gate',
        component: Gate,
        beforeEnter: guards.gate
    }
]
