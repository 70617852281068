import Repository from './repository'
import axios from 'axios'

class Airlines extends Repository {
    forRecommendation () {
        return axios.get(`${this.baseUrl}/airlines/for-recommendation`)
            .then(response => response.data)
    }

    loadImage (imageName) {
        return axios.get(`${this.baseUrl}/files/${imageName}`)
            .then(response => response.data)
    }
}

export default new Airlines()
