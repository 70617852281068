import Repository from './repository'
import axios from 'axios'

class Common extends Repository {
    loadMenu () {
        return axios.get(`${this.baseUrl}/control/menus/formatted`)
            .then(response => response.data)
    }
}

export default new Common()
