<template>
<footer
    id="recommendation__footer"
    class="recommendation__footer"
>
    <nav class="recommendation__footer__main wrapper">
        <div class="recommendation__footer--top">
            <a :href="checkUrl('/politica-de-privacidade')" target="_blank">
                <span>&#8226; Política de Privacidade</span>
            </a>
            <a :href="checkUrl('/disclaimer')" target="_blank">
                <span>&#8226; Disclaimer</span>
            </a>
            <a :href="checkUrl('/termos-de-uso')" target="_blank">
                <span>&#8226; Termos de Serviço</span>
            </a>
        </div>
        <div class="recommendation__footer--copy">
            <p>&copy; {{currentlyYear}} - Plusdin - Todos os direitos reservados.</p>
        </div>
    </nav>
</footer>
</template>

<script>
import urlParams from "@/services/urlParams";

export default {
    name: 'RecommendationFooter',
    
    computed: {
        currentlyYear() {
            const date = new Date()
            return date.getFullYear()
        }
    },

    methods: {
        checkUrl(url) {
            return urlParams(url, this.$route.query)
        }
    }
}
</script>

<style lang="scss">

.recommendation {

    &__footer {
        background-color: $color-neutral-700;

        &__main {
            @apply flex flex-col justify-between items-center;
            min-height: 78px;

            @media screen and (min-width: $screen-tablet-min-width) {
                @apply flex-row;
            }
        }

        &--top {
            @apply flex flex-wrap justify-between;
            flex: 1;
            margin-left: -10px;
            margin-left: -10px;
            max-width: 520px;
            padding: 22px 0;

            @media screen and (min-width: $screen-tablet-min-width) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            a {
                color: $color-light;
                font-size: 14px;
                font-weight: 600;
                padding: 10px;
                text-decoration: none;
                line-height: 1.7;
                transition: color .45s ease;

                &:hover {
                    color: $color-neutral-400;
                }
            }
        }

        &--copy {

            @media screen and (max-width: $screen-tablet-max-width) {
                padding: 22px 22px;
                border-top: 1px solid rgba(255,255,255,.3);
                text-align: center;
                width: calc(100% + 44px);
            }

            p {
                color: $color-light;
                font-size: 12px;
                line-height: 1.5;
            }
        }
    }
}
</style>
