export default function (value, decimal = 2) {
    if (value === null) {
        return ''
    }

    return parseFloat(value)
        .toLocaleString('pt-BR', {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal
        })
}
