<template>
    <div class="recommendation__page background-color-gray-300">
        <div v-if="isCampaign" class="recommendation__page_top">
            <div class="recommendation__page_wrapper">
                <img
                    @click="
                        !isCampaign ? $router.push('/').catch(() => {}) : null
                    "
                    :class="{ is_link: !isCampaign, '': isCampaign }"
                    class="credit_card__page_header__logo"
                    src="@/icons/logo-plusdin.svg"
                    alt="Plusdin"
                />
            </div>
        </div>
        <div v-if="!isCampaign" class="mb-6">
            <Header />
        </div>
        <div class="recommendation__page_home  background-color-gray-300">
            <div class="recommendation__page_container">
                <div class="recommendation__page_left">
                    <div class="recommendation__page_wrapper">
                        <h1>
                            Descubra qual o <u>cartão de crédito</u> ideal para
                            você!
                        </h1>
                        <h2>
                            Responda às perguntas a seguir para que nossa
                            inteligência artificial possa encontrar o
                            <strong>cartão de crédito</strong> perfeito para
                            você.
                        </h2>
                        <div class="recommendation__page_bottom__action">
                            <router-link
                                :to="{
                                    name: 'v2.credit-card.recommendation.question',
                                    params: { slug: 'inicio', flow: 'short' }
                                }"
                                class="button"
                                target='_blank'
                            >Começar agora</router-link>
                            <p>Leva menos de um minuto.</p>
                        </div>
                    </div>
                </div>
                <div class="recommendation__page_right">
                    <div class="recommendation__home_hero">
                        <img
                            src="@/icons/quiz/home-desktop.svg"
                            alt="Recomendador Plusdin"
                        />
                    </div>
                </div>
            </div>
        </div>
        <FooterRecommendation v-if="isCampaign" :isHome="true" />
        <Footer v-if="!isCampaign" />
    </div>
</template>

<script>
import Header from "@/components/Header";
import FooterRecommendation from "@/components/sections/credit-card/recommendation/Footer";
import Footer from "@/components/Footer";

export default {
    components: {
        FooterRecommendation,
        Footer,
        Header
    },

    mounted() {
        this.$clearKeysOnStorage();
    },

    computed: {
        isCampaign() {
            return this.$route.query.campaign === "true";
        }
    }
};
</script>

<style lang="scss">
.background-color-gray-300 {
    background-color: $bg-gray-300;
}
.recommendation__page {
    .recommendation__page_wrapper {
        @apply mx-auto px-6;
    }
    .recommendation__page_top {
        @apply w-full py-6;
    }
    .recommendation__page_container {
        @apply w-full flex flex-col;
        .recommendation__page_left {
            h1 {
                font-family: $v2-font-family;
                font-style: normal;
                font-weight: normal;
                font-size: 36px;
                line-height: 130%;
                @apply text-black;
                u {
                    display: inline-block;
                    position: relative;
                    font-weight: 700;
                    z-index: 1;
                    text-decoration: none;
                }
                u:after {
                    content: "";
                    display: block;
                    background: #97fca8;
                    width: 102%;
                    height: 42%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                }
            }
            h2 {
                font-family: $v2-font-family;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%;
                color: $text-gray-700;
                @apply py-6;
            }
            .recommendation__page_bottom__action {
                @apply max-w-sm mx-auto text-center mb-10;
                button,
                .button {
                    background: #00e169;
                    border-radius: 4px;
                    @apply inline-flex justify-center uppercase w-full text-sm py-4 text-black font-semibold mb-3;
                    font-weight: bold;
                }
                p {
                    font-family: $v2-font-family;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 170%;
                    @apply text-black;
                }
            }
        }
        .recommendation__page_right {
            .recommendation__home_hero {
            }
        }
    }
}
@media screen and (min-width: $screen-tablet-min-width) {
    .recommendation__page {
        @apply flex h-auto flex-col justify-between;
        .recommendation__page_home {
            @apply h-auto flex flex-col relative overflow-auto justify-start;
        }
        .recommendation__page_wrapper {
            @apply max-w-screen-xl;
        }
        .recommendation__page_container {
            @apply flex-row max-w-screen-xl mx-auto justify-between mb-12;
            .recommendation__page_left {
                @apply w-5/12 flex flex-row items-center;
                h1 {
                    font-size: 48px;
                    line-height: 120%;
                }
                h2 {
                    font-size: 18px;
                    line-height: 150%;
                }
                .recommendation__page_bottom__action {
                    @apply max-w-full text-left mx-0;
                    button,
                    .button {
                        max-width: 325px;
                    }
                }
            }
            .recommendation__page_right {
                @apply w-6/12 mr-10 flex flex-row items-center;
            }
        }
    }
}
</style>
