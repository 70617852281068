<template>
<section class="card-comparator__section card-comparator__section-points">
    <h3 class="card-comparator__section-title"><span>/</span> Programa de Pontos</h3>
    <hr class="card-comparator__section-division">
    <div class="card-comparator__section-wrapper">
        <template v-for="card of cards">
            <section
                :key="card.id"
                v-if="!card.empty"
                class="card-comparator__first-col-result"
            >
                <div
                    v-for="program in card.rewards_programs"
                    :key="program.name"
                    class="card-comparator__item-description-footer"
                >
                    <h3 v-if="isBrandReward(program)">Da Bandeira:</h3>
                    <h3 v-else>Programa Próprio:</h3>
                    <h4 class="card-comparator__item-description-footer">{{ program.name }}</h4>
                    <ul>
                        <li
                            v-for="item in program.items"
                            :key="item.value"
                            class="card-comparator__list-item card-comparator__list-item--green"
                        >{{ item.value }}</li>
                    </ul>
                </div>
            </section>
            <section
                :key="card.id"
                v-else
                class="card-comparator__first-col-result"
            ></section>
        </template>
    </div>
    <!-- <div class="ads">
        <AdBlock/>
    </div> -->
</section>
</template>

<script>
export default {
    props: {
        cards: Array
    },

    methods: {
        isBrandReward (program) {
            return !!program.brand
        }
    }
}
</script>

<style lang="scss">
</style>
