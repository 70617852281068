<template>
<section class="card-comparator__section-cards comparator-cards-chooser">
    <div
        class="card-comparator__chooser-content"
        :class="{ 'card-comparator__chooser-content--fixed' : scroll }"
    >
        <div class="card-comparator__first-col">
            <div
                v-show="!hasFirstCard"
                class="card-comparator__arrow-start"
            >Comece por aqui</div>
            <Dropdown
                :options="validCards"
                @selected="setCard('card1', $event)"
                :value="getCard(slugs[0])"
                class="mb-2"
            />
            <a
                :href="checkUrl(`https://plusdin.com/cartao-de-credito/${slugs[0]}/como-solicitar`)"
                v-if="slugs[0]"
                class="card-comparator__card-btn"
            ><span class="track" @click="trackRequest(slugs[0])">Solicitar agora</span></a>
        </div>
        <div class="card-comparator__versus"><span>X</span></div>
        <div
            :class="{ 'card-comparator__second-col--disabled': !hasFirstCard }"
            class="card-comparator__second-col"
        >
            <div
                v-show="hasFirstCard && !hasSecondCard"
                class="card-comparator__arrow-start"
            >Escolha o outro</div>
            <Dropdown
                :options="validCards"
                @selected="setCard('card2', $event)"
                :value="getCard(slugs[1])"
                class="mb-2"
            />
            <a
                :href="checkUrl(`https://plusdin.com/cartao-de-credito/${slugs[1]}/como-solicitar`)"
                v-if="slugs[1]"
                class="card-comparator__card-btn"
            ><span class="track" @click="trackRequest(slugs[1])">Solicitar agora</span></a>
        </div>
    </div>
</section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Dropdown from '@/components/card-comparator/Dropdown'
import tracking from '@/services/tracking'

const { mapState, mapActions } = createNamespacedHelpers('cardsComparision')

export default {
    components: { Dropdown },

    props: {
        hasFirstCard: Boolean,
        hasSecondCard: Boolean,
        scroll: Boolean,
        slugs: Array
    },

    data () {
        return {
            card1: {},
            card2: {},
            card3: {}
        }
    },

    created () {
        if (!this.cards.length) {
            this.listCards()
        }
    },

    computed: {
        ...mapState(['cards']),

        validCards () {
            return this.cards.filter(card => !this.slugs.includes(card.slug))
        }
    },

    methods: {
        ...mapActions(['listCards']),

        checkUrl(url) {
            return url
        },

        getCard (key) {
            return this.cards.find(card => card.slug === key)
        },

        setCard (key, value) {
            this[key] = value

            const slugs = [this.card1.slug, this.card2.slug, this.card3.slug]
                .filter(slug => !!slug)

            tracking.pushInteraction(
                'comparador-cartoes',
                'escolheu-cartoes',
                value && (typeof value === 'object') ? value.slug : value
            )
            this.$emit('choosed', slugs)
        },

        setCards () {
            this.card1 = this.slugs[0] ? { slug: this.slugs[0] } : {}
            this.card2 = this.slugs[1] ? { slug: this.slugs[1] } : {}
            this.card3 = this.slugs[2] ? { slug: this.slugs[2] } : {}
        },

        trackRequest (slug) {
            tracking.pushInteraction('comparador-cartoes', 'click-btn:solicitar-agora', slug)
        }
    },

    watch: {
        slugs: {
            handler: 'setCards',
            immediate: true
        }
    }
}
</script>

<style lang="scss">
.comparator-cards-chooser {
    @apply mb-5;

    .card-comparator__card-btn {
        display: none;
    }
}

.card-comparator__chooser-content {
    @apply flex;
    @apply justify-between;
    width: 100%;
    height: 43px;
    margin-top: 35px;
    color: #000000;
    font-size: 14px;
    line-height: 170%;
}

.card-comparator__chooser-content--fixed {
    background-color: white;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.04);
    height: auto;
    left: 0;
    margin-top: 0px;
    padding: 20px 1.5rem 10px 1.5rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;

    .card-comparator__arrow-start {
        display: none;
    }

    .card-comparator__card-btn {
        display: flex;
    }
}

@media screen and (min-width: $screen-tablet-min-width) {
    .card-comparator__chooser-content--fixed {
        // max-width: 69%;
    }
}
</style>
