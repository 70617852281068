<template>
<header class="card-comparator-header">
    <h1 class="card-comparator__headline">Compare os cartões de crédito</h1>
    <h2
        v-if="cards[0].empty"
        class="card-comparator__subtitle"
    >Selecione abaixo os cartões que você deseja comparar</h2>
    <h2
        v-else
        class="card-comparator__comparison-text"
    >
        <template v-for="card of cards">
            <span
                :key="card.id"
                class="card-comparator__versus--green"
            >{{ card.empty ? '________' : card.name }}</span>
        </template>
    </h2>
</header>
</template>

<script>
export default {
    props: {
        cards: Array
    }
}
</script>

<style lang="scss">
@media screen and (min-width: $screen-tablet-min-width) {
    .card-comparator-header {
        margin-top: 30px;
    }
}
</style>
