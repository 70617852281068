import axios from 'axios'

export default {
    locate () {
        const headers = {
            Authorization: `Bearer ${process.env.VUE_APP_IPINFO_TOKEN}`,
            Accept: 'application/json'
        }

        return axios.get(process.env.VUE_APP_IPINFO_URL, { headers })
            .then(response => response.data)
            .then(data => ({
                ip: data.ip,
                city: data.city,
                region: data.region,
                country: data.country
            }))
            .catch(() => null)
    }
}
