import Repository from "./repository";
import axios from "axios";

class Cards extends Repository {
    /**
     * @todo Move to "public" cards repo
     */
    bySlug(slug) {
        return axios
            .get(`${this.baseUrl}/recommendations/cards/${slug}`)
            .then(response => response.data);
    }

    compare(slugs) {
        slugs = slugs.filter(slug => !!slug).join("/");

        return axios
            .get(`${this.baseUrl}/comparisions/cards/compare/${slugs}`)
            .then(response => response.data);
    }

    find(id) {
        return axios
            .get(`${this.baseUrl}/control/cards/${id}`)
            .then(response => response.data);
    }

    lists() {
        return axios
            .get(`${this.baseUrl}/cards/lists`)
            .then(response => response.data);
    }

    recommendation(state) {
        const data = this.getFormattedDataFromQuiz(state)

        return axios
            .post(`${this.recommenderURL}/get_simple_personal_rank`, data, {
                auth: {
                    username: this.recommenderUser,
                    password: this.recommenderPass
                }
            })
            .then(response => response.data)
    }
    
    getBenefitsTypeAnswer(state) {
        const moreBenefitsCondition = state.lastQuizResponse.more_benefits !== undefined
            || state.lastQuizResponse.more_benefits_low_income !== undefined
            || state.lastQuizResponse.more_benefits_average_income !== undefined

        let benefits = moreBenefitsCondition ? 'more_benefits' : 'benefits'

        benefits += state.lastQuizResponse.benefits_low_income
            ? '_low_income'
            : ''

        benefits += state.lastQuizResponse.benefits_average_income
            ? '_average_income'
            : ''
        
        return benefits;
    }
    
    getBranch(state) {
        const branch = { 
            "travel-miles": 1,
            "points-and-advantages": 1,
            cashback: 2,
            entertainment: 3,
            discounts: 4
        };

        const benefits = this.getBenefitsTypeAnswer(state);

        return Number.parseInt(branch[state.lastQuizResponse[benefits]]);
    }

    getFormattedDataFromQuiz(state) {
        const creditCardStatus = { pristiny: 0, "in-debit": 1 };
        
        const employmentBond = {
            "retired-pensioner": 1,
            "government-employee": 2,
            employee: 3,
            unemployed: 4,
            "entrepreneur-freelancer": 5,
            university: 6
        };
        
        const freeFeeRecheck = { no: 1, yes: 0, "yes-if-discounts": 2 };
        
        const habits = {
            "multiple-airlines-benefits": 1,
            "single-airline-bigger-benefits": 2
        };

        const airlineBenefits = {
            "access-vip-lounges": 1,
            "tickets-discounts": 2,
            "preferential-boarding": 3,
            "longer-tickets-installment": 4,
            "free-travel-insurance": 5
        };

        const otherAirlineBenefits = {
            "access-vip-lounges": 1,
            "tickets-discounts": 2,
            "preferential-boarding": 3,
            "longer-tickets-installment": 4,
            "free-travel-insurance": 5
        };

        const tripPurpose = { leisure: 1, work: 2, balanced: 3 };
        
        const tripNationality = { national: 1, international: 2, both: 3 };
        
        const freeFee = state.lastQuizResponse.benefits === "no-annual-fee"
            || state.lastQuizResponse.benefits_low_income === "no-annual-fee"
            || state.lastQuizResponse.benefits_average_income === "no-annual-fee"

        let data = {
            recommendations: 10,
            free_fee: freeFee ? 1 : 0,
            cashback: 0,
            miles: 0,
            rewards: 0,
            discounts: 0,
            lounges: 0,
            is_international: state.lastQuizResponse.is_international ? 1 : 0,
            ignore_fee: 0,
            income: Number.parseInt(state.lastQuizResponse.income_value),
            in_debt: creditCardStatus[state.lastQuizResponse.credit_status],
            job: employmentBond[state.lastQuizResponse.employment_bond],
            free_fee_recheck: freeFeeRecheck[state.lastQuizResponse.free_fee_recheck]
                ?? 0
        };

        data['branch'] = this.getBranch(state)

        if (data['branch'] !== undefined) {
            data['habits'] = habits[state.lastQuizResponse.best_for_habits];
            data['airline_name'] = state.lastQuizResponse.airline;
            data['airline_benefit'] = airlineBenefits[state.lastQuizResponse.airline_benefits];
            data['other_airline_benefit'] = otherAirlineBenefits[state.lastQuizResponse.others_airline_benefits];
            data['other_airlines_names'] = state.lastQuizResponse.others_airlines;
            data['trip_nationality'] = tripNationality[state.lastQuizResponse.common_travel];
            data['trip_purpose'] = tripPurpose[state.lastQuizResponse.flight_purpose];
            data['expenses'] = Number.parseInt(state.lastQuizResponse.credit_card_expenses);
            data['uses_internet'] = state.lastQuizResponse.purchase_on_internet ? 1 : 0;
            data['stores'] = state.lastQuizResponse.stores;
            data['how'] = this.getHow(state, data);
        }

        return data
    }
    
    getHow(state, data) {
        let howNumber = undefined
        
        if (data.in_debt !== 0) return howNumber
        
        switch (state.lastQuizResponse.spend_points) {
            case 'miles':
                howNumber = 1;
                break;
            case 'products_and_services':
                howNumber = 2;
                break;
            case 'discounts':
                howNumber = 3;
                break;
        }

        return howNumber;
    }
}

export default new Cards();
