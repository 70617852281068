<template>
<section class="card-comparator__section card-comparator__section-like">
    <h3 class="card-comparator__section-title">
        <span><img src="@/icons/plus-benefits.svg" alt="Vantagens"></span>
        Vantagens
    </h3>
    <hr class="card-comparator__section-division">
    <div class="card-comparator__section-wrapper">
        <template v-for="card of cards">
            <section
                :key="card.id"
                v-if="!card.empty"
                class="card-comparator__first-col-result"
            >
                <ul>
                    <li
                        v-for="advantage in card.advantages"
                        :key="advantage.value"
                        class="card-comparator__list-item card-comparator__list-item--green"
                    >
                        {{ advantage.value }}
                    </li>
                </ul>
            </section>
            <section
                :key="card.id"
                v-else
                class="card-comparator__first-col-result"
            ></section>
        </template>
    </div>
</section>
</template>

<script>
export default {
    props: {
        cards: Array
    }
}
</script>

<style lang="scss">
</style>
