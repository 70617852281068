<template>
    <div class="recommendation__avatar" ref="animContainer">
        <lottie-animation
            path="assets/lotties/duda.json"
            :autoPlay=true
            :loop=false
            :speed=1
            :width=72
            :height=72
            @AnimControl="animationController"
        />
    </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation'

export default {
    components: {
        LottieAnimation
    },
    methods: {
        animationController (animation) {
            const animContainer = this.$refs.animContainer.querySelector('div')

            animContainer.addEventListener('mouseover', () => {
                animation.stop()
                animation.play()
            })
        }
    }
}
</script>
