<template>
    <article class="recommendation__card-item">
        <button
            :class="{ checked: isChecked }"
            @click.prevent="answer"
            class="card__link"
            type="button"
        >
            <div
                :class="{ full: option.isFull, 'is-longer': option.is && option.text }"
                class="recommendation__card__main card__main"
            >
                <div
                    v-if="question.style !== 'small'"
                    class="recommendation__card__header card__header"
                >
                    <span class="check check-rounded check-primary"></span>
                </div>
                <div class="card__body recommendation__card__body">
                    <div v-if="option.image_checked" class="recommendation__card__image">
                        <img
                            class="recommendation__card__image--off"
                            :src="image"
                        />
                        <img
                            class="recommendation__card__image--on"
                            :src="imgSrc + option.image_checked"
                        />
                    </div>
                    <div v-else-if="option.image" class="recommendation__card__image--only">
                        <img
                            :class="{ 'recommendation__card-image--limited': limitImagesSizes }"
                            :src="image"
                        />
                    </div>
                    <p
                        v-if="shouldShowText"
                        class="text"
                    >{{ option.text }}</p>
                </div>
            </div>
        </button>
    </article>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Option',

    props: {
        option: {
            type: Object,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        value: [Array, Boolean, Number, String]
    },

    data () {
        return {
            imgSrc: '/images/recommendation/',
            isChoosed: false
        }
    },

    computed: {
        ...mapState('DAStore', [
            'current',
            'response'
        ]),

        image () {
            return (this.option.image.match(/^http/) ? '' : this.imgSrc) + this.option.image
        },

        isChecked () {
            return this.isChoosed || this.value === this.option.value
        },

        limitImagesSizes () {
            return this.question.meta && this.question.meta.limit_images_sizes
        },

        shouldShowText () {
            return !!this.option.text && !this.option.show_image_only
        }
    },

    methods: {
        answer () {
            this.isChoosed = !this.isChoosed
            this.$emit('answered', this.question.key, this.option.value, this.option.nextQuestion)
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/recommendation/_layout.scss";
</style>