import issuers from '@/services/issuers'

const mutations = {
    SET_ISSUERS: 'setIssuers'
}

export default {
    namespaced: true,

    state: () => ({
        issuers: []
    }),

    actions: {
        load ({ commit }) {
            return issuers.forRecommendation().then(issuers => {
                commit(mutations.SET_ISSUERS, { issuers })

                return issuers
            })
        }
    },

    mutations: {
        [mutations.SET_ISSUERS] (state, payload) {
            state.issuers = payload.issuers
        }
    }
}
