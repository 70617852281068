<template>
<section class="card-comparator__section card-comparator__section-dislike">
    <h3 class="card-comparator__section-title">
        <span><img src="@/icons/plus-disadvantages.svg" alt="Desvantagens"></span>
        Desvantagens
    </h3>
    <hr class="card-comparator__section-division">
    <div class="card-comparator__section-wrapper">
        <template v-for="card of cards">
            <section
                :key="card.id"
                v-if="!card.empty"
                class="card-comparator__first-col-result"
            >
                <ul>
                    <li
                        v-for="disadvantage in card.disadvantages"
                        :key="disadvantage.value"
                        class="card-comparator__list-item card-comparator__list-item--red"
                    >{{ disadvantage.value }}</li>
                    <li class="card-comparator__list-item card-comparator__list-item--red">Taxa de rotativo é alta se comparada a outros cartões do mercado</li>
                </ul>
            </section>
            <section
                :key="card.id"
                v-else
                class="card-comparator__first-col-result"
            ></section>
        </template>
    </div>
</section>
</template>

<script>
export default {
    props: {
        cards: Array
    }
}
</script>

<style lang="scss">
</style>
