<template>
<section class="card-comparator__section card-comparator__section-rate">
    <h3 class="card-comparator__section-title"><span>/</span> Taxas</h3>
    <hr class="card-comparator__section-division">
    <div class="card-comparator__section-wrapper">
        <template v-for="card of cards">
            <section
                :key="card.id"
                v-if="!card.empty"
                class="card-comparator__first-col-result"
            >
                <h4 class="card-comparator__item-category">Renda mínima exigida</h4>
                <p
                    :class="{ 'card-comparator__item-value--best': hasWinner('minimum_income', card) }"
                    class="card-comparator__item-value"
                >{{ card.minimum_income | income }}</p>
                <h4 class="card-comparator__item-category">Primeira anuidade</h4>
                <p
                    :class="{ 'card-comparator__item-value--best': hasWinner('first_fee', card) }"
                    class="card-comparator__item-value"
                >{{ card.first_fee | fee }}</p>
                <h4 class="card-comparator__item-category">Demais anuidades</h4>
                <p
                    :class="{ 'card-comparator__item-value--best': hasWinner('fee', card) }"
                    class="card-comparator__item-value"
                >{{ card.fee | fee }}</p>
                <h4 class="card-comparator__item-category">Limite de crédito</h4>
                <p
                    :class="{ 'card-comparator__item-value--best': hasWinner('credit_limit', card) }"
                    class="card-comparator__item-value"
                >{{ card.credit_limit | creditLimit }}</p>
                <h4 class="card-comparator__item-category">Juros rotativos mensais</h4>
                <p
                    :class="{ 'card-comparator__item-value--best': hasWinner('monthly_rate', card) }"
                    class="card-comparator__item-value"
                >{{ card.monthly_rate }}%</p>
            </section>
            <section
                :key="card.id"
                v-else
                class="card-comparator__first-col-result"
            ></section>
        </template>
    </div>
</section>
</template>

<script>
import currency from '@/filters/currency'

export default {
    props: {
        cards: Array,
        observer: {},
        winners: Object
    },

    filters: {
        creditLimit (value) {
            return !value ? 'Variável' : 'R$ ' + currency(value)
        },

        fee (value) {
            return !value ? 'Grátis' : 'R$ ' + currency(value)
        },

        income (value) {
            return !value ? 'Não exigida' : 'R$ ' + currency(value)
        }
    },

    mounted () {
        this.observer.observe(this.$el)
    },

    methods: {
        hasWinner (field, card) {
            return this.winners[field] === card.slug
        }
    }
}
</script>

<style lang="scss">
</style>
