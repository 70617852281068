import { slugify, stripTags } from '@/utils/string'
import tracking from '@/services/tracking'

export default {
    methods: {
        prepareTrackAnswersStatements (key, value) {
            return Object.keys(this.response)
                .map(key => this.questionsV2.find(question => question.key === key))
                .map((question, index) => ({
                    step: index + 1,
                    statement: slugify(stripTags(question.title)),
                    answer: slugify(stripTags(this.getQuestionValueLabel(value, question))),
                    index: this.getQuestionValueIndex(value, question)
                }))
        },

        trackAnswer (key, value) {    
            tracking.pushAnswer(this.prepareTrackAnswersStatements(key, value))
        }
    }
}
