export default function persistPlugin (store) {
    // called when the store is initialized
    store.subscribe((mutation) => {
        const {
            type,
            payload
        } = mutation
        switch (type) {
        case 'setLead':
            if (payload.lead.card) { sessionStorage.setItem('plusdin_lead', JSON.stringify(payload)) }
            break
        case 'setResponseKey':
            // sessionStorage.setItem('plusdin@responseKeys', JSON.stringify(state.response))
            sessionStorage.removeItem('plusdin_lead')
            break
        }
    })
}
