import Repository from './repository'
import axios from 'axios'

class News extends Repository {
    categories () {
        return axios.get(`${this.baseUrl}/categories`)
            .then(response => response.data)
    }

    detail (slug) {
        return axios.get(`${this.baseUrl}/posts/${slug}`)
            .then(response => response.data)
    }

    posts ({ category, limit = null }) {
        let limitString = ''

        if (limit !== null) limitString = `&_limit=${limit}`

        return axios.get(`${this.baseUrl}/posts?category=${category}${limitString}`)
            .then(response => response.data)
    }
}

export default new News()
