<template>
<div class="default__form">
    <div
        class="newsletter-form__fields"
        v-if="state === null"
    >
        <div class="form__default_field">
            <div
                class="form__default_field_wrapper"
                :class="{ focus: form.name }"
            >
                <input
                    ref="name"
                    type="text"
                    v-model="form.name"
                    :class="{ focus: form.name, 'form_success': (!$v.form.name.$error && $v.form.name.required), 'form_error': ($v.form.name.$error && !$v.form.name.required) }"
                />
                <label
                    :class="{ focus: form.name }"
                    @click="focused('name', $event)"
                >Seu nome:</label>
            </div>
            <span
                class="lb__error"
                v-if="$v.form.name.$error && !$v.form.name.required"
            >Você precisa inserir um nome.</span>
        </div>
        <div class="form__default_field">
            <div
                class="form__default_field_wrapper"
                :class="{ focus: form.email }"
            >
                <input
                    ref="email"
                    type="text"
                    v-model="form.email"
                    :class="{ focus: form.email, 'form_success': (!$v.form.email.$error && ($v.form.email.required && $v.form.email.email)), 'form_error': ($v.form.email.$error && (!$v.form.email.required || !$v.form.email.email)) }"
                />
                <label
                    :class="{ focus: form.email }"
                    @click="focused('email', $event)"
                >Seu email:</label>
            </div>
            <span
                class="lb__error"
                v-if="$v.form.email.$error && (!$v.form.email.required || !$v.form.email.email)"
            >Você precisa digitar um e-mail válido.</span>
            <span
                class="lb__error"
                v-if="undeliverableEmail"
            >E-mail não é válido, por favor tente outro e-mail.</span>
        </div>
        <div
            class="form__default_field_checkbox"
            @click="toggleAccepted"
        >
            <input
                :checked="form.checked"
                type="checkbox"
            />
            Li e concordo com os
            <a
                target="_blank"
                :href="checkUrl('/termos-de-uso')"
            ><strong>Termos de Serviço</strong></a>.
            <span
                class="lb__error"
                v-if="$v.form.checked.$error && !$v.form.checked.accepted"
            >Você precisa aceitar os termos de serviço.</span>
        </div>
        <div>
            <button
                class="btn button--secondary form__default_button"
                @click="subscribe"
                :disabled="loading"
            >{{ loading ? 'Enviando ...' : 'Enviar' }}</button>
        </div>
    </div>
    <div
        class="newsletter-form__state newsletter-form__state--success"
        v-if="state === 'success'"
    >
        <h3>
            Feito
            <svg width="16" height="12" viewBox="0 0 16 12" fill="#43D29E" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4L0 6L6 12L16 2L14 0L6 8L2 4Z" fill="#43D29E"/>
            </svg>
        </h3>
        <p>Cadastro realizado com sucesso! Para cadastrar outro e-mail, <span>clique abaixo</span>.</p>
        <button class="btn btn--primary"
            @click="state = null"
            type="button"
        >
            <img src="@/icons/arrow-right-green.svg" alt="Arrow right"
            />
            Voltar
        </button>
    </div>
    <div
        class="newsletter-form__state newsletter-form__state--failure"
        v-if="state === 'failure'"
    >
        <h3>Ops! 🤔</h3>
        <p>Ocorreu um erro temporário, tente novamente mais tarde.</p>
        <button class="btn btn--primary"
            @click="state = null"
            type="button"
        >
            <img src="@/icons/arrow-right-green.svg" alt="Arrow right"
            />
            Voltar
        </button>
    </div>
</div>
</template>

<script>
import required from '@/validators/required'
import email from '@/validators/email'
import { validationMixin } from 'vuelidate'
import deliverable from '@/validators/deliverable'
import tracking from '@/services/tracking'
import emails from '@/services/emails'
import urlParams from "@/services/urlParams";

export default {
    props: {
        sourceTag: {
            type: String,
            default: ''
        }
    },

    mixins: [validationMixin],

    data () {
        return {
            form: {
                name: '',
                email: '',
                checked: false,
                website: 'https://plusdin.com.br'
            },
            loading: false,
            state: null,
            undeliverableEmail: false
        }
    },

    validations: {
        form: {
            checked: { accepted: value => value === true },
            email: { required, email },
            name: { required }
        }
    },

    methods: {
        subscribeToNewsletter (payload) {
            return emails.subscribe({ ...payload })
        },

        checkUrl(url) {
            return urlParams(url, this.$route.query)
        },

        another () {
            this.state = null
            this.$v.$reset()
        },

        async checkUndeliverableEmail () {
            this.undeliverableEmail = !(await deliverable(this.form.email))

            if (this.undeliverableEmail) {
                this.loading = false
            }

            return this.undeliverableEmail
        },

        focused (formName) {
            this.form[formName] = ' '
            this.$refs[formName].focus()
        },

        toggleAccepted () {
            this.form.checked = !this.form.checked
        },

        sendLeadToQuizTracker (lead) {
            tracking.sendNewsletterLeadToQuizTracker({
                app: 'plusdin',
                email: lead.email,
                name: lead.name
            })
        },

        async subscribe () {
            this.$v.$touch()

            if (this.$v.$invalid) return

            this.loading = true

            if (await this.checkUndeliverableEmail()) return

            try {
                await this.subscribeToNewsletter(this.form)
                this.sendLeadToQuizTracker(this.form)
                tracking.pushInteraction('lead-form', 'submit', 'sucesso')
                this.state = 'success'
                this.form.name = ''
                this.form.email = ''
                this.form.checked = false
            } catch (err) {
                this.state = 'failure'
                tracking.pushInteraction('lead-form', 'submit', 'erro')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
.default__form {
    max-width: 295px;
    @apply mx-auto w-full;

    .form__default_field {
        font-family: $v2-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        @apply mb-6;

        .form__default_field_wrapper {
            @apply relative mb-0 pb-3 h-10 w-full;
            border-bottom: 1px solid #ccc;

            input {
                @apply outline-none bg-transparent text-black w-full h-full z-10 absolute;

                .focus{
                    @apply text-black;
                    font-size: 14px;
                    line-height: 170%;
                }
            }

            label {
                color: #000000;
                transition: margin .6s ease-in-out;
                @apply absolute text-black mt-1;
                z-index: 2;

                &.focus {
                    @apply text-gray-500;
                    margin-top: -1.25rem;
                }
            }
        }
    }

    .form__default_field_checkbox {
        font-family: $v2-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        @apply mb-6 cursor-pointer select-none;
    }

    .form__default_button {
        width: 112px;
        min-height: 42px;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.07em;
        text-transform: uppercase;        
    }

    .button--secondary {
        background: #000000;
        color: white;
        border: 1px solid #000000;
    }
    .button--secondary:hover {
        background: white;
        color: #000000;
    }
}

.newsletter-form__state {
    h3 {
        @apply flex items-center;
        @apply font-semibold;
        @apply mb-5;
        font-size: 30px;
        line-height: 130%;

        svg {
            @apply ml-2;
        }
    }

    p {
        span {
            @apply font-bold;
        }
    }

    .btn--primary {
        border: 1px solid #00E169; 
        max-width: 56px; 
        background: 0;

        &:hover {
            background: #00C169;
            color: #000;
        }
    }

    button {
        @apply block flex font-semibold px-4 text-sm flex-row justify-around items-center uppercase;
        border: 1px solid $v2-background1-color;
        height: 41.97px;
        font-size: 12px;
        min-width: 124px;

        img {
            @apply ml-3;
            transform: rotate(180deg);
        }
    }
}

span.lb__error{
    @apply text-xs w-full block;
    color: #FF4B38;
}

span.lb__success{
    @apply text-xs text-green-900 w-full block;
}

.form_error { 
    background: url(~@/images/flexible-email-capture/icon-error.svg) no-repeat right 0.75rem center;  
}

.form_success {
    background: url(~@/images/flexible-email-capture/icon-check.svg) no-repeat right 0.75rem center;  
}

.newsletter-form__state--success {}

.newsletter-form__state--failure {
    p {
        @apply mb-10;
        @apply text-red-600;
    }
}
</style>
