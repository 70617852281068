<template>
    <form
        @submit.prevent="tryToSave"
        action="/"
        class="form gate__form"
        method="POST"
    >
        <div class="form__gate_field">
            <template v-if="isVariationB">
                <button
                    class="form__login_button"
                    type="button"
                    @click="getUserDataFromGoogle()"
                >
                    Continuar com o google
                </button>
                <div class="strike">
                    <span>Ou</span>
                </div>
            </template>
            <div class="form__gate_field_wrapper" :class="{ focus: nameIsFocus }">
                <input
                    :class="{ focus: nameIsFocus }"
                    v-model="contact.name"
                    name="name"
                    ref="inputName"
                    @click="onFocusInputName"
                    @blur="onLeaveInputName"
                    type="text"
                />
                <label :class="{ focus: nameIsFocus }" @click="onFocusInputName"
                >Seu nome:</label
                >
            </div>
            <small v-if="$v.contact.name.$error" class="form__error">
                <span v-if="!$v.contact.name.required">Informe seu nome.</span>
            </small>
        </div>
        <div class="form__gate_field">
            <div class="form__gate_field_wrapper" :class="{ focus: emailIsFocus }">
                <label
                    :class="{ focus: emailIsFocus }"
                    @click="trackingOnFocusInputEmail"
                >Seu e-mail:</label
                >
                <input
                    :class="{ focus: emailIsFocus }"
                    ref="inputEmail"
                    v-model="contact.email"
                    @blur="onLeaveInputEmail"
                    @click="trackingOnFocusInputEmail"
                    name="email"
                    type="email"
                />
            </div>
            <small v-if="$v.contact.email.$error" class="form__error">
                <span v-if="!$v.contact.email.required">Informe seu e-mail.</span>
                <span v-if="!$v.contact.email.email">Informe um e-mail válido.</span>
            </small>
            <small v-if="undeliverableEmail" class="form__error">
        <span
        >Parece que tem algo errado com seu e-mail. Por favor,
          verifique.</span
        >
            </small>
        </div>
        <div class="form__gate_field">
            <label class="gate__terms-field-label">
                <input
                    v-model="contact.has_accepted_terms"
                    name="termsAccepted"
                    type="checkbox"
                    :checked="terms_accepted"
                />
                <span class="gate__terms-field-text">
          Li e concordo com os
          <a class="gate__terms-link" :href="checkUrl('/termos-de-uso')" target="_blank"
          >Termos de Serviço</a
          >.
        </span>
            </label>
            <small v-if="$v.contact.has_accepted_terms.$error" class="form__error">
        <span v-if="!$v.contact.has_accepted_terms.accepted"
        >Aceite os termos para continuar</span
        >
            </small>
        </div>
        <div
            class="form__gate_button"
            :class="{ 'opacity-50': loading, disabled: loading, active: loading }"
            @click="tryToSave"
            :disabled="loading"
            type="submit"
        >
            <div class="text">
                <span v-if="!readyToRedirect">
                    {{ loading ? "Processando ..." : "Ver minha recomendação" }}
                </span>
                <img 
                    alt="Concluído"
                    class="icon"
                    src="~@/icons/icon-check.svg"
                    v-if="readyToRedirect"
                >
            </div>
            <div class="progress"></div>
        </div>
        <template v-if="isVariationC">
            <div class="strike">
                <span>Ou</span>
            </div>
            <button
                class="form__login_button"
                type="button"
                @click="getUserDataFromGoogle()"
            >
                Continuar com o google
            </button>
        </template>
    </form>
</template>

<script>
import {mapActions, mapState} from "vuex";
import required from "@/validators/required";
import email from "@/validators/email";
import {validationMixin} from "vuelidate";
import deliverable from "@/validators/deliverable";
import browser from "@/services/browser";
import tracking from "@/services/tracking";
import Dimensions from "@/types/dimensions";
import geoLocator from "@/services/geo-locator";
import client from "@/utils/client";
import urlParams from "@/services/urlParams";

export default {
    mixins: [validationMixin],

    beforeMount() {
        this.fetchEarlyRecommendation();

        if (client.getCookie("plusdin_contact") || localStorage.getItem("plusdin_contact")) {
            const contact = JSON.parse(client.getCookie("plusdin_contact") || localStorage.getItem("plusdin_contact"));
            this.contact = contact;
            this.terms_accepted = this.contact.terms_accepted_at;
        }

        this.setupLoginABTest();
    },

    data() {
        return {
            nameIsFocus: false,
            emailIsFocus: false,
            contact: {
                email: "",
                google_avatar: null,
                google_id: null,
                has_accepted_terms: false,
                name: "",
                terms_accepted_at: null,
            },
            loginAbTestVariation: null,
            terms_accepted: false,
            loading: true,
            undeliverableEmail: false,
            recomendationURL: undefined,
            formattedDataFromQuiz: null,
            recomendationVersion: null,
            recommendationComplete: null,
            readyToRedirect: false
        };
    },

    validations: {
        contact: {
            email: {required, email},
            has_accepted_terms: {accepted: (value) => value === true},
            name: {required},
        },
    },

    computed: {
        ...mapState(["flow", "questions", "questionsV2", "response"]),

        isVariationB() {
            return this.loginAbTestVariation === "1";
        },

        isVariationC() {
            return this.loginAbTestVariation === "2";
        },
    },

    watch: {
        "contact.email"(email) {
            if (email) {
                this.emailIsFocus = true;
            }
        },
        "contact.name"(name) {
            if (name) {
                this.nameIsFocus = true;
            }
        },
        "contact.has_accepted_terms"(accepted) {
            const now = new Date().toJSON().slice(0, 19).replace("T", " ");
            this.contact.terms_accepted_at = accepted ? now : null;
        },
    },

    methods: {
        ...mapActions(["fetchRecommendation", "getFormattedDataFromCardQuiz"]),

        checkUrl(url) {
            return urlParams(url, this.$route.query)
        },

        async fetchEarlyRecommendation() {
            const recommendationData = await this.fetchRecommendation()
            this.recomendationURL = recommendationData.slug
            this.recomendationVersion = recommendationData.version
            this.recommendationComplete = recommendationData.recommendationComplete
            this.formattedDataFromQuiz = await this.getFormattedDataFromCardQuiz()
            this.loading = false;
        },

        trackGoogleButton() {
            tracking.push(
                "Recomendador de CC - Cadastro",
                "Login social - Google",
                "Iniciado"
            );
        },

        trackGoogleLogin() {
            tracking.push(
                "Recomendador de CC - Cadastro",
                "Login social - Google",
                "Finalizado"
            );
        },

        trackSaveForm() {
            tracking.push("Recomendador de CC - Cadastro", "Virou lead - Geral");
        },

        onLeaveInputName() {
            if (this.contact.name === "") {
                this.nameIsFocus = false;
            }
        },

        onLeaveInputEmail() {
            if (this.contact.email === "") {
                this.emailIsFocus = false;
            }
        },

        pushOnDimensionGA(dimensionKey, dimensionValue) {
            tracking.trackDimension(dimensionKey, dimensionValue);
        },

        async checkUndeliverableEmail() {
            this.undeliverableEmail = !(await deliverable(this.contact.email));

            if (this.undeliverableEmail) {
                this.loading = false;
            }

            return this.undeliverableEmail;
        },

        generateDitoData() {
            return {
                action: "preencheu-quiz",
                data: {
                    app: "plusdin",
                    questions: this.formattedDataFromQuiz,
                    recommender_version: this.recomendationVersion,
                    recomended_card: this.recommendationComplete,
                    origem_cadastro: window.location.origin + window.location.pathname,
                    fluxo_automacao: "fluxo_unico",
                },
            };
        },

        generateLeadData() {
            return {
                name: this.contact.name,
                email: this.contact.email,
                benefits: this.getQuestionValueLabel("benefits"),
                income: this.getQuestionValueLabel("income"),
                credit_status: this.getQuestionValueLabel("credit_status"),
                is_international: this.getQuestionValueLabel("is_international"),
                employment_bond: this.getQuestionValueLabel("employment_bond"),
            };
        },

        generateLeadTrackingData(lead) {
            return {
                app: "plusdin",
                name: lead.name,
                email: lead.email,
                source: "plusdin",
                referer: window.document.referrer,
                query_string: client.getParams(),
                questions: this.formattedDataFromQuiz,
                recommender_version: this.recomendationVersion,
                recomended_card: this.recommendationComplete,
                direct_to: "",
                fbp: client.getCookie("_fbp"),
                etsclientid: client.getCookie("_etsclientid"),
                gid: client.getCookie("_gid"),
                krux_id: client.getParam("krux_id"),
                sub_id: client.getParam("sub_id"),
                fbclid: client.getParam("fbclid"),
                gclid: client.getParam("gclid"),
                taboola_external_id: client.getParam("tb_clid"),
                utm_source: client.getParam("utm_source"),
                utm_medium: client.getParam("utm_medium"),
                utm_campaign: client.getParam("utm_campaign"),
                utm_content: client.getParam("utm_content"),
                utm_term: client.getParam("utm_term"),
                campaign_id: client.getParam("campaign_id"),
                ad_id: client.getParam("ad_id"),
                affiliateID: 0,
                fluxo_automacao: "fluxo_unico",
                origem_cadastro: window.location.origin + window.location.pathname,
                dito_data: this.generateDitoData(),
            };
        },

        generateMarketingCloudData(lead) {
            return {
                Nome: lead.name,
                Email: lead.email,
                Beneficios: this.getQuestionValueLabel("benefits"),
                Renda: this.getQuestionValueLabel("income"),
                Negativado: this.getQuestionValueLabel("credit_status"),
                "CC-Internacional": this.getQuestionValueLabel("is_international"),
                Ocupacao: this.getQuestionValueLabel("employment_bond"),
            };
        },

        async getMetaData() {
            return {
                ...browser.info(),
                source: this.getTrafficSource(),
                location: await geoLocator.locate(),
            };
        },

        getQuestion(key) {
            return (this.flow ? this.questionsV2 : this.questions).find(
                (question) => question.key === key
            );
        },

        /**
         * @todo Refactoring by extraction
         */
        getQuestionValueLabel(key) {
            let response = null;

            try {
                response = this.response[key];
                const question = this.getQuestion(key);

                if (Array.isArray(response)) {
                    return question.options
                        .filter((option) => response.includes(option.value))
                        .map((option) => option.text)
                        .join(", ");
                }

                return question.options.find((option) => option.value === response)
                    .text;
            } catch (ex) {
                return response;
            }
        },

        /**
         * @todo Refactoring by extraction
         */
        getQuestionsAndAnswers() {
            const allQuestions = this.flow ? this.questionsV2 : this.questions;
            const questions = Object.keys(this.response)
                .map(key => allQuestions.find(question => question.key === key));

            return questions.reduce((acc, question, index) => {
                acc[index + 1] = {
                    answer: this.getQuestionValueLabel(question.key),
                    question: question.title.replace(/<[^>]+>/gi, ''),
                    value: this.response[question.key],
                };

                return acc;
            }, {});
        },

        async goToResult(slug) {
            const leadSrc = window.location.search ? '&lead-src=recomendador-cc' : '?lead-src=recomendador-cc';
            const hashEmail = await this.hashEmail(this.contact.email)
            const hashParameter = `&brs_hashed_email=${hashEmail}`
            this.readyToRedirect = true
            
            setTimeout(() => {
                window.location = `${process.env.VUE_APP_HOSTNAME}/cartao-de-credito/recomendacao/resultado/${slug}/${window.location.search}${leadSrc}${hashParameter}`;
            }, 200)
        },

        getTrafficSource() {
            return new URLSearchParams(window.location.search).get("utm_source");
        },

        /**
         * @todo Refactoring by extraction to proper service
         */
        async hashEmail(email) {
            const msgUint8 = new TextEncoder().encode(email);
            const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
            const hashArray = Array.from(new Uint8Array(hashBuffer));

            return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
        },

        setupLoginABTest() {
            const testId = this.$envvars.VUE_APP_AB_SOCIAL_LOGIN_ID;

            if (!testId) return;

            this.optimizeIntervalId = setInterval(() => {
                if (window.google_optimize !== undefined) {
                    this.loginAbTestVariation = window.google_optimize.get(testId);
                    clearInterval(this.optimizeIntervalId);
                }
            }, 100);
        },

        onFocusInputName() {
            this.nameIsFocus = true;
            this.$refs.inputName.focus();
        },

        trackingOnFocusInputEmail() {
            this.emailIsFocus = true;
            this.$refs.inputEmail.focus();
            tracking.push("Recomendador de CC - Cadastro", "Email", "Iniciado");
        },

        async pushOnTracking(lead) {
            tracking.push("Recomendador de CC - Cadastro", "Email", "Finalizado");
            tracking.pushLead(await this.hashEmail(lead.email));
        },

        /**
         * @todo Refactoring by extracting internal label functions
         */
        pushAnswersOnGADimensions() {
            if (!this.flow) return;

            const hasValueLabel = (key, value) => {
                if (typeof this.response[key] === "undefined") return undefined;

                return this.response[key].includes(value) ? "Sim" : "Não";
            };

            const boolValueLabel = (key, onTrue = "Sim", onFalse = "Não") => {
                if (typeof this.response[key] === "undefined") return undefined;

                return this.response[key] ? onTrue : onFalse;
            };

            const acceptAnnualFeeLabel = () => {
                const label = this.getQuestionValueLabel("free_fee_recheck");

                return ["Sim", "Não"].includes(label)
                    ? label
                    : "Talvez se houver desconto";
            };

            const airlinesHabitsLabel = () => {
                if (typeof this.response.best_for_habits === "undefined")
                    return undefined;

                return this.response.best_for_habits === "multiple-airlines-benefits"
                    ? "Vantagens em várias linhas"
                    : "Vantagens em uma linha";
            };

            const moneyTierLabel = (key) => {
                const value = this.getQuestionValueLabel(key);

                if (value < 1000) {
                    return "Até R$ 1.000,00";
                } else if (value >= 1000 && value < 2500) {
                    return "Entre R$ 1.000,00 e R$ 2.500,00";
                } else if (value >= 2500 && value < 5000) {
                    return "Entre R$ 2.500,00 e R$ 5.000,00";
                } else if (value >= 5000 && value < 10000) {
                    return "Entre R$ 5.000,00 e R$ 10.000,00";
                } else if (value >= 10000 && value < 15000) {
                    return "Entre R$ 10.000,00 e R$ 15.000,00";
                } else if (value >= 15000 && value < 20000) {
                    return "Entre R$ 15.000,00 e R$ 20.000,00";
                } else if (value >= 20000 && value <= 25000) {
                    return "Entre R$ 20.000,00 e R$ 25.000,00";
                } else {
                    return "Acima de R$ 25.000,00";
                }
            };

            const creditStatusLabel = () => {
                return this.response.credit_status === "in-debit"
                    ? "Negativado"
                    : "Não-negativado";
            };

            const dimensions = {
                [Dimensions.NO_ANNUAL_FEES]: hasValueLabel("benefits", "no-annual-fee"),
                [Dimensions.TRAVEL_MILES]: hasValueLabel("benefits", "travel-miles"),
                [Dimensions.CASHBACK]: hasValueLabel("benefits", "cashback"),
                [Dimensions.DISCOUNTS]: hasValueLabel("benefits", "discounts"),
                [Dimensions.INCOME]: moneyTierLabel("income_value"),
                [Dimensions.CREDIT_STATUS]: creditStatusLabel(),
                [Dimensions.IS_INTERNATIONAL]: boolValueLabel(
                    "is_international",
                    "Solicitou",
                    "Diz não precisar"
                ),
                [Dimensions.OCCUPATION]: this.getQuestionValueLabel("employment_bond"),
                [Dimensions.AVAILABLE_TIME]:
                    this.flow === "long" ? "Com tempo" : "Sem tempo",
                [Dimensions.ACCEPT_ANNUAL_FEE]: acceptAnnualFeeLabel(),
                [Dimensions.AIRLINES_HABITS]: airlinesHabitsLabel(),
                [Dimensions.FAVORITE_AIRLINE]: this.getQuestionValueLabel("airline"),
                [Dimensions.ACCESS_VIP_LOUNGES]: hasValueLabel(
                    "airline_benefits",
                    "access-vip-lounges"
                ),
                [Dimensions.TICKETS_DISCOUNTS]: hasValueLabel(
                    "airline_benefits",
                    "tickets-discounts"
                ),
                [Dimensions.PREFERENTIAL_BOARDING]: hasValueLabel(
                    "airline_benefits",
                    "preferential-boarding"
                ),
                [Dimensions.LONGER_TICKETS_INSTALLMENT]: hasValueLabel(
                    "airline_benefits",
                    "longer-tickets-installment"
                ),
                [Dimensions.FREE_TRAVEL_INSURANCE]: hasValueLabel(
                    "airline_benefits",
                    "free-travel-insurance"
                ),
                [Dimensions.CREDIT_CARD_EXPENSES]: moneyTierLabel(
                    "credit_card_expenses"
                ),
                [Dimensions.PURCHASE_ON_INTERNET]: this.getQuestionValueLabel(
                    "purchase_on_internet"
                ),
                [Dimensions.BUY_ON_STORES]: this.getQuestionValueLabel("stores"),
                [Dimensions.ACCOUNTS_ON_BANKS]: this.getQuestionValueLabel(
                    "bank_accounts"
                ),
                [Dimensions.WANT_CREDIT_AND_DEBIT]: this.getQuestionValueLabel(
                    "credit_and_debit"
                ),
                [Dimensions.WANT_MONEY_WITHDRAW]: this.getQuestionValueLabel(
                    "withdraw_with_credit_card"
                ),
                [Dimensions.WANT_24H_BANK]: this.getQuestionValueLabel(
                    "can_withdraw_in_24h_bank"
                ),
                [Dimensions.WANT_ANDROID_APPLE_PAY]: this.getQuestionValueLabel(
                    "accept_google_apple_pay"
                ),
                [Dimensions.HAS_CREDIT_CARD]: this.getQuestionValueLabel(
                    "has_credit_card"
                ),
                [Dimensions.NEED_BANK_BRANCH_NEAR]: this.getQuestionValueLabel(
                    "bank_branch_near"
                ),
                [Dimensions.ZIPCODE]: this.getQuestionValueLabel("cep"),
                [Dimensions.COMMON_TRAVEL]: this.getQuestionValueLabel("common_travel"),
                [Dimensions.TRAVEL_PURPOSE]: this.getQuestionValueLabel(
                    "flight_purpose"
                ),
            };

            tracking.trackDimensions(dimensions);
        },

        trackOnDito(lead) {
            const data = this.generateDitoData(lead);

            if (typeof window.dito !== "undefined") {
                window.dito.identify({
                    id: window.dito.generateID(lead.email),
                    name: lead.name,
                    email: lead.email,
                    data: data.data,
                });
            }
        },

        async tryToSave() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.loading = true;

            localStorage.setItem("plusdin_contact", JSON.stringify(this.contact));
            localStorage.setItem(
                "plusdin_contact_lead",
                JSON.stringify({
                    ...this.response,
                    questionsAndAnswers: this.getQuestionsAndAnswers(),
                })
            );

            if (this.recomendationURL !== undefined) {
                this.goToResult(this.recomendationURL);
            }
        },

        async getUserDataFromGoogle() {
            this.trackGoogleButton();

            const googleUser = await this.$gAuth.signIn();
            const profile = googleUser.getBasicProfile();

            this.contact.google_id = googleUser.getId();
            this.contact.google_avatar = profile.TJ;
            this.contact.name = profile.Ad;
            this.contact.email = profile.$t;
            this.contact.has_accepted_terms = true;
            this.contact.terms_accepted_at = new Date()
                .toJSON()
                .slice(0, 19)
                .replace("T", " ");

            this.trackGoogleLogin();
            this.tryToSave();
        },
    },
};
</script>

<style lang="scss">
.gate__form {
    max-width: 295px;
    @apply w-full mx-auto my-8;

    .form__gate_field {
        font-family: $v2-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        @apply mb-6;
        .form__gate_field_wrapper {
            @apply relative mb-3 pb-3 h-10 w-full;
            border-bottom: 1px solid #ccc;

            input {
                @apply outline-none bg-transparent text-black w-full h-full z-10 absolute;
                .focus {
                    @apply text-black;
                    font-size: 14px;
                    line-height: 170%;
                }
            }

            label {
                color: #000000;
                transition: margin 0.6s ease-in-out;
                @apply absolute text-black z-20 mt-1;
                &.focus {
                    @apply text-gray-500;
                }

                &.focus {
                    margin-top: -1.25rem;
                }
            }
        }
    }

    .form__gate_button {
        position: relative;
        display: inline-block;
        min-width: 293px;
        min-height: 42px;
        background: $bg-green-500;
        border-radius: 4px;
        &:not(.active) {
            cursor: pointer;
        }
        .text {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            z-index: 10;
            opacity: 1;
            font-family: $v2-font-family;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 350%;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: #000000;
            .icon {
                max-width: 40px;
                display: inline;
            }
        }
        .progress {
            width: 0;
            z-index: 5;
            background: #00b450;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        &.active .progress {
            opacity: 1;
            animation: progress-anim .2s ease 0s;
        }
    }

    .strike {
        display: block;
        text-align: center;
        overflow: hidden;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .strike > span {
        position: relative;
        display: inline-block;
    }

    .strike > span:before,
    .strike > span:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 9999px;
        /* Here is the modification */
        border-top: 1px solid #cacacc;
    }

    .strike > span:before {
        right: 100%;
        margin-right: 15px;
    }

    .strike > span:after {
        left: 100%;
        margin-left: 15px;
    }

    .form__login_button {
        background-image: url("~@/icons/logo-google.png");
        background-repeat: no-repeat;
        background-position: 14% 50%;
        min-height: 42px;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        text-indent: 35px;
        width: 100%;
    }
}

.gate__terms-field-label {
    align-items: center;
    display: flex;

    input {
        margin-right: $space;
    }
}

.gate__terms-field-text {
    font-family: $v2-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #000000;

    a {
        @apply font-semibold;
    }
}

@keyframes progress-anim {
    0% {
        width: 0%;
    }
    5% {
        width: 0%;
    }
    10% {
        width: 15%;
    }
    30% {
        width: 40%;
    }
    50% {
        width: 55%;
    }
    80% {
        width: 100%;
    }
    95% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

@media screen and (min-width: $screen-tablet-min-width) {
    .gate__form {
        @apply m-0 my-16;
    }

    .gate__terms-link {
        @apply border-green-500;
        @apply border-b;
        padding-bottom: 1px;
    }
}
</style>
