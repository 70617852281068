<template>
  <section class="card-comparator__section card-comparator__section-extensive">
    <h3 class="card-comparator__section-title"><span>/</span> Categoria do cartão</h3>
    <hr class="card-comparator__section-division">
    <div class="card-comparator__section-wrapper">
      <template v-for="card of cards">
          <section
              :key="card.id"
              v-if="!card.empty"
              class="card-comparator__first-col-result"
          >
              <div class="card-comparator__item-box">
                  <div class="card-comparator__item-box__image">
                      <img :src="`/images/logo-${card.brand}.png`" />
                  </div>
                  <div class="card-comparator__item-box__title">
                      <p>Bandeira</p>
                  </div>
              </div>
              <div
                  :class="{ 'card-comparator__item-box-no-border--best': hasWinner('category', card) }"
                  class="card-comparator__item-box-no-border"
              >
                  <h4 class="card-comparator__item-description-extensive-small">
                      {{ categories[card.category] }}
                  </h4>
                  <p>Categoria</p>
              </div>
              <div class="card-comparator__item-box">
                  <div class="card-comparator__item-box__image">
                      <img :src="card.issuer.logo_file.url">
                  </div>
                  <div class="card-comparator__item-box__title">
                      <p>Emissor</p>
                  </div>
              </div>
              <div
                  :class="{ 'card-comparator__item-box-no-border--best-inline': hasWinner('rewards', card) }"
                  v-if="card.rewards_from || card.rewards_to || card.rewards_default_value"
                  class="card-comparator__item-box-no-border"
              >
                  <h4
                      @click="toggleTooltip(card)"
                      class="card-comparator__item-description-extensive-large"
                  >
                      <div
                          v-if="tooltips[card.slug] && card.rewards_observation"
                          class="card-comparator__tooltip-center"
                          :class="{'card-comparator__tooltip-right' : false}"
                      >
                          <p>{{ card.rewards_observation }}</p>
                      </div>
                      <template v-if="card.rewards_from && card.rewards_to">
                          {{ card.rewards_from.toFixed(1) }} a {{ card.rewards_to.toFixed(1) }}
                      </template>
                      <template v-else>{{ card.rewards_default_value.toFixed(1) }}</template>
                  </h4>
                  <p
                      @click="toggleTooltip(card)"
                      class="card-comparator__item-category-extensive"
                  >Pontos por Dólar que <strong>{{ rewardsExpirationText(card) }}</strong></p>
              </div>
              <div
                  v-else
                  class="card-comparator__item-box-no-border"
              >
                  <h4 class="
                      card-comparator__item-description-extensive-large
                      card-comparator__item-description-extensive-large--no-tooltip
                  ">0.0</h4>
                  <p class="card-comparator__item-category-extensive">
                      Este cartão não converte gastos em pontos.
                  </p>
              </div>
          </section>
          <section
              :key="card.id"
              v-else
              class="card-comparator__first-col-result"
          ></section>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    cards: Array,
    winners: Object
  },

  data () {
    return {
      categories: {
        black: 'Black',
        infinite: 'Infinite',
        platinum: 'Platinum',
        gold: 'Gold',
        international: 'Internacional',
        affinity: 'Afinidade',
        'co-branded': 'Co-branded',
        national: 'Nacional',
        academic: 'Universitário',
        prepaid: 'Pré-pago',
        virtual: 'Virtual'
      },
      tooltips: {}
    }
  },

  methods: {
    hasWinner (key, card) {
      return this.winners[key] === card.slug
    },

    rewardsExpirationText (card) {
      if (!card.rewards_months_expiration) return 'nunca expiram'

      const months = card.rewards_months_expiration
      const years = Math.floor(months / 12)

      if (!years) {
          return `expira${months > 1 ? 'm' : ''} em ${months} ${months > 1 ? 'meses' : 'mês'}`
      }

      return `expira${years > 1 ? 'm' : ''} em ${years} ano${years > 1 ? 's' : ''}`
    },

    toggleTooltip (card) {
        this.$set(this.tooltips, card.slug, !this.tooltips[card.slug])
    }
  }
}
</script>

<style>
</style>
