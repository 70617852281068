const Dimensions = {
    ETUS_ID: 'etus_id',
    CP_SESSION: 'cp_session',
    GA_ID: 'ga_id',
    LEAD: 'lead',
    FB_CAMPAIGN_ID: 'fb_campaign_id',
    CLIENT_ID: 'client_id',
    SESSION_ID: 'session_id',
    ADSET_ID: 'adset_id',
    AD_ID: 'ad_id',
    TB_CLID: 'tb_clid',
    SITE_AN: 'site_an',
    CARD_REQUESTED: 'card_requested',
    RECOMMENDATION_REJECTED: 'recommendation_rejected',
    CARD_RECOMMENDED: 'card_recommended',
    CC_QUIZ_ABANDONED: 'cc_quiz_abandoned',
    NO_ANNUAL_FEES: 'no_annual_fees',
    TRAVEL_MILES: 'travel_miles',
    REWARDS: 'rewards',
    CASHBACK: 'cashback',
    DISCOUNTS: 'discounts',
    TRAVEL_LOUNGES: 'travel_lounges',
    INCOME: 'income',
    CREDIT_STATUS: 'credit_status',
    IS_INTERNATIONAL: 'is_international',
    OCCUPATION: 'occupation',
    CONTENT_AUTHOR: 'content_author',
    CONTENT_CATEGORY: 'content_category',
    CONTENT_PUBLISH_AT: 'content_publish_at',
    AVAILABLE_TIME: 'available_time',
    ACCEPT_ANNUAL_FEE: 'accept_annual_fee',
    AIRLINES_HABITS: 'airlines_habits',
    FAVORITE_AIRLINE: 'favorite_airline',
    ACCESS_VIP_LOUNGES: 'access_vip_lounges',
    TICKETS_DISCOUNTS: 'tickets_discounts',
    PREFERENTIAL_BOARDING: 'preferential_boarding',
    LONGER_TICKETS_INSTALLMENT: 'longer_tickets_installment',
    FREE_TRAVEL_INSURANCE: 'free_travel_insurance',
    CREDIT_CARD_EXPENSES: 'credit_card_expenses',
    PURCHASE_ON_INTERNET: 'purchase_on_internet',
    BUY_ON_STORES: 'buy_on_stores',
    ACCOUNTS_ON_BANKS: 'accounts_on_banks',
    WANT_CREDIT_AND_DEBIT: 'want_credit_and_debit',
    WANT_MONEY_WITHDRAW: 'want_money_withdraw',
    WANT_24H_BANK: 'want_24h_bank',
    WANT_ANDROID_APPLE_PAY: 'want_android_apple_pay',
    HAS_CREDIT_CARD: 'has_credit_card',
    NEED_BANK_BRANCH_NEAR: 'need_bank_branch_near',
    ZIPCODE: 'zipcode',
    COMMON_TRAVEL: 'common_travel',
    TRAVEL_PURPOSE: 'travel_purpose',
    AFF_ID: 'aff_id'
}

export default Dimensions
