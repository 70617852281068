import airlinesService from '@/services/airlines'

const mutations = {
    SET_AIRLINES: 'setAirlines'
}

export default {
    namespaced: true,

    state: () => ({
        airlines: []
    }),

    actions: {
        load ({ commit }) {
            return airlinesService.forRecommendation().then( async (airlines) => {
                const airlineData = await Promise.all(airlines.map(async (airline) => {
                    const image = await airlinesService.loadImage(airline.logo_file.name)

                    airline.logo_file.original_url = `${process.env.VUE_APP_API_ASSETS_URL}/airlines/${image}`

                    return airline
                }))

                commit(mutations.SET_AIRLINES, { airlineData })

                return airlineData
            })
        }
    },

    mutations: {
        [mutations.SET_AIRLINES] (state, payload) {
            state.airlines = payload.airlineData
        }
    }
}
