var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'footer_recommendation--is-home' : _vm.isHome,
        'fixed bottom-0 left-0 w-full':
            _vm.isFixedForLargeDisplay && _vm.$windowSize().height >= 800
    },attrs:{"id":"footer_recommendation"}},[_c('div',{staticClass:"footer_container"},[(_vm.isFooterFull)?_c('div',{staticClass:"footer_container__menu"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":_vm.checkUrl('/politica-de-privacidade'),"target":"_blank"}},[_vm._v("• Política de Privacidade")])]),_c('li',[_c('a',{attrs:{"href":_vm.checkUrl('/termos-de-uso'),"target":"_blank"}},[_vm._v("• Termos de Serviço")])])])]):_vm._e(),_c('div',{class:{
                'footer_container__copyright--is_full' : !_vm.isFooterFull,
                'footer_container__copyright--normal' : _vm.isFooterFull
            }},[_vm._v("© "+_vm._s(_vm.currentlyYear)+" - Plusdin - Todos os direitos reservados")])])])}
var staticRenderFns = []

export { render, staticRenderFns }