export default class Repository {
    constructor(settings = {}) {
        this.baseUrl =
            settings.baseUrl || process.env.VUE_APP_API_URL;
        this.recommenderURL =
            settings.recommenderURL || process.env.VUE_APP_DATA_API_URL;
        this.recommenderUser =
            settings.recommenderUser ||
            process.env.VUE_APP_DATA_API_USERNAME;
        this.recommenderPass =
            settings.recommenderPass ||
            process.env.VUE_APP_DATA_API_PASSWORD;
    }
}
