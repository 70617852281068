export default {
    clearKeysOnStorage () {
        localStorage.removeItem('plusdin_push_on_ga')
        localStorage.removeItem('plusdin_card_complete')
        localStorage.removeItem('plusdin_is_partner')
    },

    isCampaign () {
        if (typeof window === 'undefined') return false
    
        const campaign = new URLSearchParams(window.location.search).get('campaign')
    
        return ['true', '1'].includes(campaign)
    },

    isMobile () {
        if (typeof navigator === 'undefined') return true
        if (typeof window === 'undefined') return true
    
        let isMobile = false
    
        isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune|Windows Phone/i.test(
            navigator.userAgent
        )
    
        if (!isMobile) {
            isMobile = window.innerWidth <= 1024 && window.innerHeight <= 768
        }
    
        return isMobile
    },

    windowSize () {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
}