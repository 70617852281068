<template>
    <article class="recommendation__card-item">
        <div class="recommendation__card-range range">
            <label for="range" class="range__values">
                <div class="range__value--min">0</div>
                <div class="range__value">
                    <span v-if="rangeValue >= rangeMax">+</span>{{ rangeValue >= rangeMax ? rangeMax - 1 : rangeValue }} {{question.highlightedText}}
                </div>
                <div class="range__value--max">+{{ rangeMax - 1 }}</div>
            </label>
            <input
                v-model="rangeValue"
                :style="backgroundColor"
                @input="updateInputData"
                type="range"
                min="0"
                :max="rangeMax"
                name="range"
                ref="range-income"
                :step="step"
                value="0"
                class="range__input"
            />
        </div>
    </article>
</template>

<script>
export default {
    name: 'Range',

    props: {
        option: {
            type: Object,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        step: {
            default: 1,
            type: Number
        },
        rangeMax: {
            default: 11,
            type: Number
        }
    },

    data () {
        return {
            rangeValue: 0
        }
    },

    computed: {
        backgroundColor () {
            let percentage = Math.round(this.rangeValue * 100 / this.rangeMax)

            return {
                background: `linear-gradient(to right, #00e169 ${percentage}%, #00e169 ${percentage}%, #cacacc ${percentage}%)`
            }
        }
    },

    methods: {
        updateInputData () {
            this.$emit('rangeValue', this.rangeValue, this.question.key, this.option.value, this.option.nextQuestion)
        }
    }
}
</script>

<style lang="scss">
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none !important;
        position: relative;
        top: -50%;
        width: 30px !important;
        height: 30px !important;
        background: #00E169 !important;
        border-radius: 50% !important;
        cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none !important;
        position: relative;
        top: -50%;
        width: 25px !important;
        height: 25px !important;
        background: #00E169 !important;
        border-radius: 50% !important;
        cursor: pointer;
    }
</style>
