import Bowser from 'bowser'

export default {
    info () {
        if (typeof window === 'undefined') return
        return Bowser.parse(window.navigator.userAgent)
    },

    isMobile () {
        if (typeof window === 'undefined') return
        return Bowser.getParser(window.navigator.userAgent).is('mobile')
    }
}
