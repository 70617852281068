<template>
<section class="card-comparator__section card-comparator__section-extensive">
    <h3 class="card-comparator__section-title"><span>/</span> Detalhes</h3>
    <hr class="card-comparator__section-division">
    <div class="card-comparator__section-wrapper">
        <template v-for="card of cards">
            <section
                :key="card.id"
                v-if="!card.empty"
                class="card-comparator__first-col-result"
            >
                <ul>
                    <li
                        class="card-comparator__line-item"
                        v-for="benefit in card.benefits"
                        :key="benefit.value"
                    >
                        <span class="card-comparator__span-line"></span>
                        {{ benefit.value }}
                    </li>
                </ul>
            </section>
            <section
                :key="card.id"
                v-else
                class="card-comparator__first-col-result"
            ></section>
        </template>
    </div>
</section>
</template>

<script>
export default {
    props: {
        cards: Array,
        observer: {}
    },

    mounted () {
        this.observer.observe(this.$el)
    }
}
</script>

<style lang="scss">
</style>
