<template>
    <div
        id="recommendation__question"
        class="recommendation-v2 recommendation__page"
        :style="this.gateBackgroundColor"
    >
        <div class="recommendation__content">
            <header class="recommendation__top wrapper">
                <div class="recommendation__logo">
                    <a :href="!isCampaign ? checkUrl('/') : null"
                        aria-label="Plusdin"
                        class="logo__link"
                    >
                        <picture>
                            <source
                                media="(max-width: 1023px)"
                                srcset="@/images/logos/logo-plusdin-short.svg"
                                type="image/svg+xml"
                            />
                            <source
                                media="(min-width: 1024px)"
                                srcset="@/images/logos/logo-plusdin.svg"
                                type="image/svg+xml"
                            />
                            <img
                                alt="Plusdin"
                                class="logo-plusdin"
                                src="@/images/logos/logo-plusdin-short.svg"
                            />
                        </picture>
                        <span class="sr-only">Plusdin</span>
                    </a>
                </div>
                <Duda />
                <div class="recommendation__back-button">
                    <button type="button" @click="restart">
                        <i class="icon icon-undo"></i>
                        <span>Refazer</span>
                    </button>
                </div>
            </header>
        </div>
        <div class="recommendation__page_home">
            <div class="recommendation__page_container">
                <div class="recommendation__gate__wrapper">
                    <div class="recommendation__gate_hero">
                        <img
                            src="@/images/digital-accounts/hero.png"
                            alt=""
                        />
                    </div>
                    <div class="recommendation__gate_form">
                        <h1>Fantástico!</h1>
                        <h2>
                            Encontramos a conta bancária perfeita para você!
                        </h2>
                        <h3>
                            Para ver nossa recomendação, escolha uma das opções
                            abaixo:
                        </h3>
                        <Form />
                    </div>
                    <div class="recommendation__gate_bottom">
                        <img
                            src="@/images/digital-accounts/hero-mobile.png"
                        />
                    </div>
                </div>
            </div>
        </div>
        <FooterRecommendation
            v-if="isCampaign"
            :isFooterFull="true"
            :isFixedForLargeDisplay="true"
        />
        <Footer
            v-if="!isCampaign"
            :isFooterFull="true"
            :isFixedForLargeDisplay="true"
        />
    </div>
</template>

<script>
import FooterRecommendation from "@/components/sections/credit-card/recommendation/Footer";
import Footer from "@/components/Footer";
import Form from "@/components/sections/digital-accounts/recommendation/gate/Form";
import Duda from "@/components/Duda";
import { mapActions, mapState } from "vuex";
import urlParams from "@/services/urlParams";

export default {
    components: {
        Form,
        Footer,
        FooterRecommendation,
        Duda
    },

    computed: {
        ...mapState(["flow"]),
        ...mapState("DAStore", ["gateBackgroundColor", "currentIndex"]),

        isCampaign() {
            return this.$route.query.campaign === "true";
        }
    },

    beforeMount() {
        if (this.currentIndex === null) {
            this.sendToStart();
        }
    },

    methods: {
        ...mapActions('DAStore', [
            "clearAskedQuestions",
            "clearFlow",
            "clearResponseKey",
            "clearQuestionsHistory"
        ]),

        restart() {
            this.clearResponseKey();
            this.clearAskedQuestions();
            this.clearQuestionsHistory();
            this.sendToStart();
        },

        sendToStart() {
            this.$router.push({
                name: "v1.digital-accounts.recommendation.questions.question",
                params: { slug: "inicio" },
                query: this.$route.query
            });
        },

        checkUrl(url) {
            return urlParams(url, this.$route.query)
        }
    }
};
</script>

<style lang="scss">
.recommendation__page {
    .recommendation__page_top {
        @apply max-w-sm mx-auto;

        .recommendation__page_wrapper {
            @apply pt-3 px-0;

            div {
                @apply flex flex-row justify-between;
                > div {
                    @apply w-4/12 flex flex-row;
                    &:nth-child(2) {
                        @apply justify-center;
                    }
                    &:last-child {
                        @apply justify-end;
                    }
                }
                img.credit_card__page_header__logo_mobile {
                    @apply block;
                }
                img.credit_card__page_header__logo_desktop {
                    @apply hidden;
                }
                .credit_card__page_header__avatar {
                    @apply flex flex-col max-w-xs mx-auto text-center w-full;
                    p {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 170%;
                        text-align: center;
                        color: #000000;
                        @apply mt-3;
                    }
                }
                .credit_card__page_header__right_button {
                    button {
                        @apply outline-none;
                    }
                }
            }
            p {
                @apply text-center;
            }
        }
    }

    .recommendation__page_home {
        min-height: 510px;
        .recommendation__page_container {
            @apply mt-3;
            .recommendation__gate__wrapper {
                @apply w-full flex-row flex-wrap;
                .recommendation__gate_hero {
                    @apply w-full hidden;
                }
                .recommendation__gate_form {
                    @apply px-6 mx-auto;
                    h1 {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 130%;
                        text-align: center;
                        color: $text-gray-700;
                        @apply my-2;
                    }
                    h2 {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 150%;
                        text-align: center;
                        color: $text-gray-700;
                        @apply my-2;
                    }
                    h3 {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 180%;
                        text-align: center;
                        color: $text-gray-700;
                        @apply my-2;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: $screen-tablet-min-width) {
    .recommendation__page {
        @apply h-auto;
        .recommendation__page_top {
            @apply max-w-screen-xl;
            .recommendation__page_wrapper {
                div {
                    img.credit_card__page_header__logo_mobile {
                        @apply hidden;
                    }
                    img.credit_card__page_header__logo_desktop {
                        @apply block;
                    }
                }
            }
        }
        .recommendation__page_home {
            @apply h-auto;
            .recommendation__page_container {
                section {
                    @apply flex flex-col items-center;
                }
                .recommendation__gate__wrapper {
                    @apply flex flex-row justify-between mx-auto max-w-screen-xl;
                    max-width: 1024px;
                    .recommendation__gate_hero {
                        @apply w-6/12 flex flex-row justify-center items-center;
                    }
                    .recommendation__gate_form {
                        @apply max-w-sm;
                        h1,
                        h2,
                        h3 {
                            @apply text-left my-3;
                        }
                        h1 {
                            font-weight: 600;
                            font-size: 30px;
                        }
                        h2 {
                            font-size: 24px;
                            line-height: 29px;
                        }
                        h3 {
                            font-size: 16px;
                            line-height: 180%;
                        }
                    }
                    .recommendation__gate_bottom {
                        @apply hidden;
                    }
                }
            }
        }
    }
}
</style>
