import cardComparator from './card-comparator/routes'
import creditCard from './credit-card/routes'
import digitalAccounts from './digital-accounts/routes'
import tracking from '../services/tracking'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        redirect: {
            name: 'v2.credit-card.recommendation.index',
            params: { slug: 'inicio' }
        }
    },
    ...cardComparator,
    ...creditCard,
    ...digitalAccounts
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach(to => {
    tracking.trackPageViewDimensions(to)
})

router.beforeEach((to, from, next) => {
  if(to.name === null) {
    window.location.replace('https://plusdin.com' + to.path)
  }
  next()
})
export default router
