import Gate from '../../views/credit-card/Gate'
import guards from './guards'
import Index from '../../views/credit-card/Index'
import Question from '../../views/credit-card/Question'

export default [
    {
        path: '/cartao-de-credito/recomendacao',
        name: 'v2.credit-card.recommendation.index',
        component: Index,
    },
    {
        path: '/cartao-de-credito',
        name: 'v2.credit-card.recommendation.main',
        redirect: {
            name: 'v2.credit-card.recommendation.question',
            params: { slug: 'inicio', flow: 'short' }
        }
    },
    {
        path: '/cartao-de-credito/recomendacao/perguntas/:flow?/:slug?',
        name: 'v2.credit-card.recommendation.question',
        component: Question,
        beforeEnter: guards.question
    },
    {
        path: '/cartao-de-credito/recomendacao/pre-resultado',
        name: 'v2.credit-card.recommendation.gate',
        component: Gate,
        beforeEnter: guards.gate
    }
]
