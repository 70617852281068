<template>
<section class="card-comparator__section card-comparator__section-resources">
    <h3 class="card-comparator__section-title"><span>/</span> Serviços e benefícios</h3>
    <hr class="card-comparator__section-division">
    <div class="card-comparator__section-wrapper">
        <template v-for="card of cards">
            <section
                :key="card.id"
                v-if="!card.empty"
                class="card-comparator__first-col-result"
            >
                <ul>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.is_international }"
                    >É internacional</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.accept_in_debit }"
                    >Aceita negativados</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.gives_travel_miles }"
                    >Acumula milhas</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.is_credit_and_debit }"
                    >Funciona como crédito e débito</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.allow_withdraw_at_24_hours_bank }"
                    >Saque no banco 24h</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.gives_travel_lounges }"
                    >Acesso a salas VIP</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.gives_cashback }"
                    >Paga Cashback</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.allow_cash_withdrawals_in_credit_function }"
                    >Saque em dinheiro na função crédito</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.accept_payment_by_google_pay }"
                    >Google Pay</li>
                    <li
                        class="card-comparator__list-item card-comparator__list-item--check"
                        :class="{'card-comparator__list-item--uncheck': !card.accept_payment_by_apple_pay }"
                    >Apple Pay</li>
                </ul>
            </section>
            <section
                :key="card.id"
                v-else
                class="card-comparator__first-col-result"
            ></section>
        </template>
    </div>
</section>
</template>

<script>
export default {
    props: {
        cards: Array,
        observer: {}
    },

    mounted () {
        this.observer.observe(this.$el)
    }
}
</script>

<style lang="scss">
</style>
