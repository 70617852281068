<template>
    <div class="recommendation__page">
        <div class="recommendation__page_top">
            <div class="recommendation__page_wrapper">
                <div>
                    <div class="items-center credit_card__page_header__logo_wrapper">
                        <a
                            class="credit_card__page_header__logo_desktop"
                            :href="checkUrl('/')"
                        >
                            <img
                                class="credit_card__page_header__logo credit_card__page_header__logo_desktop"
                                src="~@/icons/logo-plusdin.svg"
                                alt="Plusdin"
                            />
                        </a>
                        <a
                            class="credit_card__page_header__logo_mobile"
                            :href="checkUrl('/')"
                        >
                            <img
                                class="credit_card__page_header__logo credit_card__page_header__logo_mobile"
                                src="~@/icons/logo-plusdin-short.svg"
                                alt="Plusdin"
                            />
                        </a>
                    </div>

                    <Duda/>
                    <div class="credit_card__page_header__right_button">
                        <button 
                            @click="restart" 
                            type="button"
                            v-if="false"
                        >
                            <img src="~@/icons/quiz/button-undo-top.svg"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="recommendation__page_home">
            <div class="recommendation__page_container">
                <div class="recommendation__gate__wrapper">
                    <div class="recommendation__gate_hero">
                        <img src="~@/icons/quiz/gate/hero.svg"/>
                    </div>
                    <div class="recommendation__gate_form">
                        <h1>Fantástico!</h1>
                        <h2>
                            Encontramos a oferta de cartão de crédito perfeita para você!
                        </h2>
                        <h3>Para ver nossa recomendação, insira seus dados abaixo</h3>
                        <Form/>
                    </div>
                    <div class="recommendation__gate_bottom">
                        <img src="~@/icons/quiz/gate/hero-mobile.svg"/>
                    </div>
                </div>
            </div>
        </div>
        <FooterRecommendation
            v-if="isCampaign"
            :isFooterFull="true"
            :isFixedForLargeDisplay="true"
        />
        <Footer
            v-if="!isCampaign"
            :isFooterFull="true"
            :isFixedForLargeDisplay="true"
            :no-links="true"
        />
    </div>
</template>

<script>
import FooterRecommendation from "@/components/sections/credit-card/recommendation/Footer";
import Duda from "@/components/Duda";
import Footer from "@/components/Footer";
import Form from "@/components/sections/credit-card/recommendation/gate/Form";
import {mapActions, mapState} from "vuex";
import urlParams from "@/services/urlParams";

export default {
    components: {
        Form,
        Duda,
        Footer,
        FooterRecommendation,
    },

    mounted() {
        sessionStorage.setItem("ga_cc_tracked", false);
    },

    computed: {
        ...mapState(["flow", "lastQuizResponse"]),

        isCampaign() {
            return this.$route.query.campaign === "true";
        }
    },

    methods: {
        ...mapActions(["clearAskedQuestions", "clearResponseKey"]),

        checkUrl(url) {
            return urlParams(url, this.$route.query);
        },

        restart() {
            const onFlow = !!this.flow;

            if (onFlow) {
                this.clearResponseKey();
                this.clearAskedQuestions();
            }

            this.sendToStart(onFlow);
        },

        sendToStart(onFlow) {
            if (onFlow) {
                return this.$router
                    .push({
                        name: "v2.credit-card.recommendation.question",
                        params: { slug: 'inicio', flow: 'long' },
                        query: this.$route.query,
                    })
                    .catch(() => {
                    });
            }

            this.$router
                .push({
                    name: "v2.credit-card.recommendation.main",
                    query: this.$route.query,
                })
                .catch(() => {
                });
        },
    },
};
</script>

<style lang="scss">
.recommendation__page {
    .recommendation__page_top {
        @apply max-w-sm mx-auto;

        .recommendation__page_wrapper {
            @apply pt-3 px-0;

            div {
                @apply flex flex-row justify-between;
                > div {
                    @apply w-4/12 flex flex-row;
                    &:nth-child(2) {
                        @apply justify-center;
                    }

                    &:last-child {
                        @apply justify-end;
                    }
                }

                .credit_card__page_header__logo_mobile {
                    @apply block;
                }

                .credit_card__page_header__logo_desktop {
                    @apply hidden;
                }

                .credit_card__page_header__avatar {
                    @apply flex flex-col max-w-xs mx-auto text-center w-full;
                    p {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 170%;
                        text-align: center;
                        color: #000000;
                        @apply mt-3;
                    }
                }

                .credit_card__page_header__right_button {
                    button {
                        @apply outline-none;
                    }
                }
            }

            p {
                @apply text-center;
            }
        }
    }

    .recommendation__page_home {
        min-height: 510px;

        .recommendation__page_container {
            @apply mt-3;
            .recommendation__gate__wrapper {
                @apply w-full flex-row flex-wrap;
                .recommendation__gate_hero {
                    @apply w-full hidden;
                }

                .recommendation__gate_form {
                    @apply px-6 mx-auto;
                    h1 {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 130%;
                        text-align: center;
                        color: $text-gray-700;
                        @apply my-2;
                    }

                    h2 {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 150%;
                        text-align: center;
                        color: $text-gray-700;
                        @apply my-2;
                    }

                    h3 {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 180%;
                        text-align: center;
                        color: $text-gray-700;
                        @apply my-2;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $screen-tablet-min-width) {
    .recommendation__page {
        @apply h-auto;
        .recommendation__page_top {
            @apply max-w-screen-xl;
            .recommendation__page_wrapper {
                div {
                    .credit_card__page_header__logo_mobile {
                        @apply hidden;
                    }

                    .credit_card__page_header__logo_desktop {
                        @apply block;
                    }
                }
            }
        }

        .recommendation__page_home {
            @apply h-auto;
            .recommendation__page_container {
                section {
                    @apply flex flex-col items-center;
                }

                .recommendation__gate__wrapper {
                    @apply flex flex-row justify-between mx-auto max-w-screen-xl;
                    max-width: 1024px;

                    .recommendation__gate_hero {
                        @apply w-6/12 flex flex-row justify-center items-center;
                    }

                    .recommendation__gate_form {
                        @apply max-w-sm;
                        h1,
                        h2,
                        h3 {
                            @apply text-left my-3;
                        }

                        h1 {
                            font-weight: 600;
                            font-size: 30px;
                        }

                        h2 {
                            font-size: 24px;
                            line-height: 29px;
                        }

                        h3 {
                            font-size: 16px;
                            line-height: 180%;
                        }
                    }

                    .recommendation__gate_bottom {
                        @apply hidden;
                    }
                }
            }
        }
    }
}
</style>
