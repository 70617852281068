export function stripTags (string) {
    return string.replace(/(<[^>]+?>)/gi, '')
}

export function slugify (string, separator = '-') {
    return string.toLowerCase()
        .replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
        .replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
        .replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
        .replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
        .replace(/đ/gi, 'd')
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s*$/g, '')
        .trim()
        .replace(/\s+/g, separator)
}
