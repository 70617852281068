import Repository from './repository'
import axios from 'axios'

class Ranks extends Repository {
    find () {
        return axios.get(`${this.baseUrl}/ranks/cards`)
            .then(response => response.data)
    }
}

export default new Ranks()
