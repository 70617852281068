<template>
<main id="page-card-comparator">
    <Header />
    <div class="card-comparator__content">
        <div class="ad-unit" data-position="top"></div>
        <ComparatorHeader
            :cards="fullCards"
            v-if="fullCards.length"
        />
        <CardsChooser
            @choosed="goToComparision"
            :scroll="scrolled"
            :slugs="slugs"
            :has-first-card="!!firstCard.id"
            :has-second-card="!!secondCard.id"
        />
        <CardsHeaders
            :cards="fullCards"
            :scroll="scrolled"
            @removed="goToComparision"
        />
        <CardsDetails
            :cards="fullCards"
            :observer="observer"
            v-if="filledCards.length"
        />
        <CardsCategory
            :cards="fullCards"
            v-if="filledCards.length"
            :winners="winners"
        />
        <div v-if="filledCards.length" class="ad-unit" data-position="content"></div>
        <CardsRates
            :cards="fullCards"
            :observer="observer"
            v-if="filledCards.length"
            :winners="winners"
        />
        <div v-if="filledCards.length" class="ad-unit" data-position="content"></div>
        <CardsResources
            :cards="fullCards"
            :observer="observer"
            v-if="filledCards.length"
        />
        <div v-if="filledCards.length" class="ad-unit" data-position="content"></div>
        <CardsAdvantages
            :cards="fullCards"
            v-if="filledCards.length"
        />
        <div v-if="filledCards.length" class="ad-unit" data-position="content"></div>
        <CardsDisadvantages
            :cards="fullCards"
            v-if="filledCards.length"
        />
        <div v-if="filledCards.length" class="ad-unit" data-position="content"></div>
        <CardsRewardsPrograms
            :cards="fullCards"
            v-if="filledCards.length"
        />
    </div>

    <section
        v-if="false && firstCard.id && secondCard.id"
        class="card-comparator__section-footer card-comparator__result"
    >
        <div class="card-comparator__section-footer-content">
            <h3 class="card-comparator__section-title-footer">
                Análise Completa dos nossos especialistas
            </h3>
            <div
                :class="{'card-comparator__section-inline-row--large': threeCards}"
                class="card-comparator__section-inline-row"
            >
                <div v-show="firstCard.id" class="card-comparator__inline-row--reduced-image">
                    <img class="card-comparator__footer-image" :src="firstCard.image_file.url">
                </div>
                <span v-show="secondCard.id">X</span>
                <div v-show="secondCard" class="card-comparator__inline-row--reduced-image">
                    <img class="card-comparator__footer-image" :src="secondCard.image_file.url">
                </div>
                <span v-show="threeCards.id">X</span>
                <div v-show="thirdCard.id" class="card-comparator__inline-row--reduced-image">
                    <img class="card-comparator__footer-image">
                </div>
            </div>
            <p>Turpis quis ac integer quisque. Blandit in imperdiet at placerat libero massa.
                Nisl leo vitae ut a convallis id augue elit ultrices.
                iverra suspendisse proin nisl pretium nisl mauris ornare felis.
                Libero, tellus eget porttitor facilisis. Auctor quam pellentesque et,
                id adipiscing ullamcorper neque lectus. Faucibus interdum at <br/><br/>
                Turpis quis ac integer quisque. Blandit in imperdiet at placerat libero massa.
                Nisl leo vitae ut a convallis id augue elit ultrices.
                iverra suspendisse proin nisl pretium nisl mauris ornare felis.
                Libero, tellus eget porttitor facilisis. Auctor quam pellentesque et,
                id adipiscing ullamcorper neque lectus. Faucibus interdum at </p>
            <!-- <div class="ads">
                <AdBlock/>
            </div> -->
            <h4 class="card-comparator__section-label-footer">Lorem ipusm dolor</h4>
            <p>Viverra suspendisse proin nisl pretium nisl mauris ornare felis.
                Libero, tellus eget porttitor facilisis. Auctor quam pellentesque et,
                id adipiscing ullamcorper neque lectus. Faucibus interdum at ut est,
                duis tincidunt enim aliquam. At imperdiet eget fringilla cras. Faucibus
                vitae interdum aliquet tristique blandit accums</p>
            <hr class="card-comparator__section-division-footer">
            <h4 class="card-comparator__section-label-footer">Faucibis interdum at</h4>
            <p>Viverra suspendisse proin nisl pretium nisl mauris ornare felis.
                Libero, tellus eget porttitor facilisis. Auctor quam pellentesque et,
                id adipiscing ullamcorper neque lectus. Faucibus interdum at ut est,
                duis tincidunt enim aliquam. At imperdiet eget fringilla cras. Faucibus
                vitae interdum aliquet tristique blandit accums</p>
        </div>
    </section>

    <Newsletter />
    <Footer />
</main>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Header from '@/components/Header'
import CardsAdvantages from '@/components/card-comparator/CardsAdvantages'
import CardsCategory from '@/components/card-comparator/CardsCategory'
import CardsChooser from '@/components/card-comparator/CardsChooser'
import CardsDetails from '@/components/card-comparator/CardsDetails'
import CardsDisadvantages from '@/components/card-comparator/CardsDisadvantages'
import CardsHeaders from '@/components/card-comparator/CardsHeaders'
import CardsResources from '@/components/card-comparator/CardsResources'
import CardsRewardsPrograms from '@/components/card-comparator/CardsRewardsPrograms'
import CardsRates from '@/components/card-comparator/CardsRates'
import ComparatorHeader from '@/components/card-comparator/Header'
import Newsletter from '@/components/article/Newsletter'
import Footer from '@/components/Footer'
import { slugify } from '@/utils/string'
import tracking from '@/services/tracking'

const { mapState, mapActions } = createNamespacedHelpers('cardsComparision')

function fakeMapper (card, index) {
    if (!card) {
        card = {
            id: (new Date()).toISOString() + index,
            empty: true
        }
    }

    return card
}

export default {
    components: {
        CardsAdvantages,
        CardsCategory,
        CardsChooser,
        CardsDetails,
        CardsDisadvantages,
        CardsHeaders,
        CardsResources,
        CardsRewardsPrograms,
        CardsRates,
        ComparatorHeader,
        Footer,
        Header,
        Newsletter
    },

    data () {
        return {
            observer: null,
            scrolled: false,
            slugs: [],
            threeCards: false,
            tracked: []
        }
    },

    beforeMount () {
        window.addEventListener('scroll', this.handleScroll)
        this.observer = new IntersectionObserver(this.assertSectionsVisible, { threshold: 0.5 })
    },

    destroyed () {
        window.removeEventListener('scroll', this.handleScroll)
    },

    watch: {
        '$route': {
            handler (to) {
                this.slugs = (to.params.slugs || '')
                    .split('-X-')
                    .filter(slug => !!slug)

                if (this.slugs.length) {
                    this.compare(this.slugs)
                }
            },
            immediate: true
        }
    },

    computed: {
        ...mapGetters(['isMobile']),

        ...mapState(['comparision']),

        cards () {
            return this.slicedCard
                .map(slug => this.comparision && this.comparision[slug])
                .map(fakeMapper)
        },

        filledCards () {
            return this.cards.filter(card => !card.empty)
        },

        firstCard () {
            return (this.comparision && this.comparision[this.slugs[0]]) || {}
        },

        fullCards () {
            let cards = JSON.parse(JSON.stringify(this.cards))

            if (cards.length < this.maxCards) {
                cards.push(...Array(this.maxCards - cards.length).fill(null))
            }

            return cards.map(fakeMapper)
        },

        maxCards () {
            return 2
        },

        secondCard () {
            return (this.comparision && this.comparision[this.slugs[1]]) || {}
        },

        slicedCard () {
            return this.slugs.slice(0, this.maxCards)
        },

        thirdCard () {
            return (this.comparision && this.comparision[this.slugs[2]]) || {}
        },

        topBannerSizes () {
            return this.isMobile ? [320, 50] : this.BANNER_SIZES.desk_top
        },

        winners () {
            return (this.comparision && this.comparision.comparision_winners) || {}
        }
    },

    methods: {
        ...mapActions(['compare']),

        assertSectionsVisible (entries) {
            entries.filter(entry => entry.isIntersecting)
                .map(this.trackSectionView)
        },

        goToComparision (slugs) {
            slugs = slugs.join('-X-')
            slugs = slugs.length ? slugs : null

            this.$router.push({ 
                name: 'credit-card.compare', 
                params: { slugs },
                query: this.$route.query
            })
            .catch(() => {}) // Workaround to prevent fake error from router in vuepress
        },

        handleScroll () {
            if (window.pageYOffset > 500 && window.pageYOffset < 7100) {
                this.scrolled = true
            } else {
                this.scrolled = false
            }
        },

        trackSectionView (entry) {
            if (this.tracked.indexOf(entry.target) === -1) {
                tracking.trackVirtualPage(slugify(entry.target.querySelector('h3').innerText))
                this.tracked.push(entry.target)
            }
        }
    }
}
</script>

<style lang="scss">
.global-layout.credit-card-comparator-page .header {
    background-color: $color-white;
}

    #page-card-comparator {
        .card-comparator__content {
            margin-left: auto;
            margin-right: auto;
            margin-top: 1rem;
            width: 333px;
        }
        .ads {
            width: 300px;
            height: 250px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 70px;
            margin-bottom: 70px;
            background-color: #4A4B4C;
        }
        .ads--top {
            width: 320px;
            height: 50px;
            margin-top: 33px;
            margin-bottom: 28px;
        }
        .card-comparator__headline {
            color: #4A4B4C;
            font-weight: 600;
            font-size: 30px;
            line-height: 130%;
            padding-bottom: 12px;
        }
        .card-comparator__subtitle {
            font-size: 16px;
            line-height: 150%;
            padding-bottom: 26px;
        }
        .card-comparator__comparison-text {
            display: none;
        }
        .card-comparator__arrow-start{
            position: absolute;
            bottom: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            padding-left: 30%;
            color: #7F7F85;
            min-width: 144px;
            min-height: 29px;
            background: url("~@/images/card-comparator/arrow-start.png") no-repeat;
            background-position: 7%;

            .is-ios & {
                letter-spacing: -1px;
            }
        }
        .card-comparator__section {
            margin-bottom: 4.25rem;
        }
        .card-comparator__section-cards {
            width: 100%;
        }
        .card-comparator__first-col {
            position: relative;
            max-width: 158px;
            min-width: 158px;
            ul {
                max-width: 143px;
            }
        }
        .card-comparator__first-col-result {
            max-width: 158px;
            min-width: 158px;
            text-align: left;
            ul {
                max-width: 432px;
            }
            li {
                display: block;
            }
        }
        .card-comparator__first-col-result:last-child {
            .card-comparator__tooltip-center {
                left: -80px;

                &::after {
                    left: 89%;
                }
            }
        }
        .card-comparator__card-image-wrapper {
            align-items: center;
            display: flex;
        }

        .card-comparator__card-image-wrapper--taller {
            height: 158px;
        }

        .card-comparator__card-image {
            margin: 0 auto;
            max-height: 158px;
        }

        .card-comparator__item-description-footer {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 130%;

            &:last-child {
                @apply mb-0;
            }

            h4 {
                font-weight: 600;
            }
        }
        .card-comparator__select-input {
            font-size: 14px;
            line-height: 170%;
            color: #7F7F85;
            border: 1px solid #CACACC;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 10px;
        }
        .card-comparator__versus {
            display: none;
        }
        .card-comparator__second-col {
            position: relative;
            max-width: 158px;
        }
        .card-comparator__second-col--disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        .card-comparator__second-col-result {
            position: relative;
            max-width: 158px;
            li {
                display: block;
            }
        }
        .card-comparator__card-cta {
            @apply flex;
            @apply flex-1;
            @apply flex-col;
            @apply justify-between;
            max-width: 158px;
            // margin: 0 auto;
            @apply mt-4;
        }
        .card-comparator__third-col {
            display: none;
            position: relative;
        }
        .card-comparator__item-value {
            margin-bottom: 36px;
            font-weight: 600;
            font-size: 19px;
            line-height: 130%;
        }
        .card-comparator__item-value--best {
            padding-left: 28px;
            background: url('~@/images/card-comparator/medal.svg') no-repeat;
            background-position: 0% 40%;
            color: #00A26B;
        }
        .card-comparator__section-title {
            font-weight: 800;
            font-size: 27px;
            line-height: 130%;
            text-align: center;
            span {
                display: inline-flex;
                color: #00E169;
            }
        }
        .card-comparator__section-division {
            margin-top: 15px;
            margin-bottom: 30px;
            border: 1px solid #EBE9E8;
        }
        .card-comparator__section-wrapper {
            @apply flex;
            @apply justify-between;
            width: 100%;
            gap: 18px;
            color: #000000;
            font-size: 14px;
            line-height: 170%;
        }
        .card-comparator__line-item {
            padding-top: 40px;

            &:first-child {
                @apply pt-0;
            }
        }
        .card-comparator__span-line{
            display: block;
            width: 25px;
            border: 2px solid #00E169;
            background: #00E169;
        }
        .card-comparator__item-box {
            position: relative;
            width: 158px;
            height: 166.48px;
            margin-top: 70px;
        }
        .card-comparator__item-box__title {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 180%;
            color: #7F7F85;
            padding-top: 11px;
        }
        .card-comparator__item-box__image {
            display: flex;
            align-items: center;
            width: 158px;
            height: 166px;
            border: 1px solid #CACACC;
            padding: 35px 16px;
        }
        .card-comparator__item-box-no-border {
            cursor: pointer;
            position: relative;
            width: 158px;
            height: 166.48px;
            margin-top: 70px;
            text-align: center;
            padding-top: 50%;
            p {
                text-align: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 180%;
                color: #7F7F85;
            }
        }

        .card-comparator__item-box-no-border--best {
            background: url('~@/images/card-comparator/medal.svg') no-repeat;
            background-position: 50% 40%;
            color: #00A26B;
        }
        .card-comparator__item-description-extensive-small {
            font-weight: 600;
            font-size: 20px;
            line-height: 130%;
            p {
                font-size: 16px;
            }
        }
        .card-comparator__item-description-extensive-large {
            @apply relative;
            display: inline;
            font-weight: 600;
            font-size: 24px;
            line-height: 130%;
            padding-left: 30px;
            padding-right: 25px;
            background: url('~@/images/card-comparator/btn-comment.svg') no-repeat;
            background-position: 100% 58%;
        }

        .card-comparator__item-description-extensive-large--no-tooltip {
            background: none;
        }

        .card-comparator__tooltip-center {
            position: absolute;
            top: -52px;
            left: 16px;
            background: #000000;
            border-radius: 4px;
            width: 246px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
            p {
                color: white;
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                padding: 14px;
            }
        }

        .card-comparator__tooltip-center:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-top-color: #000000;
            border-bottom: 0;
            margin-left: -7px;
            margin-bottom: -7px;
        }
        .card-comparator__tooltip-right {
            position: absolute;
            top: -10px;
            left: -80px;
            background: #000000;
            border-radius: 4px;
            width: 246px;
            height: 82px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
            p {
                text-align: left;
                color: white;
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                padding: 14px;
            }
        }
        .card-comparator__tooltip-right:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 91%;
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-top-color: #000000;
            border-bottom: 0;
            margin-left: -7px;
            margin-bottom: -7px;
        }
        .card-comparator__item-box-no-border--best-inline {
            background: url('~@/images/card-comparator/medal.svg') no-repeat;
            background-position: 5% 58%;
            color: #00A26B;
        }
        .card-comparator__item-category-extensive {
            font-weight: 600;
            font-size: 16px;
            line-height: 140% !important;
            color: #7F7F85;
            strong {
                color: #000000;
            }
        }
        .card-comparator__item-category {
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #7F7F85;
            strong {
                color: #000000;
            }
        }
        .card-comparator__list-item {
            display: inline-block;
            position: relative;
            padding-left: 20px;
            padding-bottom: 20px;
            font-size: 14px;
            line-height: 170%;
        }
        .card-comparator__list-item::before {
            content: "•";
            display: block;
            position: absolute;
            left: 0px;
            list-style-type: disc;
        }
        .card-comparator__list-item--green::before {
            color: #00E169;
        }
        .card-comparator__list-item--red::before {
            color: #DB2B28;
        }
        .card-comparator__section-points {
            @apply mb-0;
            padding-bottom: 90px;
        }
        .card-comparator__list-item--check {
            font-weight: 600;
        }
        .card-comparator__list-item--check::before {
            color: #00E169;
            content: '';
            position: absolute;
            left: 0;
            width: 1em;
            height: 1.3em;
            background: url("~@/images/card-comparator/check.png") no-repeat;
            background-position: bottom;
        }
        .card-comparator__list-item--uncheck {
            color: #A6A6AB;
            font-weight: 600;
        }
        .card-comparator__list-item--uncheck::before {
            color: #00E169;
            content: '';  /* placeholder for the SVG */
            position: absolute;
            left: 0;  /* place the SVG at the start of the padding */
            width: 1em;
            height: 1.3em;
            background: url("~@/images/card-comparator/uncheck.png") no-repeat;
            background-position: bottom;
        }
        .card-comparator__section-footer {
            background-color: #F7F6F5;
            padding-bottom: 92px;
        }
        .card-comparator__section-footer-content {
            max-width: 337px;
            margin-left: auto;
            margin-right: auto;
        }
        .card-comparator__section-title-footer {
            max-width: 303px;
            padding-left: 34px;
            padding-bottom: 38px;
            font-weight: 800;
            font-size: 30px;
            line-height: 130%;
            padding-top: 80px;
            background: url("~@/images/card-comparator/circle-green.svg") no-repeat;
            background-position: 0% 42%;
        }
        .card-comparator__section-inline-row {
            display: flex;
            width: 336px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 40px;
            align-items: center;
            span {
                padding-right: 14px;
                padding-left: 14px;
                font-weight: 600;
                font-size: 30px;
                line-height: 130%;
                text-align: center;
                align-self: center;
            }
        }
        .card-comparator__footer-image {
            max-width: 143px;
            max-height: 162px;
            margin: auto;
        }
        .card-comparator__footer-third-col {
            display: none;
        }
        .card-comparator__section-label-footer {
            margin-bottom: 15px;
            font-weight: 600;
            font-size: 24px;
            line-height: 130%;
        }
        .card-comparator__section-division-footer {
            margin-top: 70px;
            margin-bottom: 70px;
            border: 1px solid #EBE9E8;
        }
    }

    @media screen and (min-width: $screen-tablet-min-width) {
        #page-card-comparator {
            .ads {
                width: 730px;
                height: 188px;
            }
            .card-comparator__content {
                margin-left: auto;
                margin-right: auto;
                width: 963px;
            }
            .card-comparator__headline {
                max-width: 730px;
                margin: auto;
                font-size: 36px;
                text-align: center;
            }
            .card-comparator__subtitle {
                max-width: 540px;
                margin: auto;
                text-align: center;
            }
            .card-comparator__comparison-text {
                display: block;
                font-weight: 600;
                font-size: 20px;
                line-height: 130%;
                text-align: center;
                padding-bottom: 30px;
            }
            .card-comparator__first-col {
                max-width: 400px;
                min-width: 400px;
                margin-left: auto;
                ul {
                    max-width: 432px;
                }
            }
            .card-comparator__first-col--reduced {
                max-width: 274px;
                min-width: 274px;
                img {
                    padding: 27px 10px;
                }
            }
            .card-comparator__first-col--reduced-image {
                max-width: 190px;
                min-width: 190px;
                align-self: center;
            }
            .card-comparator__first-col-result {
                max-width: 432px;
                min-width: 432px;
                text-align: left;
                ul {
                    max-width: 432px;
                }
            }
            .card-comparator__first-col-result:last-child {
                .card-comparator__tooltip-center {
                    left: 54px;
                    top: -50px;

                    &::after {
                        left: 50.5%;
                    }
                }
            }
            .card-comparator__card-image-wrapper {
                height: 200px;
            }

            .card-comparator__card-image-wrapper--taller {
                height: 320px;
            }

            .card-comparator__card-image {
                height: 200px;
                max-height: none;
                margin-left: auto;
                margin-right: auto;

                &[data-is-vertical] {
                    height: auto;
                    max-height: 320px;
                    max-width: 320px;
                }
            }

            .card-comparator__card-image--reduced {
                padding: 27px 10px;
            }
            .card-comparator__section-title {
                padding: 10px;
                text-align: left;
            }
            .card-comparator__section-division {
                margin-bottom: 40px;
            }
            .card-comparator__section-wrapper {
                gap: 0;
            }
            .card-comparator__select-input {
                width: 100%;
            }
            .card-comparator__versus {
                display: block;
                margin-left: 32px;
                margin-right: 32px;
                font-weight: 600;
                font-size: 16px;
                line-height: 180%;
            }
            .card-comparator__versus--green:not(:last-child)::after {
                content: 'X';
                color: #00E169;
                margin: 0 1rem;
            }
            .card-comparator__arrow-start {
                color: #7F7F85;
                min-width: 100%;
                min-height: 29px;
                padding-left: 40%;
                margin-bottom: 6px;
                font-weight: 600;
                font-size: 12px;
                line-height: 150%;
                background: url("~@/images/card-comparator/arrow-start.png") no-repeat;
                background-position: 30% 100%;
            }
            .card-comparator__second-col {
                max-width: 400px;
                min-width: 400px;
                margin-right: auto;
                ul {
                    max-width: 432px;
                }
            }
            .card-comparator__second-col--reduced {
                max-width: 274px;
                min-width: 274px;
                img {
                    padding: 27px 10px;
                }
            }
            .card-comparator__second-col-result {
                max-width: 432px;
                min-width: 432px;
                text-align: left;
                margin-left: auto;
                ul {
                    max-width: 432px;
                }
            }
            .card-comparator__card-cta {
                max-width: unset;
                margin: 0 auto;
                margin-top: 25px;
                width: 320px;
            }
            .card-comparator__card-cta--reduced {
                position: relative;
                max-width: 205px;
                margin-left: auto;
                margin-right: auto;
            }
            .card-comparator__third-col {
                display: block;
                max-width: 272px;
                min-width: 273px;
            }
            .card-comparator__third-col--none {
                opacity: 0;
            }
            .card-comparator__third-col--active {
                margin-left: auto;
            }
            .card-comparator__item-value {
                font-size: 20px;
            }
            .card-comparator__item-box {
                width: 158px;
                height: 206px;
                margin: auto;
                margin-top: 50px;
            }
            .card-comparator__item-box-no-border {
                width: 219px;
                height: 68px;
                margin: auto;
                margin-top: 62px;
                margin-bottom: 90px;
                padding-top: 7%;

                &:last-child {
                    @apply pt-0;
                }
            }
            .card-comparator__item-description-extensive-large {
                font-weight: 600;
                font-size: 36px;
                line-height: 130%;
                padding-left: 18px;
                padding-right: 30px;
                background-position: 100% 40%;
            }

            .card-comparator__item-description-extensive-large--no-tooltip {
                @apply p-0;
            }

            .card-comparator__section-points {
                padding-bottom: 104px;
            }

            .card-comparator__item-box-no-border--best {
                background-position: 50% 0%;
            }
            .card-comparator__item-box-no-border--best-inline {
                background-position: 0% 100%;
            }
            .card-comparator__item-description-extensive-small {
                font-size: 30px;
            }
            .card-comparator__item-category-extensive {
                margin: auto;
                max-width: 174px;
            }
            .card-comparator__tooltip-right {
                top: -60px;
                left: -32px;
            }
            .card-comparator__tooltip-center {
                top: -60px;
                left: 70px;
            }
            .card-comparator__section-footer-content {
                max-width: 730px;
            }
            .card-comparator__section-title-footer {
                max-width: 686px;
                background-position: 0% 65%;
            }
            .card-comparator__section-inline-row {
                max-width: 462px;
                min-width: 462px;
                span {
                    padding-right: 25px;
                    padding-left: 25px;
                }
            }
            .card-comparator__section-inline-row--large {
                max-width: 663px;
                min-width: 663px;
            }
            .card-comparator__inline-row--reduced-image {
                max-width: 190px;
                min-width: 190px;
                align-self: center;
            }
            .card-comparator__footer-image {
                max-width: 190px;
                min-height: 120px;
                max-height: 220px;
            }
            .card-comparator__footer-third-col {
                display: block;
            }
        }
    }
</style>
