import Repository from '@/services/repository'
import axios from 'axios'

class Leads extends Repository {
    conversion (id) {
        const data = {
            _method: 'patch',
            meta: { converted: true }
        }

        return axios.post(`${this.baseUrl}/recommendations/leads/${id}`, data)
            .then(response => response.data)
    }

    create (data) {
        return axios.post(`${this.baseUrl}/recommendations/leads`, data)
            .then(response => response.data)
    }
}

export default new Leads()
