<template>
<div class="article__newsletter">
    <div class="article__newsletter__wrapper">
        <div class="article__newsletter__container">
            <h4>Receba novidades diretamente em seu e-mail:</h4>
        </div>
        <div class="article__newsletter__form_container">
            <NewsletterForm />
        </div>
    </div>
</div>
</template>

<script>
import NewsletterForm from '@/components/NewsletterForm'

export default {
    components: { NewsletterForm }
}
</script>

<style lang="scss">
.article__newsletter {
    @apply bg-gray-300;
    @apply block;
    background-color: $v2-background2-color;

    .article__newsletter__wrapper {
        @apply py-20;
        @apply py-20;
        @apply px-6;

        .article__newsletter__container {
            @apply mx-auto;
            @apply max-w-sm;

            h4 {
                @apply font-semibold;
                @apply text-3xl;
                font-family: $v2-font-family;
                line-height: 130%;
            }

            p {
                @apply mt-10;
                @apply text-black;
                @apply text-xl;
                font-family: $v2-font-family;
                line-height: 180%;
            }
        }

        .article__newsletter__form_container {
            @apply mt-12;

            .article__newsletter__form {
                @apply w-full;
                @apply mx-auto;
                @apply max-w-sm;
                @apply text-base;

                .article__newsletter__form_input {
                    border-bottom: 1px solid #000000;
                    @apply my-6;
                    @apply mt-0;

                    label {
                        @apply block;
                        @apply text-gray-800;
                    }
                    input {
                        @apply w-full;
                        @apply bg-transparent;
                        @apply outline-none;
                        @apply text-xl;
                    }
                }

                .article__newsletter__form_checkbox {
                    @apply text-base;
                    @apply select-none;
                    @apply cursor-pointer;
                    line-height: 150%;
                }

                .article__newsletter__form_button {
                    @apply max-w-sm;
                    @apply mx-auto;
                    @apply mt-6;

                    button.article__newsletter__form_solid {
                        min-width: 124px;
                        height: 41.97px;
                        border-radius: 4px;
                        @apply block flex text-white bg-black px-4 font-semibold text-sm flex-row justify-around items-center uppercase;
                    }
                }
            }
        }
    }
}

span.lb__error{
    @apply text-xs text-red-900 w-full block;
}

span.lb__success{
    @apply text-xs text-green-900 w-full block;
}

@media screen and (min-width: $screen-tablet-min-width) {
    .article__newsletter {
        @apply bg-gray-300;
        background-color: $v2-background2-color;
        .article__newsletter__wrapper {
            @apply flex flex-row max-w-screen-xl mx-auto py-0 my-0 items-center justify-center h-screen;
            max-height: 404px;

            .article__newsletter__container{
                max-height: 205px;
                max-width: 266px;
                @apply w-4/12 text-left mx-0 flex flex-row justify-end h-screen;

                h4 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 130%;
                }
            }

            .article__newsletter__form_container{
                @apply mt-0 ml-20;
            }
        }
    }
}
</style>
