/**
 * DIGITAL ACCOUNTS STORE
 */

import axios from "axios"
import Vue from "vue";
import leads from "@/services/bank-accounts/digital-accounts/leads";
import digitalAccounts from "@/services/bank-accounts/digital-accounts";

export default {
    namespaced: true,
    state: () => ({
        askedQuestions: [],
        digitalAccount: {},
        lead: {},
        contact: {
            email: "",
            first_name: "",
            has_accepted_terms: false,
        },
        card: {},
        current: {},
        currentIndex: null,
        response: {},
        lastQuizResponse: [],
        questionsHistory: [],
        filterListCards: {},
        gateBackgroundColor: "background-color: #EBE9E8",
        navigationTransition: "",
        questions: [
            {
                type: "single",
                style: "small",
                layout: "vertical",
                key: "monthly_withdrawals",
                slug: "saques",
                quizInfo:
                    "Responda às perguntas a seguir para que nossa inteligência artificial possa encontrar o <b>Conta Bancária</b> perfeita para você.",
                title: "Quantos <b>saques</b> de dinheiro você costuma fazer por mês?",
                highlightedText: "por mês.",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "cartao-de-credito",
                        prevQuestion: null,
                        text: "Nenhum",
                        value: 0
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "cartao-de-credito",
                        prevQuestion: null,
                        text: "1 a 3",
                        value: 3
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "cartao-de-credito",
                        prevQuestion: null,
                        text: "4 a 6",
                        value: 6
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "cartao-de-credito",
                        prevQuestion: null,
                        text: "7 a 10",
                        value: 10
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "cartao-de-credito",
                        prevQuestion: null,
                        text: "Mais que 10",
                        value: 15
                    },
                ],
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "wants_credit_card",
                slug: "cartao-de-credito",
                title:
                    "Você precisa que o banco lhe forneça um <b>cartão de crédito?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: 1,
                        nextQuestion: "transferencias",
                        prevQuestion: "saques",
                    },
                    {
                        image: "icon-tanto-faz.svg",
                        image_checked: "icon-tanto-faz-ativa.svg",
                        text: "Seria legal. Mas não é fundamental.",
                        value: 2,
                        nextQuestion: "transferencias",
                        prevQuestion: "saques",
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não",
                        value: 0,
                        nextQuestion: "transferencias",
                        prevQuestion: "saques",
                    },
                ],
            },
            {
                type: "single",
                style: "small",
                layout: "vertical",
                key: "monthly_transfers",
                slug: "transferencias",
                title:
                    "Quantas <b>transferências</b> para outras contas você costuma fazer por mês?</b>",
                highlightedText: "por mês.",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "agencias",
                        prevQuestion: "cartao-de-credito",
                        text: "Nenhum",
                        value: 0
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "agencias",
                        prevQuestion: "cartao-de-credito",
                        text: "1 a 3",
                        value: 3
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "agencias",
                        prevQuestion: "cartao-de-credito",
                        text: "4 a 6",
                        value: 6
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "agencias",
                        prevQuestion: "cartao-de-credito",
                        text: "7 a 10",
                        value: 10
                    },
                    {
                        image: "",
                        image_checked: "",
                        nextQuestion: "agencias",
                        prevQuestion: "cartao-de-credito",
                        text: "Mais que 10",
                        value: 15
                    },
                ],
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "is_important_physical_agency",
                slug: "agencias",
                title:
                    "É importante que o seu banco tenha agências onde você possa ir <b>presencialmente?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: 1,
                        nextQuestion: "cep",
                        prevQuestion: "transferencias",
                    },
                    {
                        image: "icon-tanto-faz.svg",
                        image_checked: "icon-tanto-faz-ativa.svg",
                        text: "Seria legal. Mas não é fundamental.",
                        value: 2,
                        nextQuestion: "cep",
                        prevQuestion: "transferencias",
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não",
                        value: 0,
                        nextQuestion: "valor-mensal",
                        prevQuestion: "transferencias",
                    },
                ],
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "only_free_accounts",
                slug: "valor-mensal",
                title:
                    "Se necessário para atender a todas as suas necessidades, você tem interesse em um banco que cobra um valor <b>fixo mensal pela conta?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: 0,
                        nextQuestion: null,
                        prevQuestion: "agencias|cep",
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não. Só tenho interesse em contas gratuitas.",
                        value: 1,
                        nextQuestion: null,
                        prevQuestion: "agencias|cep",
                    },
                ],
            },
            {
                type: "form",
                style: "default",
                layout: "vertical",
                key: "zip_code",
                slug: "cep",
                title: "Qual é o seu <b>CEP?</b>",
                by_pass_option: "Pular esta pergunta",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        text: "",
                        value: "",
                        nextQuestion: "valor-mensal",
                    },
                ],
            },
        ],
    }),
    mutations: {
        addAskedQuestion(state, payload) {
            if (!state.askedQuestions.includes(payload.key)) {
                state.askedQuestions.push(payload.key);
            }
        },

        addToQuestionsHistory(state, payload) {
            if (!state.questionsHistory.includes(payload.slug)) {
                state.questionsHistory.push(payload.slug);
            }
        },

        clearAskedQuestions(state) {
            state.askedQuestions = [];
        },

        clearQuestionsHistory(state) {
            state.questionsHistory = [];
        },

        setCurrent(state, {slug}) {
            state.current = state.questions.find((one) => one.slug === slug);
            state.currentIndex = state.questions.findIndex(
                (one) => one.slug === slug
            );
        },

        setNavigationTransition(state, isPrevious) {
            state.navigationTransition = isPrevious
            ? "slide-fade-previous"
            : "slide-fade-next"
        },

        setLead(state, payload) {
            state.lead = payload.lead;
        },

        setContact(state, payload) {
            state.contact = payload.contact;
        },

        setCurrentQuestion(state, payload) {
            state.current = payload.current;
        },

        setCurrentQuestionIndex(state, payload) {
            state.currentIndex = payload.index;
        },

        addResponseKey(state, payload) {
            const [key, value] = payload;

            Vue.set(state.response, key, value);
        },

        setLastQuizResponse(state, payload) {
            state.lastQuizResponse = payload;
        },

        clearResponseKey(state) {
            state.response = {};
        },

        setCard(state, payload) {
            state.card = payload.digitalAccount;
        },

        setNumberOfQuestions(state, quantity) {
            state.numberOfQuestions = quantity;
        },
    },
    getters: {
        nextQuestion(state, getters) {
            return state.questions[getters.nextQuestionIndex] || null;
        },

        nextQuestionIndex(state) {
            return state.currentIndex === null ? 0 : state.currentIndex + 1;
        },

        currentHasResponse(state) {
            const value = state.response[state.current.key];
            const type = typeof value;

            return (
                (type === "object" && value.length) ||
                (type !== "undefined" && type !== "object")
            );
        },

        lastQuizResponse(state) {
            if (Object.keys(state.lastQuizResponse).length > 0) {
                return state.lastQuizResponse;
            }
            try {
                let historic = sessionStorage.getItem("lastQuizResponse")
                    ? JSON.parse(sessionStorage.getItem("lastQuizResponse"))
                    : {};
                if (historic.expires_at) {
                    const expires = historic.expires_at;
                    const lastDate = new Date(
                        expires.year,
                        expires.month - 1,
                        expires.day,
                        expires.hours,
                        expires.minutes
                    );
                    if (lastDate > new Date()) {
                        historic.expires_at = undefined;
                        return historic;
                    }
                }

                sessionStorage.setItem("lastQuizResponse", undefined);
                return {};
            } catch (e) {
                sessionStorage.setItem("lastQuizResponse", undefined);
                return {};
            }
        },
    },
    actions: {
        clearAskedQuestions({ commit }) {
            commit("clearAskedQuestions");
        },
        
        clearQuestionsHistory({ commit }) {
            commit("clearQuestionsHistory");
        },
        
        clearResponseKey({commit}) {
            commit("clearResponseKey");
        },

        async fetchRecommendation({state}) {
            const data = {
                brand: "any",
                quiz_type: "bank-account",
                ...state.response
            };

            let retries = 0;
            let recommendation = [];

            do {
                try {
                    const response = await axios
                        .post(`https://accounts-rank.plusdin.com/v1/get_personal_rank`, data, {
                            auth: {
                                username: process.env.VUE_APP_DATA_API_USERNAME,
                                password: process.env.VUE_APP_DATA_API_PASSWORD
                            }
                        });
                    recommendation = await response.data;
                } catch (error) {
                    retries++;
                }
            } while (retries > 0 && retries < 3)

            if (recommendation.length === 0) {
                throw 'Recommender is not responding';
            }

            const recommenderVersion = recommendation[recommendation.length - 1]['recommender-version'];

            return {
                slug: recommendation[0].slug,
                version: recommenderVersion,
                recommendationComplete: recommendation
            };
        },

        saveLead({commit, state}, payload) {
            commit("setContact", payload);

            const data = {
                brand: "any",
                quiz_type: "bank-account",
                ...state.contact,
                ...state.response,
                meta: payload.meta,
            };

            return leads.create(data).then((lead) => {
                commit("setLead", {lead});
                return lead;
            });
        },

        loadDigitalAccount({commit}, {id, slug}) {
            const request = id
                ? digitalAccounts.find(id)
                : digitalAccounts.bySlug(slug);

            return request.then((digitalAccount) => {
                commit("setCard", {digitalAccount});

                return digitalAccount;
            });
        },

        setLastQuizResponse({commit}, payload) {
            if (!payload.expires_at) {
                const date = new Date(new Date().getTime() + 1800000);
                payload.expires_at = {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                    hours: date.getHours(),
                    minutes: date.getMinutes(),
                };
            }
            sessionStorage.setItem("lastQuizResponse", JSON.stringify(payload));
            commit("setLastQuizResponse", payload);
        },

        setCurrentQuestion({commit, state}, {slug}) {
            if (slug === "inicio") slug = state.questions[0].slug;

            const current = state.questions.find((one) => one.slug === slug);
            const index = state.questions.findIndex((one) => one.slug === slug);
            commit("setCurrentQuestion", {current});
            commit("setCurrentQuestionIndex", {index});
            commit('addAskedQuestion', {key: current.key})
            commit('addToQuestionsHistory', {slug})
        },

        setResponseKey({commit}, payload) {
            commit("addResponseKey", payload);
        },
    },
};
