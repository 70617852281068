<template>
<div
    id="footer_recommendation"
    :class="{
        'footer_recommendation--is-home' : isHome,
        'fixed bottom-0 left-0 w-full':
            isFixedForLargeDisplay && $windowSize().height >= 800
    }"
>
    <div class="footer_container">
        <div v-if="isFooterFull" class="footer_container__menu">
            <ul>
                <li>
                    <a
                        :href="checkUrl('/politica-de-privacidade')"                        
                        target="_blank"
                        >• Política de Privacidade</a>
                </li>
                <!-- <li>
                    <router-link :to="{ name: 'disclaimer'}">• Disclaimer</router-link>
                </li> -->
                <li>
                    <a
                        :href="checkUrl('/termos-de-uso')"
                        target="_blank"
                        >• Termos de Serviço</a>
                </li>
            </ul>
        </div>
        <div
            :class="{
                'footer_container__copyright--is_full' : !isFooterFull,
                'footer_container__copyright--normal' : isFooterFull
            }"
        >© {{currentlyYear}} - Plusdin - Todos os direitos reservados</div>
    </div>
</div>
</template>

<script>
import urlParams from "@/services/urlParams";

export default {
    props: {
        isFixedForLargeDisplay: {
            type: Boolean,
            default: false
        },
        isFooterFull: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        goToUrl (route) {
            this.$router.push(route).catch(() => {})
        },

        checkUrl(url) {
            return urlParams(url, this.$route.query)
        }
    },

    computed: {
        isCampaign () {
            return this.$route.query.campaign === 'true'
        },

        isHome () {
            return this.$router.currentRoute.name === 'credit-card.recommendation'
        },

        currentlyYear() {
            const date = new Date()
            return date.getFullYear()
        }
    }
}
</script>

<style lang="scss">
#footer_recommendation {
    background-color: $bg-gray-700;
    &.footer_recommendation--is-home {
        background-color: #EBE9E8;
        .footer_container {
            .footer_container__menu {
                ul {
                    li {
                        a {
                            color: $bg-gray-700;
                            @apply cursor-pointer select-none;
                        }
                    }
                }
            }
            .footer_container__copyright--is_full,
            .footer_container__copyright--normal {
                color: $bg-gray-700;
            }
        }
    }
    @apply flex flex-col;
    .footer_container {
        @apply mx-auto p-6 max-w-md;
        .footer_container__menu {
            @apply block;
            ul {
                li {
                    @apply inline-block mr-6;
                    &:last-child {
                        @apply mr-0;
                    }
                    a {
                        font-family: $v2-font-family;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 170%;
                        color: #ffffff;
                        @apply cursor-pointer select-none;
                    }
                }
            }
        }
        .footer_container__copyright--is_full {
            @apply pt-6 mt-3 w-full;
            font-family: $v2-font-family;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: #ffffff;
            border-top: 1px solid #ccc;
            @apply w-full border-0 p-0 m-0;
        }
        .footer_container__copyright--normal {
            @apply pt-6 mt-3;
            font-family: $v2-font-family;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: #ffffff;
            border-top: 1px solid #ccc;
        }
    }
}
@media screen and (min-width: $screen-tablet-min-width) {
    #footer_recommendation {
        .footer_container {
            @apply max-w-screen-xl mx-auto w-full flex flex-row ;
            .footer_container__menu {
                @apply w-8/12;
                ul {
                    li {
                        @apply mx-6;
                    }
                }
            }
            .footer_container__copyright--is_full {
                @apply w-full border-0 p-0 m-0;
            }
            .footer_container__copyright--normal {
                @apply w-4/12 border-0 p-0 m-0;
            }
        }
    }
}
</style>
