import partnersStores from '@/services/partners-stores'

const mutations = {
    SET_PARTNERS_STORES: 'setPartnersStores'
}

export default {
    namespaced: true,

    state: () => ({
        partnersStores: []
    }),

    actions: {
        load ({ commit }) {
            return partnersStores.forRecommendation()
                .then(async (stores) => {                 
                    const storesData = await Promise.all(stores.map(async (store) => {
                        const image = await partnersStores.loadImage(store.logo_file.name)
                        
                        store.logo_file.original_url = `${process.env.VUE_APP_API_ASSETS_URL}/partners-stores/${image}`

                        return store
                    })) 

                    commit(mutations.SET_PARTNERS_STORES, { storesData })

                    return storesData
                })
        }
    },

    mutations: {
        [mutations.SET_PARTNERS_STORES] (state, payload) {
            state.partnersStores = payload.stores
        }
    }
}
