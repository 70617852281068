import Vue from 'vue'
import App from './App.vue'
import { Integrations } from '@sentry/tracing'
import router from './router'
import * as Sentry from '@sentry/vue'
import store from './store'
import crossUtils from './utils/cross'
import './assets/tailwind.css'

Vue.config.productionTip = false

initSentry()

Vue.prototype.$isMobile = crossUtils.isMobile
Vue.prototype.$isCampaign = crossUtils.isCampaign
Vue.prototype.$windowSize = crossUtils.windowSize
Vue.prototype.$clearKeysOnStorage = crossUtils.clearKeysOnStorage

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

function initSentry () {
    /**
     * SENTRY CONFIG (vitorino@etus.digital)
     */
    Sentry.init({
        environment: process.env.NODE_ENV,
        Vue,
        dsn: 'https://1aae6e0525b24057951112fd1355346c@o516495.ingest.sentry.io/5623657',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0
    })
    /**
     * END SENTRY CONFIG
     */
}
