<template>
<div id="footer__page">
    <div 
        class="footer__page__top_wrapper"
        v-if="!noLinks"
    >
        <div class="footer__page__top">
            <img src="~@/images/logo-all-green.svg" alt="Plusdin" />
        </div>
        <div class="footer-menu-columns">
            <nav class="footer-menu-column">
                <ul>
                    <li
                        :key="menu.id"
                        v-for="menu of firstNavColumn"
                    >
                        <a
                            :class="{ 'footer-menu-column-title': hasChildren(menu) }"
                            :href="checkUrl(menu.url)"
                            v-if="menu.url"
                        >{{ menu.name }}</a>
                        <span
                            :class="{ 'footer-menu-column-title': hasChildren(menu) }"
                            v-else
                        >{{ menu.name }}</span>
                        <ul v-if="hasChildren(menu)">
                            <li
                                :key="subItem.id"
                                v-for="subItem of menu.subItems"
                            >
                                <a :href="checkUrl(subItem.url)">{{ subItem.name }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <nav class="footer-menu-column">
                <ul>
                    <li
                        :key="menu.id"
                        v-for="menu of secondNavColumn"
                    >
                        <a
                            :class="{ 'footer-menu-column-title': hasChildren(menu) }"
                            :href="checkUrl(menu.url)"
                            v-if="menu.url"
                        >{{ menu.name }}</a>
                        <span
                            :class="{ 'footer-menu-column-title': hasChildren(menu) }"
                            v-else
                        >{{ menu.name }}</span>
                        <ul v-if="hasChildren(menu)">
                            <li
                                :key="subItem.id"
                                v-for="subItem of menu.subItems"
                            >
                                <a :href="checkUrl(subItem.url)">{{ subItem.name }}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a :href="checkUrl('/sobre-o-plusdin')">Sobre o Plusdin</a>
                    </li>
                </ul>
            </nav>
            <nav class="footer-menu-column">
                <ul>
                    <li>
                        <a
                            target="_blank"
                            :href="checkUrl('/politica-de-privacidade')"
                        >Política de Privacidade</a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            :href="checkUrl('/disclaimer')"
                        >Disclaimer</a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            :href="checkUrl('/termos-de-uso')"
                        >Termos de Serviço</a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            :href="checkUrl('/trabalhe-conosco')"
                        >Trabalhe Conosco</a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            :href="checkUrl('/fale-conosco')"
                        >Fale Conosco</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div 
        class="footer__page__bottom"
        :class="noLinks ? 'pb-3' : 'pt-6'"
    >
        <div 
            class="footer__page__bottom_divider"
            v-if="!noLinks"
        ></div>
        <div class="footer__page__bottom_wrapper">
            <label>© 2021 - Plusdin - Todos os direitos reservados</label>
            <div 
                class="footer__page__sociais"
                v-if="!noLinks"
            >
                <a
                    href="https://www.instagram.com/plusdin/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="~@/icons/icon-instagram.svg"
                        alt="Instagram"
                    />
                </a>
                <a
                    href="https://www.facebook.com/Plusdin-101107134902695"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="~@/icons/icon-facebook.svg"
                        alt="facebook"
                    />
                </a>
                <a
                    href="https://linkedin.com/company/plusdin"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="~@/icons/icon-linkedin.svg"
                        alt="linkedin"
                    />
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import urlParams from "@/services/urlParams";

export default {
    props: {
        noLinks: {
            type: Boolean,
            default: false
        }  
    },
    
    computed: {
        menu () {
            return this.$root.$children[0].menu || this.$store.state.menu
        },

        firstNavColumn () {
            return this.menu?.items?.slice(0, 3) || []
        },

        secondNavColumn () {
            return this.menu?.items?.slice(3, 4) || []
        }
    },

    methods: {
        hasChildren (menu) {
            return menu.subItems && !!menu.subItems.length
        },

        checkUrl(url) {
            return urlParams(url, this.$route.query)
        }
    }
}
</script>

<style lang="scss">
.footer-menu-columns {
    @apply mx-6 text-white font-semibold pb-8;
    font-family: $v2-font-family;
    font-size: 14px;
    line-height: 170%;

    ul {
        li {
            @apply my-6;

            ul {
                @apply ml-6;

                li {
                    @apply my-2;
                    font-size: 14px;
                    line-height: 170%;

                    a {
                        color: $text-gray-300;
                    }
                }
            }
        }
    }
}

.footer-menu-column {}

.footer-menu-column-title {
    color: rgba(#fff, 0.6);
}

#footer__page{
    background-color: $bg-gray-700;
    @apply pt-10 pb-8;
    .footer__page__top_wrapper {
        .footer__page__top{
            @apply pt-8 flex flex-col items-start mx-6;
            img {
                @apply mb-6;
            }
            label {
                font-size:12px;
                font-family: $v2-font-family;
                line-height: 150%;
                @apply text-white;
                a {
                    @apply text-center underline lowercase font-semibold;
                    text-decoration-color: $bg-green-500;
                    text-underline-position: under;
                }
            }
        }
    }
    .footer__page__bottom{
        font-family: $v2-font-family;
        @apply mx-6 text-white;
        .footer__page__bottom_divider {
            border-top: 1px solid #666666;
            padding-bottom: 1.5rem;
        }
        .footer__page__bottom_wrapper {
            .footer__page__bottom_submenu {
                @apply block mt-6;
                ul {
                    li {
                        @apply my-3;
                        a {
                            @apply cursor-pointer;
                        }
                    }
                }
            }
            .footer__page__sociais{
                @apply flex flex-row pt-6;
                a {
                    @apply mr-3;
                }
            }
            label {
                font-size: 12px;
            }
        }
    }
}

@media screen and (min-width: $screen-tablet-min-width) {
    .footer-menu-columns {
        @apply w-10/12 px-6 pt-3 pb-0 flex;

        ul {
            li {
                @apply pb-8 my-0;

                a {
                    @apply cursor-pointer;
                }

                ul {
                    @apply flex flex-col;

                    li {
                        @apply my-0 py-1 w-full font-normal cursor-pointer;

                        a {
                            @apply cursor-pointer;
                        }
                    }
                }
            }
        }
    }

    .footer-menu-column {
        @apply flex-1;
    }

    #footer__page {
        padding-top: 3.5rem;

        .footer__page__top_wrapper {
            @apply flex flex-row mx-auto max-w-screen-xl;
            .footer__page__top {
                @apply w-2/12 pt-0 mt-0;
            }
        }
        .footer__page__bottom {
            .footer__page__bottom_wrapper {
                @apply mx-auto max-w-screen-xl justify-center flex flex-row justify-between items-center;
                label {
                    @apply mx-auto;
                }
                .footer__page__bottom_submenu {
                    @apply block mt-0;
                    ul {
                        @apply flex flex-row;
                        li {
                            border-left: 1px solid #ccc;
                            @apply ml-4 pl-4 my-0 py-0 float-left;
                            &:nth-child(1){
                                @apply ml-0 pl-0;
                                border: 0;
                            }
                        }
                    }
                }
                .footer__page__sociais {
                    @apply pt-0;
                }
            }
        }
    }
}
</style>
