<template>
  <div id="menu" :class="{ 'is-show': isShow }">
    <!--<ClientOnly>
            <div v-if="this.$isMobile()" class="mt-4 mr-5">
                <SearchBox />
            </div>
        </ClientOnly>-->
    <div class="menu-mobile-closed" @click="toggleMenu(null)">
      <div class="menu__icon">
        <img alt="Menu Mobile" src="~@/icons/hamb-menu-mobile.svg" />
      </div>
      <!-- <label>Menu</label> -->
      <!-- <img src="~@/menu-mobile-open.svg" alt="Menu" /> -->
    </div>
    <div :class="{ 'main-menu-wrapper': menuIsFirst }" id="main-menu">
      <div class="main-menu-header">
        <label>
          <img
            alt="Plusdin"
            class="credit_card__page_header__logo"
            src="/icons/logo-plusdin.svg"
          />
        </label>
        <img
          alt="Menu"
          @click="toggleMenu(null)"
          src="~@/icons/menu-mobile-close.svg"
        />
      </div>
      <ul>
        <li
          :class="{ 'has-li-submenu': hasChildren(menu) }"
          :key="menu.id"
          v-for="menu of menu.items"
        >
          <a
            href="#"
            :class="{
              'has-submenu': hasChildren(menu),
              'menu-is-active': $route.path === menu.url,
            }"
            @click.prevent="
              toggleMenu(`menu-${menu.id}`, 'menu-children', menu.name)
            "
            v-if="isToggleItem(menu)"
          >
            <span>
              <img :src="menu.filePath" v-if="menu.fileName && isMobile" />
              {{ menu.name }}
            </span>
            <img
              src="~@/icons/menu-chevron-right.svg"
              alt="Menu Right"
              v-if="hasChildren(menu)"
            />
          </a>
          <a
            :class="{
              'has-submenu': hasChildren(menu),
              'menu-is-active': $route.path === menu.url,
            }"
            :href="checkUrl(menu.url)"
            v-else
          >
            <span>
              <img :src="menu.filePath" v-if="menu.fileName && isMobile" />
              {{ menu.name }}
            </span>
            <img
              src="~@/icons/menu-chevron-right.svg"
              alt="Menu Right"
              v-if="hasChildren(menu)"
            />
          </a>
          <div :id="`menu-${menu.id}`" class="menu-children">
            <div class="main-menu-header">
              <label
                ><img
                  src="/icons/logo-plusdin.svg"
                  alt="Plusdin"
                  class="credit_card__page_header__logo"
              /></label>

              <img
                @click="toggleMenu(null)"
                src="~@/icons/menu-mobile-close.svg"
                alt="Menu"
              />
            </div>
            <ul v-if="hasChildren(menu)">
              <!-- <ClientOnly> -->
              <li @click="backMenu(`menu-${menu.id}`)">
                <img
                  src="~@/icons/menu-chevron-left.svg"
                  alt="Menu Left"
                />
              </li>
              <!-- </ClientOnly> -->
              <li
                v-for="subItem of menu.subItems"
                :key="subItem.id"
                :class="{
                  'has-li-submenu': hasChildren(subItem),
                }"
              >
                <a
                  href="#"
                  :class="{
                    'has-submenu': hasChildren(subItem),
                  }"
                  @click.prevent="
                    toggleMenu(
                      `subItem-${subItem.id}`,
                      'menu-children2',
                      menu.name,
                      subItem.name
                    )
                  "
                  v-if="hasChildren(subItem)"
                >
                  <div>
                    <svg
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#00E169" />
                    </svg>
                    <span>
                      <img
                        v-if="subItem.fileName && isMobile"
                        :src="subItem.filePath"
                      />
                      {{ subItem.name }}
                    </span>
                  </div>
                  <img
                    v-if="subItem.subItems && subItem.subItems.length > 0"
                    src="~@/icons/menu-chevron-right.svg"
                    alt="Menu Right"
                  />
                </a>
                <a
                  :class="{
                    'has-submenu': hasChildren(subItem),
                  }"
                  :href="checkUrl(subItem.url)"
                  v-else
                >
                  <div>
                    <svg
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#00E169" />
                    </svg>
                    <span>
                      <img
                        v-if="subItem.fileName && isMobile"
                        :src="subItem.filePath"
                      />
                      {{ subItem.name }}
                    </span>
                  </div>
                  <img
                    v-if="hasChildren(subItem)"
                    src="~@/icons/menu-chevron-right.svg"
                    alt="Menu Right"
                  />
                </a>
                <div
                  class="menu-children menu-children2"
                  :id="`subItem-${subItem.id}`"
                  v-if="hasChildren(subItem)"
                >
                  <div class="main-menu-header">
                    <label
                      ><img
                        src="/icons/logo-plusdin.svg"
                        alt="Plusdin"
                        class="credit_card__page_header__logo"
                    /></label>

                    <img
                      @click="toggleMenu(null)"
                      src="~@/icons/menu-mobile-close.svg"
                      alt="Menu"
                    />
                  </div>
                  <ul v-if="hasChildren(subItem)">
                    <!-- <ClientOnly> -->
                    <li @click="backMenu(`subItem-${subItem.id}`)">
                      <img
                        src="~@/icons/menu-chevron-left.svg"
                        alt="Menu Left"
                      />
                    </li>
                    <!-- </ClientOnly> -->
                    <li
                      :key="subItemChild2.id"
                      v-for="subItemChild2 of subItem.subItems"
                    >
                      <a :href="checkUrl(subItemChild2.url)">
                        <span>
                          <label
                            v-html="subItemChild2.icon"
                            v-if="subItemChild2.icon"
                          />
                          <p>
                            {{ subItemChild2.name }}
                          </p>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!--
                <ClientOnly>
                    <li v-if="!this.$isMobile()">
                        <SearchBox />
                    </li>
                </ClientOnly>-->
      </ul>
      <!-- <span class="about-link">
                <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2.5" cy="2.5" r="2.5" fill="black"/>
                </svg>
                <a href="#">Sobre o Plusdin</a>
            </span> -->
    </div>
  </div>
</template>

<script>
// import SearchBox from "@components/search-box/SearchBox";
import urlParams from "@/services/urlParams";

export default {
  components: {
    // SearchBox
  },

  data() {
    return {
      menuIsFirst: true,
      isShow: false,
      subMenuText: "",
      subMenuText2: "",
      loading: false,
    };
  },

  computed: {
    menu() {
      return this.$root.$children[0].menu || this.$store.state.menu;
    },

    isMobile() {
      return this.$isMobile();
    },
  },

  methods: {
    backMenu(id) {
      if (typeof document === "undefined") return false;

      const _menu = document.querySelector(`div#menu.is-show div#${id}`);

      _menu.classList.remove("is-show");
    },

    hasChildren(menu) {
      return menu.subItems && menu.subItems.length > 0;
    },

    isToggleItem(menu) {
      return (this.hasChildren(menu) && this.isMobile) || !menu.url;
    },

    toggleMenu(
      id,
      children = ".menu-children",
      subMenuTxt = null,
      subMenuSubText = null
    ) {
      if (typeof document === "undefined") return false;

      if (subMenuTxt !== null) {
        this.subMenuText = subMenuTxt;
      }

      if (subMenuSubText !== null) {
        this.subMenuText2 = subMenuSubText;
      }

      console.log(`id: ${id}`);
      console.log(`is mobile: ${this.isMobile}`);
      console.log(`children: ${children}`);
      console.log(`is show: ${this.isShow}`);

      if (this.isMobile) {
        const _menuChildren = document.querySelectorAll(
          `div#menu.is-show ${children}`
        );
        _menuChildren.forEach((value) => {
          if (value.classList.contains("is-show")) {
            value.classList.remove("is-show");
          }
        });

        if (id === null) {
          this.isShow = !this.isShow;
        } else {
          const _menu = document.querySelector(`div#menu.is-show div#${id}`);

          if (_menu !== null) {
            _menu.classList.add("is-show");
          }
        }
      }
    },

    checkUrl(url) {
      return urlParams(url, this.$route.query);
    },
  },
};
</script>
