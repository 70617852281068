<template>
<article class="recommendation__card-item">
    <div class="recommendation__card-form">
        <label for="Seu CEP">
            <input
                v-mask="'#####-###'"
                v-model="formValue"
                @input="updateInputData"
                class="form-input"
                maxlength="9"
                pattern="[0-9]{5}[\-]?[0-9]{3}"
                placeholder="Seu CEP"
                type="text"
            />
        </label>
    </div>
    <div v-if="option.wasChoosen" class="mark mt-2 text-center">
        <img src="~@/icons/corner-left-up.svg" alt="Indicação do Item" class="inline">
        <span class="ml-2">Sua escolha anterior: {{ option.wasChoosen }}</span>
    </div>
</article>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
    name: 'Form',

    directives: { mask },

    props: {
        option: {
            type: Object,
            required: true
        },
        question: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            formValue: ''
        }
    },

    methods: {
        updateInputData () {
            this.$emit('formValue', this.formValue, this.question.key, this.option.value)
        }
    }
}
</script>

<style lang="scss">
.recommendation__card-form {

    .form-input {
        width: 100%;
    }
}
</style>
