<template>
<article 
    :class="{ 'recommendation__card-item__span': setSpanSpacing() }"
    class="recommendation__card-item"
>
    <button
        :class="{
            checked: isChecked,
            'h-auto' : question.style === 'default'
        }"
        @click.prevent="answer"
        class="card__link"
        type="button"
    >
        <div
            :class="{ full: option.isFull, 'is-longer': option.is && option.text }"
            class="recommendation__card__main card__main"
        >
            <div
                v-if="question.style !== 'small'"
                class="recommendation__card__header card__header"
            >
                <span class="check check-rounded check-primary"></span>
            </div>
            <div class="card__body recommendation__card__body">
                <div v-if="option.image_checked" class="recommendation__card__image">
                    <img
                        class="recommendation__card__image--off"
                        :src="image"
                    />
                    <img
                        class="recommendation__card__image--on"
                        :src="imgSrc + option.image_checked"
                    />
                </div>
                <div v-else-if="option.image" class="recommendation__card__image--only">
                    <picture v-if="originalImage">
                        <source :srcset="image" type="image/webp">
                        <source v-if="validateImageFormat('png')" :srcset="originalImage" type="image/png">
                        <source v-if="validateImageFormat('jpeg')" :srcset="originalImage" type="image/jpeg">
                        <img
                            :src="originalImage"
                            :class="{ 'recommendation__card-image--limited': limitImagesSizes }"
                        >
                    </picture>
                    <img
                        :class="{ 'recommendation__card-image--limited': limitImagesSizes }"
                        :src="image" 
                        v-else
                    >
                </div>
                <p
                    v-if="shouldShowText"
                    class="text"
                >{{ option.text }}</p>
            </div>
        </div>
    </button>
    <div
        v-if="option.wasChoosen"
        class="mark mt-2"
    >
        <img src="~@/icons/corner-left-up.svg" alt="Indicação do Item" class="inline">
        <span class="ml-2">Sua escolha anterior</span>
    </div>
</article>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'Option',

    props: {
        option: {
            type: Object,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        value: [Array, Boolean, Number, String]
    },

    data () {
        return {
            imgSrc: '/images/recommendation/',
            isChoosed: false
        }
    },

    computed: {
        ...mapState([
            'current',
            'response'
        ]),

        countOptions () {
            return this.question.options.length
        },

        image () {
            return (this.option.image.match(/^http/) ? '' : this.imgSrc) + this.option.image
        },

        isChecked () {
            return this.isChoosed || this.value === this.option.value
        },

        limitImagesSizes () {
            return this.question.meta && this.question.meta.limit_images_sizes
        },

        originalImage () {
            return (this.option.original_image.match(/^http/) ? '' : this.imgSrc) + this.option.original_image
        },

        shouldShowText () {
            return !!this.option.text && !this.option.show_image_only
        }
    },

    methods: {
        ...mapMutations('DAStore', [
            'setNavigationTransition'
        ]),

        answer () {
            this.isChoosed = !this.isChoosed
            this.setNavigationTransition(false)

            if (this.question.type !== 'multiple') {
                this.$emit('answered', this.question.key, this.option.value)
            } else {
                this.$emit('answeredMultiple', this.option.value)
            }
        },

        validateImageFormat (format) {
            if (this.originalImage.split(".").pop() === format) {
                return true
            }
            return false
        },

        setSpanSpacing () {
            if (this.option.wasChoosen) {
                if (this.question.options.length >= 3 && this.question.layout === 'horizontal') {
                    return true
                }
            }
            return false
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/recommendation/_layout.scss";
</style>
