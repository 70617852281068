<template>
    <div class="ChatQuestionContent">
        <section class="content">
            <div>
                <img src="~@/images/recommendation/avatar-duda-branco.svg"/>
            </div>
            <div class="bg-white rounded-md p-2 baloon">
                <div>
                    <img
                        class="icon-close clickable"
                        src="~@/images/recommendation/icon-close-o.svg"
                        @click="deny"
                    />
                </div>
                <section class="p-10">
                    <div class="sub-title text-bold mt-3">
                        Vai responder as perguntas de novo?
                    </div>
                    <div class="mt-3 content-text">
                        Legal! Desta vez, vou fazer algumas perguntas a mais para te dar uma recomendação
                        <span class="text-bold">mais precisa.</span>
                    </div>
                    <div class="mt-5 flex justify-between" v-if="!$isMobile">
                        <a href="#" class="btnAnswerSameQuestions underline w-full" @click="deny">
                            Prefiro responder às mesmas perguntas
                        </a>
                        <button class="theme-button" @click="accept">
                            ótimo
                        </button>
                    </div>
                    <div v-if="$isMobile">
                        <div class="mt-5 w-full">
                            <button class="theme-button" @click="accept">
                                ótimo
                            </button>
                        </div>
                        <div class="mt-5 text-center">
                            <a href="#" class="btnAnswerSameQuestions underline" @click="deny">
                                Prefiro responder às mesmas perguntas
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        accept: {
            type: Function,
            required: true
        },
        deny: {
            type: Function,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.ChatQuestionContent {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    width: 100%;
    height: 100%;
}

.ChatQuestionContent .content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 466px;
    margin-top: 2%;
}

.baloon{
    margin: 0 auto;
    height: auto;
    position: relative;
    margin-top: 4%;
}

.baloon:after{
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    top: -15px;
    left: 47%;
}

.ChatQuestionContent img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ChatQuestionContent .icon-close{
    float: right;
}

.ChatQuestionContent .sub-title{
    font-size: 24px;
    line-height: 31.2px;
    font-weight: 600;
}

.ChatQuestionContent .content-text{
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
}

.ChatQuestionContent .btnAnswerSameQuestions{
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 400;
    width: 142px;
}

.theme-button {
    background-color: $v2-background1-color;
    @apply text-gray-700 text-black text-xs uppercase text-base px-6 py-3;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    border-radius: 4px;
    width: 166px;
}

.clickable{
    cursor: pointer;
}

@media only screen and (max-width: 998px) {
    .ChatQuestionContent .content {
        width: 85%;
        margin-top: 4%;
    }

    .ChatQuestionContent .content-text{
        font-size: 16px;
        line-height: 20.8px;
    }

    .baloon{
        margin-top: 3%;
    }

    .baloon:after{
        top: -12px;
        left: 48%;
    }
}

@media only screen and (max-width: 700px) {
    .ChatQuestionContent .content {
        width: 95%;
        margin-top: 7%;
    }

    .baloon{
        margin-top: 8%;
    }

    .baloon:after{
        top: -12px;
        left: 46%;
    }

    .ChatQuestionContent button{
        display: block;
    }

    .theme-button {
        margin: 0 auto;
        width: 206px;
    }
}
</style>
