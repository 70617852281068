<template>
    <section
        id="recommendation__question"
        class="recommendation-v2 recommendation__page"
    >
        <div class="recommendation__content">
            <header class="recommendation__top wrapper">
                <div class="recommendation__logo">
                    <a :href="!isCampaign ? checkUrl('/') : null"
                        aria-label="Plusdin"
                        class="logo__link"
                    >
                        <picture>
                            <source
                                media="(max-width: 1023px)"
                                srcset="~@/images/logos/logo-plusdin-short.svg"
                                type="image/svg+xml"
                            />
                            <source
                                media="(min-width: 1024px)"
                                srcset="~@/images/logos/logo-plusdin.svg"
                                type="image/svg+xml"
                            />
                            <img
                                alt="Plusdin"
                                class="logo-plusdin"
                                src="~@/images/logos/logo-plusdin-short.svg"
                            />
                        </picture>
                        <span class="sr-only">Plusdin</span>
                    </a>
                </div>
                <Duda />
                <div class="recommendation__back-button">
                    <button
                        @click="restart"
                        type="button"
                        v-if="!isFirstQuestion"
                    >
                        <i class="icon icon-undo"></i>
                        <span>Refazer</span>
                    </button>
                </div>
            </header>
            <Quiz
                @answered="handleAnswer"
                :question="current"
                :percentage="percentage"
                :step="step"
            />
        </div>
        <Footer v-if="isFirstQuestion" />
        <ChatQuestion
            :accept="acceptLongFlow"
            :deny="denyLongFlow"
            :hidden="!repeating"
        ></ChatQuestion>
    </section>
</template>

<script>
import ChatQuestion from "@/components/new-quiz/ChatQuestion";
import Footer from "@/components/new-quiz/Footer";
import Quiz from "@/components/new-quiz/Quiz";
import { mapActions, mapGetters, mapState } from "vuex";
import recommendationTracking from '@/mixins/tracking/recommendation'
import Duda from "@/components/Duda";
import urlParams from "@/services/urlParams";

export default {
    name: "Recommendation",

    components: {
        ChatQuestion,
        Footer,
        Quiz,
        Duda
    },

    data() {
        return { repeating: false };
    },

    mixins: [recommendationTracking],

    beforeMount() {
        this.checkFlow()

        if (this.current.key === "flow") {
            let historic = this.lastQuizResponse;
            if (
                Object.keys(historic).length > 0 &&
                historic.flow !== "long" &&
                historic.completed
            ) {
                this.repeating = true;
                historic.completed = false;
                this.setLastQuizResponse(historic);
            }
        }
    },

    mounted() {
        this.autoHandleFlow();
    },

    watch: {
        $route: {
            handler() {
                this.setCurrentQuestion({ ...this.$route.params });
            },
            immediate: true
        }
    },

    computed: {
        ...mapState([
            "askedQuestions",
            "current",
            "currentIndex",
            "response",
            "questionsHistory",
            "questionsV2",
            "flow",
            "flows",
            "lastQuizResponse"
        ]),

        ...mapGetters(["isFirstQuestion", "lastQuizResponse"]),

        flowSize() {
            if (!this.flow) return 10;

            return this.flows[this.flow].length;
        },

        defaultFlow() {
           return !this.$route.params.flow ? 'short' : this.$route.params.flow
        },

        isCampaign() {
            return this.$route.query.campaign === "true";
        },

        percentage() {
            const index =
                this.questionsHistory.indexOf(this.$route.params.slug) + 1;

            return (index / this.flowSize) * 100;
        },

        step() {
            if (!this.flow) return {};

            return this.flows[this.flow].find(
                step => step.question === this.current.key
            );
        }
    },

    methods: {
        ...mapActions([
            "clearAskedQuestions",
            "clearQuestionsHistory",
            "clearResponseKey",
            "removeAskedQuestion",
            "removeQuestionHistory",
            "setCurrentQuestion",
            "setFlow",
            "setLastQuizResponse",
            "setResponseKey"
        ]),

        autoHandleFlow() {
            const isAutoFlow = !this.flow && !!this.$route.params.flow;

            if (isAutoFlow) {
                const flow =
                    this.$route.params.flow === "fl" ? "long" : "short";
                this.setResponseKey(["flow", flow]);
                this.setFlow({ flow });
                let next = this.filterQuestion(
                    this.flows[this.flow],
                    "flow",
                    flow
                );
                this.goToNext(next);
            }
        },

        checkFlow() {
            this.setFlow({ flow: this.defaultFlow });
            this.$route.params.flow = this.defaultFlow
        },

        filterNextQuestion(questions, value) {
            return questions.filter(next => next.key === value)[0].slug;
        },

        filterQuestion(flows, key) {
            let currentStep = flows.find(question => question.question === key);

            if (typeof currentStep.next === "string") {
                return currentStep.next;
            }

            const options = Object.keys(currentStep.next);

            for (let option of options) {
                const def = currentStep.next[option];

                const filteredResponse = def.operator === "is"
                    ? this.filterIsQuestion(currentStep, def, option)
                    : this.filterIsAnyQuestion(currentStep, def, option);
                
                if (filteredResponse) return filteredResponse;
            }
            
            return false;
        },
        
        filterIsQuestion(currentStep, def, option) {
            const questionIsStringAndMatchResponse =
                typeof currentStep.next[option].question === "string"
                && this.response[def.question] === def.value;
            
            return questionIsStringAndMatchResponse
                ? option
                : this.findOptionInQuestionSources(currentStep, def, option);
        },
        
        filterIsAnyQuestion(currentStep, def, option) {
            let simpleQuestionAndMatchValueResponse = 
                typeof def.question === "string"
                && def.value.includes(this.response[def.question]);
            
            return simpleQuestionAndMatchValueResponse
                ? option
                : this.findOptionInQuestionSources(currentStep, def, option);
        },
        
        findOptionInQuestionSources(currentStep, def, option) {
            const responses = Object.keys(
                currentStep.next[option].question
            );

            for (let response of responses) {
                const source = currentStep.next[option].question[response];
                
                const valueIsStringAndMatchResponse =
                    typeof def.value === "string" 
                    && this.response[source] === def.value;
                
                if (valueIsStringAndMatchResponse) return option;
                
                const valueIsArrayAndIncludesResponse =
                    Array.isArray(def.value) 
                    && def.value.includes(this.response[source]);
                
                if (valueIsArrayAndIncludesResponse) return option;
            }
            
            return false;
        },

        /**
         * @todo Refactoring by extraction
         */
        getQuestionValueLabel(value, question = {}) {  
            try {
                question = this.current

                if (Array.isArray(value)) {
                    return question.options
                        .filter(option => value.includes(option.value))
                        .map(option => option.text)
                        .join(", ");
                }

                return question.options.find(
                    option => option.value === value
                ).text;
            } catch (ex) {
                return value;          
            }
        },

        /**
         * @todo Refactoring by extraction
         */
        getQuestionValueIndex (value, question = {}) {
            try {
                question = question.key ? question : this.current

                if (Array.isArray(value)) {
                    return question.options
                        .filter(option => value.includes(option.value))
                        .map(option => question.options.findIndex(opt => opt.value === option.value) + 1)
                        .join(', ')
                }

                return question.options.findIndex(option => option.value === value) + 1
            } catch (ex) {
                return value
            }
        },

        goToNext(next) {
            try {
                this.syncQuestions();
                const slug = this.filterNextQuestion(this.questionsV2, next);
                const flow = this.$route.params.flow;
                const params = { slug, ...(flow ? { flow } : {}) };
                const query = this.$route.query;

                this.$router
                    .push({
                        name: "v2.credit-card.recommendation.question",
                        params,
                        query
                    })
                    .catch(() => {});
            } catch (err) {
                if (next === "result") {
                    const historic = {
                        ...this.response,
                        completed: true
                    };
                    this.setLastQuizResponse(historic);

                    this.$router
                        .push({
                            name: "v2.credit-card.recommendation.gate",
                            query: this.$route.query
                        })
                        .catch(() => {});
                }
            }
        },

        handleAnswer(key, value) {
            const payload = [key, value];

            this.setResponseKey(payload);

            if (!this.flow && this.isFirstQuestion) {
                this.setFlow({ flow: value });
            }

            this.trackAnswer(key, value);

            let next = this.filterQuestion(this.flows[this.flow], key, value);

            if (this.current.type !== "multiple") {
                setTimeout(() => {
                    this.goToNext(next);
                }, 500);
            } else {
                this.goToNext(next);
            }
        },

        restart() {
            this.clearResponseKey();
            this.clearAskedQuestions();
            this.clearQuestionsHistory();
            this.sendToStart();
        },

        sendToStart() {
            this.$router
            .push({
                name: "v2.credit-card.recommendation.question",
                params: { slug: 'inicio', flow: this.flow },
                query: this.$route.query
            })
            .catch(() => {});
        },
        
        syncQuestions() {
            const actualIndex = this.askedQuestions.indexOf(this.current.key)
            const questionsToSync = [...this.askedQuestions].splice(actualIndex + 1)

            questionsToSync.forEach(item => {
                const hasAnswer = this.response[item]
                
                if (!hasAnswer) {
                    const question = this.questionsV2.find(question => question.key === item)
                    this.removeAskedQuestion(item)
                    this.removeQuestionHistory(question.slug)
                }
            })   
            
        },

        acceptLongFlow() {
            if (this.current.key === "flow") {
                this.handleAnswer("flow", "long");
            }
        },

        denyLongFlow() {
            if (this.current.key === "flow") {
                this.handleAnswer("flow", "short");
            }
        },

        checkUrl(url) {
            return urlParams(url, this.$route.query)
        }
    }
};
</script>

<style lang="scss">

.recommendation-v2 {
    &.recommendation__page {
        @apply flex;
        @apply flex-col;
        @apply justify-between;
        min-height: 100vh;
    }

    .recommendation__top {
        @apply flex;
        @apply flex-row;
        @apply items-center;
        @apply justify-between;
        padding-top: 12px;
        @media screen and (min-width: $screen-tablet-min-width) {
            padding-top: 31px;
        }
    }

    .recommendation__logo {
        flex-basis: 30%;
    }

    .recommendation__avatar {
        @apply flex;
        @apply justify-center;
        flex-basis: 40%;

        img {
            width: 66px;
            height: auto;

            @media screen and (min-width: $screen-tablet-min-width) {
                width: 72px;
            }
        }
    }

    .recommendation__back-button {
        @apply flex;
        @apply justify-end;
        flex-basis: 30%;
        position: relative;

        .icon-undo {
            transition: transform .6s ease-in-out;
            background-image: url("/icons/icon-undo.svg");
        }

        button {
            @apply flex;
            @apply flex-row;
            @apply items-center;
            padding: 10px;
            margin-right: -10px;

            span {
                @media screen and (max-width: $screen-tablet-max-width) {
                    display: none;
                }

                @media screen and (min-width: $screen-tablet-min-width) {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 150%;
                    padding-left: 8px;
                }
            }
        }
    }

    .recommendation__back-button:hover {
        .icon-undo {
            transform: rotate(-360deg);
        }
    }

    .recommendation__back-confirm {
        background: $color-dark;
        border-radius: 10px;
        position: absolute;
        top: 43px;
        right: 0;
        padding: 10px 12px;
        width: 120px;

        p {
            color: $color-light;
            font-size: 11px;
            line-height: 1.2;
        }
    }

    .recommendation__header {
        @apply flex;
        @apply flex-col;
        @apply justify-center;
        max-width: 554px;
        min-height: 115px;

        @media screen and (min-width: $screen-tablet-min-width) {
            max-width: 622px;
        }

        .text {
            &-sm {
                padding-top: 8px;
            }

            &-2xl {
                padding-top: 24px;
                padding-bottom: 24px;
            }
        }
    }
}
</style>
