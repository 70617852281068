<template>
<header class="card-comparator__section-cards comparator-cards-headers">
    <section
        :key="card.id"
        v-for="card of cards"
        class="comparator-cards-header__section"
    >
        <button
            @click="remove(card)"
            v-if="!card.empty"
            class="card-comparator__card-close"
            type="button"
        ></button>
        <img
            v-if="card.empty"
            class="card-comparator__card-image"
            src="@/images/card-comparator/card-default-large.png"
            alt="Escolha outro cartão"
        />
        <template v-else>
            <div
                :class="{ 'card-comparator__card-image-wrapper--taller': hasVerticalImage }"
                class="card-comparator__card-image-wrapper"
            >
                <img
                    @is-vertical="hasVerticalImage = true"
                    :src="card.image_file.url"
                    v-assert-vertical-image
                    class="card-comparator__card-image"
                    alt="Escolha um cartão"
                />
            </div>
            <div class="card-comparator__card-cta">
                <p class="card-comparator__card-name">{{ card.name }}</p>
                <a
                    :href="checkUrl(`https://plusdin.com/cartao-de-credito/${card.slug}/como-solicitar`)"
                    class="card-comparator__card-btn"
                ><span class="track" @click="trackRequest(card.slug)">Solicitar agora</span></a>
            </div>
        </template>
    </section>
</header>
</template>

<script>
import AssertVerticalImage from '@/directives/assert-vertical-image.js'
import tracking from '@/services/tracking'
import urlParams from "@/services/urlParams";

export default {
    props: {
        cards: Array,
        scroll: Boolean
    },

    directives: { AssertVerticalImage },

    data () {
        return { hasVerticalImage: false }
    },

    methods: {
        checkUrl(url) {
            return urlParams(url, this.$route.query)
        },

        remove (card) {
            const slugs = this.cards.map(one => one.slug)
                .filter(one => one !== card.slug)

            this.$emit('removed', slugs)
        },

        trackRequest (slug) {
            tracking.pushInteraction('comparador-cartoes', 'click-btn:solicitar-agora', slug)
        }
    }
}
</script>

<style lang="scss">
.comparator-cards-headers {
    @apply flex;
    @apply justify-between;
    @apply relative;
    margin-bottom: 5.125rem;
}

.comparator-cards-header__section {
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply relative;
    width: 158px;
}

// .card-comparator__chooser-button--fixed {
//     position: fixed;
//     margin-top: 20px;
//     top: 50px;
//     width: 158px;
//     z-index: 11;
// }

@media screen and (min-width: $screen-tablet-min-width) {
    .comparator-cards-header__section {
        width: 100%;
    }

    // .card-comparator__chooser-button--fixed {
    //     width: 23%;
    // }
}
</style>
