<template>
<div class="header">
    <div class="header__container">
        <div class="header__left">
            <a
                :href="checkUrl('https://plusdin.com')"
            >
                <img
                    class="header__logo"
                    src="/icons/logo-plusdin.svg"
                    alt="Plusdin"
                />
                <img
                    class="header__logo header__logo--green"
                    src="/icons/logo-plusdin-green.svg"
                    alt="Plusdin"
                />
            </a>
        </div>
        <Menu v-if="showExtended" />
    </div>
</div>
</template>

<script>
import urlParams from "@/services/urlParams";
import Menu from '@/components/Menu'

export default {
    components: { Menu },

    props: {
        categories: {
            type: Array,
            required: false
        },
        showExtended: {
            default: true,
            type: Boolean
        }
    },

    methods: {
        checkUrl(url) {
            return urlParams(url, this.$route.query)
        }
    }
}
</script>

<style lang="scss">
.header {
    .header__container {
        @apply mx-auto w-full flex flex-row px-6 pt-4 justify-between items-center;
    }

    .header__left {
        @apply w-9/12 h-full;
        a {
            @apply inline-block mr-3 mt-3;
        }
        .header__logo {
            width: 160px;
            &--small {
                width: 90px;
            }
        }
        .header__logo-news {
            width: 118px;
        }
        .header__logo--green {
            @apply hidden;
        }
    }
    .menu__icon {
        background-color: $v2-background1-color;
        @apply inline-block text-center rounded-full p-3;
        img {
            width: 20px;
            height: 20px;
            @apply inline-block;
        }
    }
}

@media screen and (min-width: $screen-tablet-min-width) {
    .header {
        .header__container {
            @apply px-0 max-w-screen-xl flex flex-row w-full;
        }

        .header__left {
            @apply w-3/12;
        }
        .articles__page_container__menu {
            @apply hidden;
        }
        .header_right {
            @apply w-9/12 flex flex-row items-center justify-end;
            .articles__page_menu_suspense {
                @apply flex flex-row items-center justify-between;
                ul {
                    @apply p-0 m-0 flex-row flex;
                    li {
                        @apply px-4 mx-4 font-semibold text-sm uppercase;
                        &.is__dropdown {
                            @apply relative;
                            &:after {
                                background-image: url("/icons/arrow-down-black-sm.svg");
                                content: "";
                                @apply absolute top-0 right-0 mt-2 block w-3 h-3 bg-no-repeat bg-center;
                                right: -10px;
                            }
                            ul {
                                @apply absolute w-full bg-white p-3 m-3 bg-opacity-75 top-0 left-0;
                                margin-top: 30px;
                                li {
                                    @apply flex flex-col w-full p-0 m-0;
                                    a {
                                        @apply cursor-pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            img {
                @apply block;
            }
        }
    }
}
</style>
