import Repository from './repository'
import axios from 'axios'

class Faqs extends Repository {
    createQuestion (data) {
        return axios.post(`${this.baseUrl}/faq/questions`, data)
            .then(response => response.data)
    }

    createAnswer (data) {
        return axios.post(`${this.baseUrl}/faq/answer`, data)
            .then(response => response.data)
    }

    byCard (cardId) {
        return axios.get(`${this.baseUrl}/faq/questions/cards/${cardId}`)
            .then(response => response.data)
    }
}

export default new Faqs()
