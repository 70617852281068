<template>
    <section
        id="recommendation__question"
        class="recommendation-v2 recommendation__page"
    >
        <div class="recommendation__content">
            <header class="recommendation__top wrapper">
                <div class="recommendation__logo">                  
                    <a :href="!isCampaign ? checkUrl('/') : null"
                        aria-label="Plusdin"
                        class="logo__link"
                    >
                        <picture>
                            <source
                                media="(max-width: 1023px)"
                                srcset="@/images/logos/logo-plusdin-short.svg"
                                type="image/svg+xml"
                            />
                            <source
                                media="(min-width: 1024px)"
                                srcset="@/images/logos/logo-plusdin.svg"
                                type="image/svg+xml"
                            />
                            <img
                                alt="Plusdin"
                                class="logo-plusdin"
                                src="@/images/logos/logo-plusdin-short.svg"
                            />
                        </picture>
                        <span class="sr-only">Plusdin</span>
                    </a>
                </div>
                <Duda />
                <div class="recommendation__back-button">
                    <button type="button" v-if="false">
                        <i class="icon icon-undo"></i>
                        <span>Refazer</span>
                    </button>
                </div>
            </header>
            <Quiz
                @answered="handleAnswer"
                :question="current"
                :percentage="percentage"
                :step="step"
            />
        </div>
        <Footer v-if="!isCampaign" :isFixedForLargeDisplay="!isMobile" />
        <FooterRecommendation
            v-if="isCampaign"
            :is-footer-full="isFirstQuestion"
            :isFixedForLargeDisplay="!isMobile"
        />
    </section>
</template>

<script>
import Footer from "@/components/Footer";
import FooterRecommendation from "@/components/sections/credit-card/recommendation/Footer";
import Quiz from "@/components/digital-accounts/quiz/Quiz";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import tracking from "@/services/tracking";
import { stripTags } from "@/utils/string";
import Duda from "@/components/Duda";
import urlParams from "@/services/urlParams";

export default {
    name: "Recommendation",

    components: {
        FooterRecommendation,
        Footer,
        Quiz,
        Duda
    },

    data() {
        return {
            isFirstQuestion: true,
            repeating: false,
            step: {}
        };
    },

    watch: {
        $route: {
            handler() {
                this.setCurrentQuestion({ ...this.$route.params });
            },
            immediate: true
        }
    },

    computed: {
        ...mapState("DAStore", [
            "current",
            "questions",
            "currentIndex",
            "response",
            "questionsHistory",
            "lastQuizResponse"
        ]),
        ...mapGetters(["lastQuizResponse"]),

        ...mapGetters(["isMobile"]),

        percentage() {
            const questionIndex = this.questions.findIndex(
                el => el.key === this.current.key
            );
            const totalQuestions = this.questions.length; // TODO: Use another logic to calc amout of questions including the outer path question.
            const noq =
                this.current.slug === "cep"
                    ? totalQuestions + 1
                    : totalQuestions;
            const percent = Math.ceil(((questionIndex + 1) * 100) / noq);
            return percent;
        },

        isCampaign() {
            return this.$route.query.campaign === "true";
        }
    },

    methods: {
        ...mapActions("DAStore", [
            "setResponseKey",
            "setCurrentQuestion",
            "setLastQuizResponse"
        ]),

        ...mapMutations("DAStore", ["setNavigationTransition"]),

        shouldTrackSingleOptions(value) {
            return (
                Array.isArray(value) &&
                this.current.meta &&
                this.current.meta.track_single_options
            );
        },

        trackAnswer(key, value) {
            if (this.shouldTrackSingleOptions(value)) {
                value.forEach(item =>
                    tracking.pushAnswerLegacy(
                        "Recomendador de Contas Bancárias Digitais - Perguntas",
                        stripTags(this.current.title),
                        this.getQuestionValueLabel(item)
                    )
                );
            }

            tracking.pushAnswerLegacy(
                "Recomendador de Contas Bancárias Digitais - Perguntas",
                stripTags(this.current.title),
                this.getQuestionValueLabel(value)
            );
        },

        getQuestionValueLabel(value) {
            try {
                if (Array.isArray(value)) {
                    return this.current.options
                        .filter(option => value.includes(option.value))
                        .map(option => option.text)
                        .join(", ");
                }

                return this.current.options.find(
                    option => option.value === value
                ).text;
            } catch (ex) {
                return value;
            }
        },

        handleAnswer: function(key, value, slug) {
            const payload = [key, value];
            this.setResponseKey(payload);
            this.trackAnswer(key, value);

            setTimeout(() => {
                if (slug) {
                    this.setNavigationTransition(false); // This method expects isPrevious boolean
                    this.$router
                        .push({
                            name: "v1.digital-accounts.recommendation.questions.question",
                            params: { slug },
                            query: this.$route.query
                        })
                        .catch(() => {});
                } else {
                    this.$router
                        .push({
                            name: "v1.digital-accounts.recommendation.gate",
                            query: this.$route.query
                        })
                        .catch(() => {});
                }
            }, 750);
        },

        checkUrl(url) {
            return urlParams(url, this.$route.query)
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/recommendation/critical.scss";
@import "@/styles/recommendation/main.scss";

.recommendation-v2 {
    &.recommendation__page {
        @apply flex;
        @apply flex-col;
        @apply justify-between;
        min-height: 100vh;
    }

    .recommendation__top {
        @apply flex;
        @apply flex-row;
        @apply items-center;
        @apply justify-between;
        padding-top: 12px;
        @media screen and (min-width: $screen-tablet-min-width) {
            padding-top: 31px;
        }
    }

    .recommendation__logo {
        flex-basis: 30%;
    }

    .recommendation__avatar {
        @apply flex;
        @apply justify-center;
        flex-basis: 40%;

        img {
            width: 66px;
            height: auto;

            @media screen and (min-width: $screen-tablet-min-width) {
                width: 72px;
            }
        }
    }

    .recommendation__back-button {
        @apply flex;
        @apply justify-end;
        flex-basis: 30%;
        position: relative;

        .icon-undo {
            background-image: url("~@/icons/icon-undo.svg");
            transition: transform 0.3s linear;
            transform-origin: center center;
        }

        button {
            @apply flex;
            @apply flex-row;
            @apply items-center;
            padding: 10px;
            margin-right: -10px;

            &:hover {
                .icon-undo {
                    transform: rotate(-1turn);
                }
            }

            span {
                @media screen and (max-width: $screen-tablet-max-width) {
                    display: none;
                }

                @media screen and (min-width: $screen-tablet-min-width) {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 150%;
                    padding-left: 8px;
                }
            }
        }
    }

    .recommendation__back-confirm {
        background: $color-dark;
        border-radius: 10px;
        position: absolute;
        top: 43px;
        right: 0;
        padding: 10px 12px;
        width: 120px;

        p {
            color: $color-light;
            font-size: 11px;
            line-height: 1.2;
        }
    }

    .recommendation__header {
        @apply flex;
        @apply flex-col;
        @apply justify-center;
        max-width: 554px;
        min-height: 115px;

        @media screen and (min-width: $screen-tablet-min-width) {
            max-width: 622px;
        }

        .text {
            &-sm {
                padding-top: 8px;
            }

            &-2xl {
                padding-top: 24px;
                padding-bottom: 24px;
            }
        }
    }
}
</style>
