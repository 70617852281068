<template>
    <section>
        <Nav :percentage="percentage" />
        <transition
            mode="out-in"
            :name="navigationTransition"
        >
            <main class="recommendation__main" :key="question.title">
                <header
                    class="recommendation__header wrapper leading-6"
                    :style="question.slug === 'valor-mensal' ? 'max-width: 85ch' : ''"
                >
                <h2
                    v-if="question.quizInfo"
                    class="text text text-center leading-6"
                    v-html="question.quizInfo"
                ></h2>
                <h1
                    class="text text-3xl text-normal text-center"
                    v-html="question.title"
                ></h1>
                </header>
                <section
                    :class="classLayout"
                    :key="question.key"
                    class="recommendation__card-list"
                >
                    <template
                        v-if="question.type !== 'range' && question.type !== 'form'"
                    >
                        <Option
                            :key="answer.value"
                            :option="answer"
                            :question="question"
                            @answered="answered"
                            @answeredMultiple="answeredMultiple"
                            :value="questionValue"
                            v-for="answer in processedOptions"
                        />
                    </template>
                    <template v-else-if="question.type === 'range'">
                        <Range
                            :key="answer.value"
                            :option="answer"
                            :question="question"
                            @rangeValue="handleRangeValue"
                            v-for="answer in processedOptions"
                        />
                    </template>
                    <template v-else-if="question.type === 'form'">
                        <Form
                            :key="answer.value"
                            :option="answer"
                            :question="question"
                            @formValue="handleFormValue"
                            v-for="answer in processedOptions"
                        />
                    </template>
                    <div v-if="question.by_pass_option" class="recommendation__card-skip">
                        <button
                            @click.prevent="skipQuestion"
                            type="button"
                            class="button--lined"
                        >
                            {{ question.by_pass_option }}
                        </button>
                    </div>
                    <div v-if="shouldShowContinue" class="recommendation__card-continue">
                        <button
                            :disabled="unavailable"
                            @click.prevent="answer"
                            type="button"
                            class="button button-default button-primary"
                        >
                            Continuar
                        </button>
                    </div>
                </section>
            </main>
        </transition>
    </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Form from "@/components/digital-accounts/forms/Form";
import Nav from "@/components/digital-accounts/navigation/Nav";
import Option from "@/components/digital-accounts/quiz/Option";
import Range from "@/components/digital-accounts/inputs/Range";

export default {
  name: "Quiz",

  components: {
    Form,
    Nav,
    Option,
    Range,
  },

  props: {
    percentage: {
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    quizInfo: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      unavailable: false,
      value: [],
      nextSlug: "cartao-de-credito",
      showTitle: false,
      show: false,
    };
  },

  updated() {
    this.nextSlug = this.question.options[0].nextQuestion;
  },

  watch: {
    $route: {
      handler() {
        this.showTitle = this.$route.params.slug === "saques";
        this.unavailable = this.question.slug === "cep";
      },
      immediate: true,
    },
  },

  mounted() {
    this.show = true;
  },

  computed: {
    ...mapGetters("DAStore", [
      "currentHasResponse",
      "nextQuestion",
      "lastQuizResponse",
    ]),

    ...mapState("DAStore", ["response", "questions", "navigationTransition"]),

    classLayout() {
      return {
        multiple: this.question.type === "multiple",
        small: this.question.style === "small",
        large: this.question.style === "large",
        vertical: this.question.layout === "vertical",
      };
    },

    fromValueAsArray() {
      let fromValue = this.response[this.step.exclude_options_from];

      return Array.isArray(fromValue) ? fromValue : [fromValue];
    },

    questionValue() {
      return this.response[this.question.key];
    },

    shouldShowContinue() {
      return !["single", "boolean"].includes(this.question.type);
    },

    processedOptions() {
      if (!this.step.exclude_options_from) {
        if (Object.keys(this.lastQuizResponse).length === 0) {
          return this.question.options;
        } else {
          let callable = "";
          if (this.question.type === "range" || this.question.type === "form") {
            callable = "processRangeOrFormOption";
          } else {
            callable = "processSingleOption";
          }
          return this.question.options.map((item, index) => {
            if (this.question.key in this.lastQuizResponse) {
              this[callable](item, index);
            } else {
              item.wasChoosen = false;
            }
            return item;
          });
        }
      }
      return this.question.options.filter(
        (option) => !this.fromValueAsArray.includes(option.value)
      );
    },
  },

  methods: {
    answer() {
      if (this.question.type === "form" && !this.value.length) {
        this.value = "";
      }
      this.$emit("answered", this.question.key, this.value, this.nextSlug);
    },

    answered(key, value, slug) {
      this.$emit("answered", key, value, slug);
    },

    answeredMultiple(value) {
      if (this.value.includes(value)) {
        this.value = this.value.filter((item) => item !== value);

        if (!this.value.length) {
          this.unavailable = true;
        }
      } else {
        this.value.push(value);
        this.unavailable = false;
      }
    },

    disabled() {
      return !this.unavailable;
    },

    skipQuestion() {
      this.$emit(
        "answered",
        this.question.key,
        this.value,
        this.question.options[0].nextQuestion
      );
    },

    handleRangeValue(rangeValue, key, value, nextSlug) {
      this.value = Number(rangeValue);
      this.nextSlug = nextSlug;
      this.unavailable = false;
    },

    handleFormValue(formValue, key, value, nextQuestion) {
      this.unavailable = formValue.length !== 9;
      this.nextSlug = nextQuestion;
      this.value = formValue;
    },

    processRangeOrFormOption(item, index) {
      if (Array.isArray(this.lastQuizResponse[this.question.key])) {
        item.wasChoosen = this.lastQuizResponse[this.question.key][index];
      } else {
        item.wasChoosen = this.lastQuizResponse[this.question.key];
      }
      return item;
    },

    processSingleOption(item) {
      if (
        (Array.isArray(this.lastQuizResponse[this.question.key]) &&
          this.lastQuizResponse[this.question.key].includes(item.value)) ||
        this.lastQuizResponse[this.question.key] === item.value
      ) {
        item.wasChoosen = true;
      } else {
        item.wasChoosen = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/recommendation/settings/_colors.scss";

.mark {
  font-size: 12px;
  color: #00a26b;
}

@keyframes fadeInRightTweak {
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftTweak {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-name: fadeInLeftTweak;
}

.fadeInRight {
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-name: fadeInRightTweak;
}

.recommendation-v2 {
  .recommendation__header {
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    max-width: 554px;
    min-height: 72px;

    @media screen and (min-width: $screen-tablet-min-width) {
      max-width: 622px;
    }

    .text {
      &-sm {
        padding-top: 8px;
      }

      &-2xl,
      &-3xl {
        padding-top: 23px;
        padding-bottom: 23px;

        small {
          color: $color-neutral-600;
          display: block;
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  .recommendation__card {
    &-list {
      @apply flex;
      @apply flex-wrap;
      @apply justify-center;
      padding: 15px 15px 88px;

      &.vertical {
        @apply flex-col;
        margin-left: auto;
        margin-right: auto;
        max-width: 374px;
      }
    }

    &-continue {
      @apply flex;
      @apply justify-center;
      bottom: 22px;
      flex-basis: 100%;
      margin-top: 40px;
      max-width: 100%;
      padding-left: 7px;
      padding-right: 7px;
      position: sticky;

      @media screen and (min-width: $screen-tablet-min-width) {
        margin-top: 50px;
      }

      .button {
        width: 330px;
      }
    }

    &-skip {
      @apply flex;
      @apply justify-center;
      flex-basis: 100%;
      margin-top: 12px;
      max-width: 100%;
      padding-left: 7px;
      padding-right: 7px;

      .button--lined {
        font-size: 14px;
      }
    }
  }
}
</style>
