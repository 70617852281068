import store from '../../store'

const creditCard = {
    question (to, from, next) {
        if (to.params.slug === 'inicio') {
            next({ query: to.query })
        } else {
            const question = store.state.questionsV2.find(one => one.slug === to.params.slug)

            if (!question) {
                from.params.slug = 'inicio'
                next({
                    ...from,
                    name: 'v2.credit-card.recommendation.question',
                    query: to.query
                })
            } else {
                const response = store.state.response[question.key]
                const flow = store.state.flows[store.state.flow]
                const index = flow.findIndex(step => step.question === question.key)
                const shouldContinue = index === 0 || typeof response !== 'undefined'

                if (shouldContinue) next({ query: to.query })
                else {
                    if (from.params.slug === undefined) {
                        from.params.slug = 'inicio'
                    }

                    next({
                        ...from,
                        name: 'v2.credit-card.recommendation.question',
                        query: to.query
                    })
                }
            }
        }
    },

    gate (to, from, next) {
        const questionsCount = store.state.askedQuestions.length
        const responsesCount = Object.keys(store.state.response).length

        if (questionsCount === responsesCount && responsesCount !== 0) {
            next({ query: to.query })
        } else {
            next({
                ...from,
                name: 'v2.credit-card.recommendation.question',
                params: { slug: 'inicio', flow: 'long' },
                query: to.query
            })
        }
    }
}

export default creditCard
