import Repository from './repository'
import axios from 'axios'

class Emails extends Repository {
    subscribe (data) {
        return axios.post(`${this.baseUrl}/emails/emails`, data)
    }
}

export default new Emails()
