import Vue from "vue";
import Vuex from "vuex";
import emails from "@/services/emails";
import cards from "@/services/cards";
import leads from "@/services/leads";
import common from "@/services/common";
import news from "@/services/news";
import faqs from "@/services/faqs";
import comments from "@/services/comments";
import ranks from "@/services/ranks";
import persistPlugin from "./persist-plugin";
import issuers from "./issuers";
import partnersStores from "./partners-stores";
import airlines from "./airlines";
import cardsComparision from "./cards-comparision";
import DAStore from "./digital-accounts/v1";

Vue.use(Vuex);

/**
 * @todo Refactor by extraction
 */
function objectKeyValue(payload) {
    const key = Object.keys(payload)[0];

    return { key, value: payload[key] };
}

/**
 * @todo Refactor by extraction
 */
function newestSorter(post1, post2) {
    return new Date(post2.created_at) - new Date(post1.created_at);
}

function currentlyYear() {
    const date = new Date()
    return date.getFullYear()
}

export default new Vuex.Store({
    plugins: [persistPlugin],

    modules: {
        cardsComparision,
        DAStore,
        issuers,
        partnersStores,
        airlines,
    },

    state: {
        askedQuestions: [],
        loaderRanks: false,
        loading: false,
        menu: {
            items: [
                {
                    id: 1,
                    name: "Cartões de Crédito",
                    slug: "cartoes-de-credito",
                    url: null,
                    subItems: [
                        {
                            id: 6,
                            name: "Os Melhores",
                            slug: "os-melhores",
                            url: "/cartao-de-credito/os-melhores",
                            subItems: [
                                {
                                    id: 9,
                                    name: `Os Melhores de ${currentlyYear()}`,
                                    slug: `os-melhores-de-${currentlyYear()}`,
                                    url: "/cartao-de-credito/os-melhores",
                                    icon: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 11.9C11.2822 11.4367 13 9.41896 13 7V0H3V7C3 9.41896 4.71776 11.4367 7 11.9V14H5V16H11V14H9V11.9ZM5 2H11V7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7V2Z" fill="black"/><path d="M14 2H16V7H14V2Z" fill="black"/><path d="M2 2H0V7H2V2Z" fill="black"/></svg>`
                                },
                                {
                                    id: 10,
                                    name: "Melhores para negativados",
                                    slug: "melhores-para-negativados",
                                    url: "/cartao-de-credito/negativados",
                                    icon: `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 8C8.20914 8 10 6.20914 10 4C10 1.79086 8.20914 0 6 0C3.79086 0 2 1.79086 2 4C2 6.20914 3.79086 8 6 8ZM6 6C7.10457 6 8 5.10457 8 4C8 2.89543 7.10457 2 6 2C4.89543 2 4 2.89543 4 4C4 5.10457 4.89543 6 6 6Z" fill="black"/><path d="M9 11C9.55228 11 10 11.4477 10 12V18H12V12C12 10.3431 10.6569 9 9 9H3C1.34315 9 0 10.3431 0 12V18H2V12C2 11.4477 2.44772 11 3 11H9Z" fill="black"/><path d="M20 6H14V8H20V6Z" fill="black"/></svg>`
                                },
                                {
                                    id: 11,
                                    name: "Melhores cartões sem anuidade",
                                    slug: "melhores-cartoes-sem-anuidade",
                                    url: "/cartao-de-credito/sem-anuidade",
                                    icon: `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 4a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V7a3 3 0 00-3-3H6zM5 19V8h14v11a1 1 0 01-1 1H6a1 1 0 01-1-1z" fill="#000"/><path d="M10 10.667A.943.943 0 108.666 12L14 17.333A.943.943 0 1015.333 16L10 10.667z" fill="#000"/><path d="M14 10.667A.943.943 0 0115.334 12L10 17.333A.943.943 0 118.667 16L14 10.667z" fill="#000"/><rect x="7" y="2" width="2" height="3" rx="1" fill="#000"/><rect x="11" y="2" width="2" height="3" rx="1" fill="#000"/><rect x="15" y="2" width="2" height="3" rx="1" fill="#000"/></svg>`
                                },
                                {
                                    id: 12,
                                    name:
                                        "Melhores para acumular milhas aéreas",
                                    slug:
                                        "melhores-para-acumular-milhas-aereas",
                                    url: "/cartao-de-credito/milhas",
                                    icon: `<svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00955 0.127563H10.0096C11.1141 0.127563 12.4679 0.896782 13.0333 1.84566L15.5088 5.99996H20.0096C20.5618 5.99996 21.0096 6.44767 21.0096 6.99996C21.0096 7.55224 20.5618 7.99996 20.0096 7.99996H15.4137L12.9382 12.1543C12.3728 13.1031 11.019 13.8724 9.91441 13.8724H7.91441L11.4137 7.99996H4.42473L2.99023 10.4528H0.990234L3.00955 6.99996L3.00955 6.96693L1.00955 3.54708H3.00955L4.44405 5.99996H11.5088L8.00955 0.127563Z" fill="black"/></svg>`
                                },
                                {
                                    id: 13,
                                    name: "Melhores para cashback",
                                    slug: "melhores-para-cashback",
                                    url: "/cartao-de-credito/cashback",
                                    icon: `<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 17V20H7V17H8C10.2091 17 12 15.2091 12 13C12 10.7909 10.2091 9 8 9H7V5H9V7H11V3H7V0H5V3H4C1.79086 3 0 4.79086 0 7C0 9.20914 1.79086 11 4 11H5V15H3V13H1V17H5ZM7 15H8C9.10457 15 10 14.1046 10 13C10 11.8954 9.10457 11 8 11H7V15ZM5 9V5H4C2.89543 5 2 5.89543 2 7C2 8.10457 2.89543 9 4 9H5Z" fill="black"/></svg>`
                                },
                                {
                                    id: 14,
                                    name:
                                        "Melhores descontos em lojas parceiras",
                                    slug:
                                        "melhores-descontos-em-lojas-parceiras",
                                    url: "/cartao-de-credito/lojas-parceiras",
                                    icon: `<svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 3V11C2 11.5523 2.44772 12 3 12H16.6202C16.9121 12 17.1895 11.8724 17.3795 11.6508L20.808 7.65079C21.129 7.2763 21.129 6.7237 20.808 6.34921L17.3795 2.34921C17.1895 2.12756 16.9121 2 16.6202 2H3C2.44772 2 2 2.44772 2 3ZM0 3V11C0 12.6569 1.34315 14 3 14H16.6202C17.496 14 18.328 13.6173 18.898 12.9524L22.3265 8.95237C23.2895 7.8289 23.2895 6.1711 22.3265 5.04763L18.898 1.04763C18.328 0.382688 17.496 0 16.6202 0H3C1.34315 0 0 1.34315 0 3Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6C14.4477 6 14 6.44772 14 7C14 7.55228 14.4477 8 15 8ZM15 10C16.6569 10 18 8.65685 18 7C18 5.34315 16.6569 4 15 4C13.3431 4 12 5.34315 12 7C12 8.65685 13.3431 10 15 10Z" fill="black"/></svg>`
                                }
                            ]
                        },
                        {
                            id: 7,
                            name: "Receba uma recomendação",
                            slug: "receba-uma-recomendacao",
                            url: "/cartao-de-credito/recomendacao"
                        },
                        {
                            id: 8,
                            name: "Compare cartões",
                            slug: "compare-cartoes",
                            url: "/cartao-de-credito/comparar"
                        }
                    ]
                },
                {
                    id: 2,
                    name: "Contas Digitais",
                    slug: "contas-digitais",
                    url: "/contas-digitais/recomendacao/perguntas",
                    subItems: []
                },
                {
                    id: 4,
                    name: "Empréstimos",
                    slug: "emprestimos",
                    url: "/news/emprestimos"
                },
                {
                    id: 5,
                    name: "Plusdin News",
                    slug: "plusdin-news",
                    url: "/news",
                }
            ]
        },
        ranks: {},
        rankCategories: [
            {
                slug: "negativados",
                key: "negativados",
                icon: "/assets/v2/icons/credit-card/icon-negativados.svg",
                title: "Melhores para negativados",
                box_title: "Melhores para negativados",
                cards: []
            },
            {
                slug: "sem-anuidade",
                key: "sem_anuidade",
                icon: "/assets/v2/icons/credit-card/icon-free-fee.svg",
                title: "Melhores cartões sem anuidade",
                box_title: "Melhores cartões sem anuidade",
                cards: []
            },
            {
                slug: "milhas",
                key: "milhas",
                icon: "/assets/v2/icons/credit-card/icon-milhas1.svg",
                title: "Melhores para acumular milhas aéreas",
                box_title: "Melhores para acumular milhas aéreas",
                cards: []
            },
            {
                slug: "cashback",
                key: "cashback",
                icon: "/assets/v2/icons/credit-card/icon-cashback.svg",
                title: "Melhores para cashback",
                box_title: "Melhores para cashback",
                cards: []
            },
            {
                slug: "lojas-parceiras",
                key: "lojas_parceiras",
                icon: "/assets/v2/icons/credit-card/icon-desconto.svg",
                title: "Melhores descontos em lojas parceiras",
                box_title: "Melhores descontos em lojas parceiras",
                cards: []
            }
        ],
        card: {},
        cardsCategories: [
            {
                id: "national",
                name: "Nacional"
            },
            {
                id: "international",
                name: "Internacional"
            },
            {
                id: "gold",
                name: "Gold"
            },
            {
                id: "platinum",
                name: "Platinum"
            },
            {
                id: "black",
                name: "Black"
            },
            {
                id: "infinite",
                name: "Infinite"
            },
            {
                id: "co-branded",
                name: "Co-branded"
            },
            {
                id: "affinity",
                name: "Afinidade"
            },
            {
                id: "academic",
                name: "Universitário"
            },
            {
                id: "prepaid",
                name: "Pré-pago"
            },
            {
                id: "virtual",
                name: "Virtual"
            },
            {
                id: "none",
                name: "Nenhuma das anteriores"
            }
        ],
        contact: {
            email: "",
            first_name: "",
            has_accepted_terms: false
        },
        current: {},
        currentIndex: null,
        lead: {},
        categories: [],
        post: {},
        news: [],
        newsCategoryActive: {},
        cardRatings: [],
        comments: [],
        flow: "short",
        flows: {
            short: [
                {
                    question: "employment_bond",
                    next: "income_value"
                },
                {
                    question: "income_value",
                    next: "credit_card_expenses"
                },
                {
                    question: "credit_card_expenses",
                    next: {
                        benefits_low_income: {
                            question: "income_value",
                            operator: "is-any",
                            value: [1100, 3300]
                        },
                        benefits_average_income: {
                            question: "income_value",
                            operator: "is",
                            value: 5500
                        },
                        benefits: {
                            question: "income_value",
                            operator: "is-any",
                            value: [10000, 15000, 30000]
                        }
                    }
                },
                {
                    question: "benefits",
                    next: {
                        best_for_habits: {
                            question: "benefits",
                            operator: "is",
                            value: "travel-miles"
                        },
                        credit_status: {
                            question: "benefits",
                            operator: "is",
                            value: "cashback"
                        },
                        more_benefits: {
                            question: "benefits",
                            operator: "is",
                            value: "no-annual-fee"
                        },
                        purchase_on_internet: {
                            question: "benefits",
                            operator: "is",
                            value: "discounts"
                        }
                    }
                },
                {
                    question: "benefits_average_income",
                    next: {
                        spend_points: {
                            question: "benefits_average_income",
                            operator: "is",
                            value: "travel-miles"
                        },
                        credit_status: {
                            question: "benefits_average_income",
                            operator: "is-any",
                            value: ["cashback", "entertainment"]
                        },
                        more_benefits_average_income: {
                            question: "benefits_average_income",
                            operator: "is",
                            value: "no-annual-fee"
                        },
                        stores: {
                            question: "benefits_average_income",
                            operator: "is",
                            value: "discounts"
                        }
                    }
                },
                {
                    question: "benefits_low_income",
                    next: {
                        credit_status: {
                            question: "benefits_low_income",
                            operator: "is",
                            value: "cashback"
                        },
                        more_benefits_low_income: {
                            question: "benefits_low_income",
                            operator: "is",
                            value: "no-annual-fee"
                        },
                        purchase_on_internet: {
                            question: "benefits_low_income",
                            operator: "is-any",
                            value: ["discounts", "points-and-advantages"]
                        },
                    }
                },
                {
                    question: "more_benefits",
                    next: {
                        best_for_habits: {
                            question: "more_benefits",
                            operator: "is",
                            value: "travel-miles"
                        },
                        credit_status: {
                            question: "more_benefits",
                            operator: "is",
                            value: "cashback"
                        },
                        purchase_on_internet: {
                            question: "more_benefits",
                            operator: "is",
                            value: "discounts"
                        }
                    }
                },
                {
                    question: "more_benefits_average_income",
                    next: {
                        spend_points: {
                            question: "more_benefits_average_income",
                            operator: "is",
                            value: "travel-miles"
                        },
                        credit_status: {
                            question: "more_benefits_average_income",
                            operator: "is-any",
                            value: ["cashback", "entertainment"]
                        },
                        stores: {
                            question: "more_benefits_average_income",
                            operator: "is",
                            value: "discounts"
                        }
                    }
                },
                {
                    question: "more_benefits_low_income",
                    next: {
                        credit_status: {
                            question: "more_benefits_low_income",
                            operator: "is",
                            value: "cashback"
                        },
                        purchase_on_internet: {
                            question: "more_benefits_low_income",
                            operator: "is-any",
                            value: ["discounts", "points-and-advantages"]
                        }
                    }
                },
                {
                    question: "spend_points",
                    next: {
                        credit_status: {
                            question: "spend_points",
                            operator: "is",
                            value: "products_and_services"
                        },
                        airline: {
                            question: "spend_points",
                            operator: "is",
                            value: "miles"
                        },
                        stores: {
                            question: "spend_points",
                            operator: "is",
                            value: "discounts"
                        },
                    }
                },
                {
                    question: "best_for_habits",
                    next: "airline"
                },
                {
                    question: "airline",
                    next: {
                        airline_benefits: {
                            question: "income_value",
                            operator: "is",
                            value: [1100, 3300, 10000, 15000, 30000]
                        },
                        credit_status: {
                            question: "income_value",
                            operator: "is",
                            value: 5500
                        }
                    }
                },
                {
                    question: "airline_benefits",
                    next: "credit_status"
                },
                {
                    question: "purchase_on_internet",
                    next: {
                        stores: {
                            question: "purchase_on_internet",
                            operator: "is",
                            value: "yes"
                        },
                        credit_status: {
                            question: "purchase_on_internet",
                            operator: "is",
                            value: "no"
                        }
                    }
                },
                {
                    question: "stores",
                    next: "credit_status"
                },
                {
                    question: "credit_status",
                    next: "result"
                }
            ],
            long: [
                {
                    question: "is_international",
                    next: "employment_bond"
                },
                {
                    question: "employment_bond",
                    next: "bank_accounts"
                },
                {
                    question: "bank_accounts", // bancos que possui conta
                    next: "credit_and_debit"
                },
                {
                    question: "credit_and_debit", // cartão de crédito e débito
                    next: "withdraw_with_credit_card"
                },
                {
                    question: "withdraw_with_credit_card", // saques em dinheiro com cartão
                    next: {
                        can_withdraw_in_24h_bank: {
                            // saque em banco 24 horas
                            question: "withdraw_with_credit_card",
                            operator: "is",
                            value: "yes"
                        },
                        accept_google_apple_pay: {
                            // pagamento via Android/Apple Pay
                            question: "withdraw_with_credit_card",
                            operator: "is",
                            value: "whatever"
                        }
                    }
                },
                {
                    question: "can_withdraw_in_24h_bank", // saque em banco 24 horas
                    next: "accept_google_apple_pay"
                },
                {
                    question: "accept_google_apple_pay", // pagamento via Android/Apple Pay
                    next: "credit_status"
                },
                {
                    question: "credit_status",
                    next: "income_value"
                },
                {
                    question: "income_value",
                    next: "has_credit_card"
                },
                {
                    question: "has_credit_card", // possui cartão de crédito
                    next: {
                        credit_card_expenses: {
                            // gasto mensal cartão crédito
                            question: "has_credit_card",
                            operator: "is",
                            value: true
                        },
                        bank_branch_near: {
                            // agência próxima de você
                            question: "has_credit_card",
                            operator: "is",
                            value: false
                        }
                    }
                },
                {
                    question: "credit_card_expenses",
                    next: "bank_branch_near"
                },
                {
                    question: "bank_branch_near", // agência próxima de você
                    next: {
                        cep: {
                            // cep
                            question: "bank_branch_near",
                            operator: "is",
                            value: "yes"
                        },
                        benefits: {
                            // benefícios
                            question: "bank_branch_near",
                            operator: "is",
                            value: "whatever"
                        }
                    }
                },
                {
                    question: "cep",
                    next: "benefits"
                },
                {
                    question: "benefits",
                    next: {
                        more_benefits: {
                            question: "benefits",
                            operator: "is",
                            value: "no-annual-fee"
                        },
                        free_fee_recheck: {
                            question: "benefits",
                            operator: "is-any",
                            value: ["travel-miles", "cashback", "discounts"]
                        }
                    }
                },
                {
                    question: "more_benefits",
                    next: "free_fee_recheck"
                },
                {
                    question: "free_fee_recheck",
                    next: {
                        common_travel: {
                            question: ["benefits", "more_benefits"],
                            operator: "is",
                            value: "travel-miles"
                        },
                        purchase_on_internet: {
                            question: ["benefits", "more_benefits"],
                            operator: "is",
                            value: "cashback"
                        },
                        stores: {
                            question: ["benefits", "more_benefits"],
                            operator: "is",
                            value: "discounts"
                        }
                    }
                },
                {
                    question: "common_travel",
                    next: "best_for_habits"
                },
                {
                    question: "best_for_habits",
                    next: "airline"
                },
                {
                    question: "airline",
                    next: {
                        others_airlines: {
                            question: "airline",
                            operator: "is-any",
                            value: ["azul", "gol", "latam"]
                        },
                        airline_benefits: {
                            question: "airline",
                            operator: "is",
                            value: "none"
                        }
                    }
                },
                {
                    question: "others_airlines",
                    next: "airline_benefits",
                    exclude_options_from: "airline"
                },
                {
                    question: "airline_benefits",
                    next: "others_airline_benefits"
                },
                {
                    question: "others_airline_benefits",
                    next: "flight_purpose",
                    exclude_options_from: "airline_benefits"
                },
                {
                    question: "flight_purpose",
                    next: "result" // final 1
                },
                {
                    question: "purchase_on_internet",
                    next: "result" // final 2
                },
                {
                    question: "stores",
                    next: "result" // final 3
                }
            ]
        },
        questionsHistory: [],
        questionsV2: [
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "flow",
                slug: "fluxo",
                title:
                    "Antes de começar, conte pra gente: como está o seu tempo hoje?",
                options: [
                    {
                        image: "icon-ampuleta-pouco-tempo.svg",
                        image_checked: "icon-ampuleta-pouco-tempo-ativa.svg",
                        text:
                            "Estou com pressa para receber uma boa recomendação",
                        value: "short"
                    },
                    {
                        image: "icon-ampuleta-muito-tempo.svg",
                        image_checked: "icon-ampuleta-muito-tempo-ativa.svg",
                        text:
                            "Estou com tempo para receber a melhor recomendação",
                        value: "long"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "is_international",
                slug: "internacional",
                title: "Você precisa que seu cartão seja <b>internacional?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: true
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não",
                        value: false
                    }
                ]
            },
            {
                type: "single",
                style: "small",
                layout: "vertical",
                key: "employment_bond",
                slug: "grupo",
                title: "Em qual <b>grupo</b> você mais se encaixa?",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        text: "Empregado",
                        value: "employee"
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Desempregado",
                        value: "unemployed"
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Universitário",
                        value: "university"
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Empresário / Autônomo",
                        value: "entrepreneur-freelancer"
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Servidor Público",
                        value: "government-employee"
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Aposentado / Pensionista",
                        value: "retired-pensioner"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "credit_status",
                slug: "negativado",
                title: "E por último... Você está <b>negativado?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "in-debit"
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não",
                        value: "pristiny"
                    }
                ]
            },
            {
                type: "single",
                style: "small",
                layout: "vertical",
                key: "income_value",
                slug: "renda",
                title: "Qual a sua <b>renda mensal?</b>",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        text: "Até R$ 1.100",
                        value: 1100
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Entre R$ 1.100 e R$ 3.300",
                        value: 3300
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Entre R$ 3.300 e R$ 5.500",
                        value: 5500
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Entre R$ 5.500 e R$ 10.000",
                        value: 10000
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Entre R$ 10.000 e R$ 15.000",
                        value: 15000
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Acima R$ 15.000",
                        value: 30000
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "benefits",
                slug: "beneficios",
                title:
                    "O que é mais <b>importante</b> para você em um cartão de crédito?",
                options: [
                    {
                        image: "icon-anuidade-gratis.svg",
                        image_checked: "icon-anuidade-gratis-ativa.svg",
                        text: "Anuidade grátis",
                        value: "no-annual-fee"
                    },
                    {
                        image: "icon-milhas-pontos-vantagens.svg",
                        image_checked: "icon-milhas-pontos-vantagens-ativa.svg",
                        text: "Acumular milhas, pontos e vantagens",
                        value: "travel-miles"
                    },
                    {
                        image: "icon-recuperar-gastos.svg",
                        image_checked: "icon-recuperar-gastos-ativa.svg",
                        text: "Recuperar parte dos meus gastos (cashback)",
                        value: "cashback"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text: "Desconto em lojas parceiras",
                        value: "discounts"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "benefits_average_income",
                slug: "beneficios-media-renda",
                title:
                    "O que é mais <b>importante</b> para você em um cartão de crédito?",
                options: [
                    {
                        image: "icon-anuidade-gratis.svg",
                        image_checked: "icon-anuidade-gratis-ativa.svg",
                        text: "Anuidade grátis",
                        value: "no-annual-fee"
                    },
                    {
                        image: "icon-milhas-pontos-vantagens.svg",
                        image_checked: "icon-milhas-pontos-vantagens-ativa.svg",
                        text: "Acumular milhas, pontos e vantagens",
                        value: "travel-miles"
                    },
                    {
                        image: "icon-recuperar-gastos.svg",
                        image_checked: "icon-recuperar-gastos-ativa.svg",
                        text: "Recuperar parte dos meus gastos (cashback)",
                        value: "cashback"
                    },
                    {
                        image: "icon-entretenimento.svg",
                        image_checked: "icon-entretenimento-ativa.svg",
                        text: "Entretenimento (Cinemas, teatros e shows)",
                        value: "entertainment"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text: "Desconto em lojas parceiras",
                        value: "discounts"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "benefits_low_income",
                slug: "beneficios-baixa-renda",
                title:
                    "O que é mais <b>importante</b> para você em um cartão de crédito?",
                options: [
                    {
                        image: "icon-anuidade-gratis.svg",
                        image_checked: "icon-anuidade-gratis-ativa.svg",
                        text: "Anuidade grátis",
                        value: "no-annual-fee"
                    },
                    {
                        image: "icon-pontos-vantagens.svg",
                        image_checked: "icon-pontos-vantagens-ativa.svg",
                        text: "Acumular pontos e vantagens",
                        value: "points-and-advantages"
                    },
                    {
                        image: "icon-recuperar-gastos.svg",
                        image_checked: "icon-recuperar-gastos-ativa.svg",
                        text: "Recuperar parte dos meus gastos (cashback)",
                        value: "cashback"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text: "Desconto em lojas parceiras",
                        value: "discounts"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "more_benefits",
                slug: "mais-beneficios",
                title:
                    "Mas e se você pudesse escolher mais uma <b>vantagem</b>, qual seria?",
                options: [
                    {
                        image: "icon-milhas-pontos-vantagens.svg",
                        image_checked: "icon-milhas-pontos-vantagens-ativa.svg",
                        text:
                            "Acumular milhas, pontos e vantagens para viagens",
                        value: "travel-miles"
                    },
                    {
                        image: "icon-recuperar-gastos.svg",
                        image_checked: "icon-recuperar-gastos-ativa.svg",
                        text: "Recuperar parte dos meus gastos (cashback)",
                        value: "cashback"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text: "Desconto em lojas parceiras",
                        value: "discounts"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "more_benefits_average_income",
                slug: "mais-beneficios-media-renda",
                title:
                    "Mas e se você pudesse escolher uma <b>vantagem</b>, qual seria?",
                options: [
                    {
                        image: "icon-milhas-pontos-vantagens.svg",
                        image_checked: "icon-milhas-pontos-vantagens-ativa.svg",
                        text:
                            "Acumular milhas, pontos e vantagens",
                        value: "travel-miles"
                    },
                    {
                        image: "icon-recuperar-gastos.svg",
                        image_checked: "icon-recuperar-gastos-ativa.svg",
                        text: "Recuperar parte dos meus gastos (cashback)",
                        value: "cashback"
                    },
                    {
                        image: "icon-entretenimento.svg",
                        image_checked: "icon-entretenimento-ativa.svg",
                        text: "Entretenimento (Cinemas, teatros e espetáculos)",
                        value: "entertainment"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text: "Desconto em lojas parceiras",
                        value: "discounts"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "more_benefits_low_income",
                slug: "mais-beneficios-baixa-renda",
                title:
                    "Mas e se você pudesse escolher mais uma <b>vantagem</b>, qual seria?",
                options: [
                    {
                        image: "icon-pontos-vantagens.svg",
                        image_checked: "icon-pontos-vantagens-ativa.svg",
                        text:
                            "Acumular pontos e vantagens",
                        value: "points-and-advantages"
                    },
                    {
                        image: "icon-recuperar-gastos.svg",
                        image_checked: "icon-recuperar-gastos-ativa.svg",
                        text: "Recuperar parte dos meus gastos (cashback)",
                        value: "cashback"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text: "Desconto em lojas parceiras",
                        value: "discounts"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "free_fee_recheck",
                slug: "anuidade",
                title:
                    "Você aceitaria adquirir um cartão que pode <b>cobrar anuidade?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "yes"
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não",
                        value: "no"
                    },
                    {
                        image: "",
                        image_checked: "",
                        text:
                            "Sim, desde que eu tenha desconto parcial ou total na anuidade se fizer um gasto mínimo no cartão.",
                        value: "yes-if-discounts"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "best_for_habits",
                slug: "vantagens-linhas-aereas",
                title: "O que é melhor para os <b>seus hábitos?</b>",
                options: [
                    {
                        image: "icon-descontos-cias-aereas.svg",
                        image_checked: "icon-descontos-cias-aereas-ativa.svg",
                        text:
                            "Vantagens e descontos em linhas aéreas diferentes",
                        value: "multiple-airlines-benefits"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text:
                            "Vantagens e descontos maiores em uma linha aérea específica",
                        value: "single-airline-bigger-benefits"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "airline",
                slug: "linha-aerea",
                title: "Qual <b>linha aérea</b> você mais usa?",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        text: "Não tenho preferência",
                        value: "none"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "airline_benefits",
                slug: "melhor-beneficio",
                title: "Qual desses <b>benefícios</b> mais te agrada?",
                options: [
                    {
                        image: "icon-sala-vip.svg",
                        image_checked: "icon-sala-vip-ativa.svg",
                        text: "Acesso a salas VIP em aeroportos",
                        value: "access-vip-lounges"
                    },
                    {
                        image: "icon-desconto-passagens.svg",
                        image_checked: "icon-desconto-passagens-ativa.svg",
                        text: "Descontos em passagens",
                        value: "tickets-discounts"
                    },
                    {
                        image: "icon-embarque-preferencial.svg",
                        image_checked: "icon-embarque-preferencial-ativa.svg",
                        text: "Embarque preferencial",
                        value: "preferential-boarding"
                    },
                    {
                        image: "icon-parcelamento-mais-longo.svg",
                        image_checked: "icon-parcelamento-mais-longo-ativa.svg",
                        text: "Parcelamento mais longo de passagens",
                        value: "longer-tickets-installment"
                    },
                    {
                        image: "icon-seguro-viagem.svg",
                        image_checked: "icon-seguro-viagem-ativa.svg",
                        text: "Seguro viagem grátis",
                        value: "free-travel-insurance"
                    }
                ]
            },
            {
                type: "single",
                style: "small",
                layout: "vertical",
                key: "credit_card_expenses",
                slug: "gasto-cartao-credito",
                title:
                    "Qual é a sua <b>previsão de gasto mensal</b> no cartão de crédito?",
                options: [
                    {
                        text: "Até R$ 1.000,00",
                        value: 1000,
                        icon: null
                    },
                    {
                        text: "Entre R$ 1.000,00 a R$ 3.000,00",
                        value: 3000,
                        icon: null
                    },
                    {
                        text: "Entre R$ 3.000,00 a R$ 5.000,00",
                        value: 5000,
                        icon: null
                    },
                    {
                        text: "Entre R$ 5.000,00 a R$ 8.000,00",
                        value: 8000,
                        icon: null
                    },
                    {
                        text: "Entre R$ 8.000,00 a R$ 10.000,00",
                        value: 10000,
                        icon: null
                    },
                    {
                        text: "Acima de R$ 10.000,00",
                        value: 30000,
                        icon: null
                    }
                ],
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "purchase_on_internet",
                slug: "compras-internet",
                title:
                    "Você costuma fazer <b>compras pela internet</b> (e-commerces)?",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "yes"
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não",
                        value: "no"
                    }
                ]
            },
            {
                type: "multiple",
                style: "default",
                layout: "horizontal",
                key: "stores",
                slug: "lojas",
                title:
                    "Selecione as <b>lojas</b> onde você costuma fazer compras",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        text: "Nenhuma dessas",
                        value: "none"
                    }
                ],
                meta: {
                    limit_images_sizes: true,
                    track_single_options: true
                }
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "spend_points",
                slug: "gastar-pontos",
                title:
                    "Como você gostaria de <b>utilizar os pontos?</b>",
                options: [
                    {
                        image: "icon-milhas-aereas.svg",
                        image_checked: "icon-milhas-aereas-ativa.svg",
                        text: "Acumular milhas para viagens",
                        value: "miles"
                    },
                    {
                        image: "icon-troca-por-produtos-e-servicos.svg",
                        image_checked: "icon-troca-por-produtos-e-servicos-ativa.svg",
                        text: "Trocar por produtos e serviços",
                        value: "products_and_services"
                    },
                    {
                        image: "icon-desconto-lojas.svg",
                        image_checked: "icon-desconto-lojas-ativa.svg",
                        text: "Descontos em lojas parceiras",
                        value: "discounts"
                    }
                ]
            },
            {
                type: "multiple",
                style: "default",
                layout: "horizontal",
                key: "bank_accounts",
                slug: "contas-em-banco",
                title: "Marque o(s) banco(s) onde você <b>possui conta</b>",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        text: "Nenhum desses",
                        value: "none"
                    }
                ],
                meta: {
                    limit_images_sizes: true,
                    track_single_options: true
                }
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "credit_and_debit",
                slug: "cartao-credito-debito",
                title:
                    "Você quer que seu cartão ofereça funções tanto de <b>crédito</b> quanto de <b>débito?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "yes"
                    },
                    {
                        image: "icon-tanto-faz.svg",
                        image_checked: "icon-tanto-faz-ativa.svg",
                        text: "Tanto faz",
                        value: "whatever"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "withdraw_with_credit_card",
                slug: "saque-dinheiro",
                title:
                    "Você gostaria de fazer <b>saques em dinheiro</b> com seu cartão de crédito?",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "yes"
                    },
                    {
                        image: "icon-tanto-faz.svg",
                        image_checked: "icon-tanto-faz-ativa.svg",
                        text: "Tanto faz",
                        value: "whatever"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "can_withdraw_in_24h_bank",
                slug: "caixa-banco-24-horas",
                title:
                    "Para os saques em dinheiro, é importante que você possa fazê-los em <b>caixas do Banco 24 horas?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "yes"
                    },
                    {
                        image: "icon-tanto-faz.svg",
                        image_checked: "icon-tanto-faz-ativa.svg",
                        text: "Tanto faz",
                        value: "whatever"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "accept_google_apple_pay",
                slug: "pagamento-via-aplicativo",
                title:
                    "Você gostaria que seu cartão oferecesse o pagamento via <b>Android Pay</b> ou <b>Apple Pay?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "yes"
                    },
                    {
                        image: "icon-tanto-faz.svg",
                        image_checked: "icon-tanto-faz-ativa.svg",
                        text: "Tanto faz",
                        value: "whatever"
                    }
                ]
            },
            {
                type: "boolean",
                style: "large",
                layout: "horizontal",
                key: "has_credit_card",
                slug: "possui-cartao-credito",
                title: "Você já possui algum <b>cartão de crédito?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: true
                    },
                    {
                        image: "icon-nao.svg",
                        image_checked: "icon-nao-ativa.svg",
                        text: "Não",
                        value: false
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "bank_branch_near",
                slug: "agencia-nas-proximidades",
                title:
                    "É importante que o banco do seu cartão tenha uma <b>agência próxima a você?</b>",
                options: [
                    {
                        image: "icon-sim.svg",
                        image_checked: "icon-sim-ativa.svg",
                        text: "Sim",
                        value: "yes"
                    },
                    {
                        image: "icon-tanto-faz.svg",
                        image_checked: "icon-tanto-faz-ativa.svg",
                        text: "Tanto faz",
                        value: "whatever"
                    }
                ]
            },
            {
                type: "form",
                style: "default",
                layout: "vertical",
                key: "cep",
                slug: "cep",
                title: "Qual é o seu <b>CEP?</b>",
                by_pass_option: "Pular esta pergunta",
                options: [
                    {
                        image: "",
                        image_checked: "",
                        text: "",
                        value: ""
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "common_travel",
                slug: "tipo-viagem-mais-comum",
                title: "Qual seu tipo de <b>viagem mais comum?</b>",
                options: [
                    {
                        image: "icon-nacional.svg",
                        image_checked: "icon-nacional-ativa.svg",
                        text: "Nacional",
                        value: "national"
                    },
                    {
                        image: "icon-internacional.svg",
                        image_checked: "icon-internacional-ativa.svg",
                        text: "Internacional",
                        value: "international"
                    },
                    {
                        image: "icon-ambos.svg",
                        image_checked: "icon-ambos-ativa.svg",
                        text: "Ambos",
                        value: "both"
                    }
                ]
            },
            {
                type: "multiple",
                style: "large",
                layout: "horizontal",
                key: "others_airlines",
                slug: "outra-linha-aerea",
                title:
                    "Você usa mais alguma <b>companhia aérea</b> com frequência?<small><i>(Marque quantos quiser)</i><small>",
                subtitle: "",
                options: [
                    {
                        image: "logo-azul.png",
                        image_checked: "",
                        text: "",
                        value: "azul"
                    },
                    {
                        image: "logo-gol.png",
                        image_checked: "",
                        text: "",
                        value: "gol"
                    },
                    {
                        image: "logo-latam.png",
                        image_checked: "",
                        text: "",
                        value: "latam"
                    },
                    {
                        image: "",
                        image_checked: "",
                        text: "Nenhuma dessas",
                        value: "none"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "others_airline_benefits",
                slug: "outro-melhor-beneficio",
                title:
                    "Ok, mas e se você pudesse ter mais um desses <b>benefícios</b>, qual seria?",
                options: [
                    {
                        image: "icon-sala-vip.svg",
                        image_checked: "icon-sala-vip-ativa.svg",
                        text: "Acesso a salas VIP em aeroportos",
                        value: "access-vip-lounges"
                    },
                    {
                        image: "icon-desconto-passagens.svg",
                        image_checked: "icon-desconto-passagens-ativa.svg",
                        text: "Descontos em passagens",
                        value: "tickets-discounts"
                    },
                    {
                        image: "icon-embarque-preferencial.svg",
                        image_checked: "icon-embarque-preferencial-ativa.svg",
                        text: "Embarque preferencial",
                        value: "preferential-boarding"
                    },
                    {
                        image: "icon-parcelamento-mais-longo.svg",
                        image_checked: "icon-parcelamento-mais-longo-ativa.svg",
                        text: "Parcelamento mais longo de passagens",
                        value: "longer-tickets-installment"
                    },
                    {
                        image: "icon-seguro-viagem.svg",
                        image_checked: "icon-seguro-viagem-ativa.svg",
                        text: "Seguro viagem grátis",
                        value: "free-travel-insurance"
                    }
                ]
            },
            {
                type: "single",
                style: "large",
                layout: "horizontal",
                key: "flight_purpose",
                slug: "tipo-viagem",
                title: "Você voa mais a <b>lazer</b> ou a <b>trabalho?</b>",
                options: [
                    {
                        image: "icon-lazer.svg",
                        image_checked: "icon-lazer-ativa.svg",
                        text: "A lazer",
                        value: "leisure"
                    },
                    {
                        image: "icon-trabalho.svg",
                        image_checked: "icon-trabalho-ativa.svg",
                        text: "A trabalho",
                        value: "work"
                    },
                    {
                        image: "icon-equilibrado.svg",
                        image_checked: "icon-equilibrado-ativa.svg",
                        text: "Equilibrado",
                        value: "balanced"
                    }
                ]
            }
        ],
        questions: [
            {
                slug: "beneficios",
                type: "multiple",
                key: "benefits",
                question: "Quais benefícios mais te interessam?",
                help: "",
                options: [
                    {
                        text: "Anuidade grátis",
                        value: "no-annual-fee",
                        icon: "/assets/v2/icons/quiz/options/calendar.svg",
                        icon_active:
                            "/assets/v2/icons/quiz/options/calendar-active.svg"
                    },
                    {
                        text: "Recuperar parte dos meus gastos _(cashback)_",
                        value: "cashback",
                        icon: "/assets/v2/icons/quiz/options/repeat.svg",
                        icon_active:
                            "/assets/v2/icons/quiz/options/repeat-active.svg"
                    },
                    {
                        text: "Acumular milhas aéreas",
                        value: "travel-miles",
                        icon: "/assets/v2/icons/quiz/options/departure.svg",
                        icon_active:
                            "/assets/v2/icons/quiz/options/departure-active.svg"
                    },
                    {
                        text: "Um bom programa de pontos",
                        value: "rewards",
                        icon: "/assets/v2/icons/quiz/options/award.svg",
                        icon_active:
                            "/assets/v2/icons/quiz/options/award-active.svg"
                    },
                    {
                        text: "Desconto em lojas parceiras",
                        value: "discounts",
                        icon: "/assets/v2/icons/quiz/options/dollar.svg",
                        icon_active:
                            "/assets/v2/icons/quiz/options/dollar-active.svg"
                    },
                    {
                        text: "Acesso a salas VIP em aeroportos",
                        value: "travel-lounges",
                        icon: "/assets/v2/icons/quiz/options/star.svg",
                        icon_active:
                            "/assets/v2/icons/quiz/options/star-active.svg"
                    }
                ],
                invalid_button_text: "Continuar"
            },
            {
                slug: "renda",
                type: "single",
                key: "income",
                question: "Qual sua renda atual?",
                help: "",
                options: [
                    {
                        text: "Até R$ 1.000,00",
                        value: "up-to-1000",
                        icon: null
                    },
                    {
                        text: "Entre R$ 1.000,00 e R$ 2.500,00",
                        value: "up-to-2500",
                        icon: null
                    },
                    {
                        text: "Entre R$ 2.500,00 e R$ 5.000,00",
                        value: "up-to-5000",
                        icon: null
                    },
                    {
                        text: "Mais de R$ 5.000,00",
                        value: "over-5000",
                        icon: null
                    }
                ],
                invalid_button_text: ""
            },
            {
                slug: "negativado",
                type: "single",
                key: "credit_status",
                question: "Você está negativado?",
                subQuestion:
                    "“negativados” são pessoas listadas com dívidas em órgãos como SERASA ou SPC",
                help: "",
                options: [
                    {
                        text: "Não",
                        value: "pristiny",
                        icon: null
                    },
                    {
                        text: "Sim",
                        value: "in-debit",
                        icon: null
                    }
                ],
                invalid_button_text: ""
            },
            {
                slug: "internacional",
                type: "boolean",
                key: "is_international",
                question: "Você precisa que seu cartão seja internacional?",
                help: "",
                options: [
                    {
                        text: "Não",
                        value: false,
                        icon: ""
                    },
                    {
                        text: "Sim",
                        value: true,
                        icon: ""
                    }
                ],
                invalid_button_text: ""
            },
            {
                slug: "aposentado",
                type: "single",
                key: "employment_bond",
                question: "Em qual grupo você mais se encaixa?",
                help: "",
                options: [
                    {
                        text: "Aposentado / Pensionista",
                        value: "retired-pensioner",
                        icon: ""
                    },
                    {
                        text: "Servidor Público",
                        value: "government-employee",
                        icon: ""
                    },
                    {
                        text: "Empregado",
                        value: "employee",
                        icon: ""
                    },
                    {
                        text: "Desempregado",
                        value: "unemployed",
                        icon: ""
                    },
                    {
                        text: "Empresário / autônomo",
                        value: "entrepreneur-freelancer",
                        icon: ""
                    },
                    {
                        text: "Universitário",
                        value: "university",
                        icon: ""
                    }
                ],
                invalid_button_text: ""
            }
        ],
        response: {},
        lastQuizResponse: {},
        filterListCards: {},
        rankCardActive: null,
        recommendation: undefined
    },

    mutations: {
        addAskedQuestion(state, payload) {
            if (!state.askedQuestions.includes(payload.key)) {
                state.askedQuestions.push(payload.key);
            }
        },

        addResponseToKey(state, payload) {
            const { key, value } = objectKeyValue(payload);

            if (!state.response[key]) {
                Vue.set(state.response, key, [value]);
                return;
            }

            state.response[key].push(value);
        },

        addResponseKey(state, payload) {
            const [key, value] = payload;

            Vue.set(state.response, key, value);
        },

        addToQuestionsHistory(state, payload) {
            if (!state.questionsHistory.includes(payload.slug)) {
                state.questionsHistory.push(payload.slug);
            }
        },

        clearAskedQuestions(state) {
            state.askedQuestions = [];
        },
        
        clearQuestionsHistory(state) {
            state.questionsHistory = [];  
        },

        removeAskedQuestion(state, payload) {
            const index = state.askedQuestions.indexOf(payload)

            if (index !== -1) {
                state.askedQuestions.splice(index, 1)
            }
        },
        
        removeQuestionHistory(state, payload) {
            const index = state.questionsHistory.indexOf(payload)

            if (index !== -1) {
                state.questionsHistory.splice(index, 1)
            }
        },

        removeResponseFromKey(state, payload) {
            const { key, value } = objectKeyValue(payload);

            state.response[key].splice(state.response[key].indexOf(value), 1);
        },

        setBanksQuestionOptions(state, payload) {
            const question = state.questionsV2.find(
                question => question.key === "bank_accounts"
            );
            const options = payload.issuers.map(issuer => ({
                image: issuer.logo_file.url,
                image_checked: "",
                show_image_only: !!issuer.logo_file.url,
                text: issuer.name,
                value: issuer.id
            }));

            question.options.splice(0, 0, ...options);
        },

        setCard(state, payload) {
            state.card = payload.card;
        },

        setContact(state, payload) {
            state.contact = payload.contact;
        },

        setCurrent(state, { slug }) {
            state.current = state.questions.find(one => one.slug === slug);
            state.currentIndex = state.questions.findIndex(
                one => one.slug === slug
            );
        },

        setCurrentQuestion(state, payload) {
            state.current = payload.current;
        },

        setCurrentQuestionIndex(state, payload) {
            state.currentIndex = payload.index;
        },

        setFlow(state, payload) {
            state.flow = payload.flow;
        },

        setLead(state, payload) {
            state.lead = payload.lead;
        },

        setResponseKey(state, payload) {
            const { key, value } = objectKeyValue(payload);

            Vue.set(state.response, key, value);
        },

        clearFlow(state) {
            state.flow = null;
        },

        clearResponseKey(state) {
            state.response = {};
        },

        setLastQuizResponse(state, payload) {
            state.lastQuizResponse = payload;
        },

        setMenu(state, payload) {
            state.menu = payload;
        },

        setPost(state, payload) {
            state.post = payload;
        },

        setCategories(state, payload) {
            state.categories = payload;
        },

        setRanks(state, payload) {
            state.ranks = payload;
        },

        setNews(state, payload) {
            state.news = payload;
        },

        setFaqs(state, payload) {
            state.faqs = payload;
        },

        setComments(state, payload) {
            state.comments = payload;
        },

        setFilterListCards(state, payload) {
            state.filterListCards = payload;
        },

        setRankCardActive(state, payload) {
            state.rankCardActive = payload;
        },

        setNewsCategoryActive(state, payload) {
            state.newsCategoryActive = payload;
        },

        setLoading(state, payload) {
            state.loading = payload;
        },

        setLoaderRanks(state, payload) {
            state.loaderRanks = payload;
        },

        setRecommendation(state, payload) {
            state.recommendation = payload;
        },

        setStoresQuestionOptions(state, payload) {
            const question = state.questionsV2.find(
                question => question.key === "stores"
            );

            const options = payload.partnersStores.map(store => ({
                image: store.logo_file.url,
                original_image: store.logo_file.original_url,
                image_checked: "",
                show_image_only: !!store.logo_file.url,
                text: store.name,
                value: store.id
            }));

            question.options.splice(0, 0, ...options);
        },

        setAirlinesQuestionOptions(state, payload) {
            const question = state.questionsV2.find(
                question => question.key === "airline"
            );
            const options = payload.airlines.map(airline => ({
                image: airline.logo_file.url,
                image_checked: "",
                original_image: airline.logo_file.original_url,
                show_image_only: !!airline.logo_file.url,
                text: airline.name,
                value: airline.id
            }));

            question.options.splice(0, 0, ...options);
        }
    },

    actions: {
        setCurrentQuestion({ commit, state }, { slug }) {
            if (slug === "inicio" || !slug) {
                slug = "grupo";
            }

            const current = state.questionsV2.find(one => one.slug === slug);
            const index = state.questionsV2.findIndex(one => one.slug === slug);

            commit("setCurrentQuestion", { current });
            commit("setCurrentQuestionIndex", { index });
            commit("addAskedQuestion", { key: current.key });
            commit("addToQuestionsHistory", { slug });
        },

        setFlow({ commit, dispatch, getters }, payload) {
            commit("setFlow", payload);

            if (!getters.partnersStoresLoaded) {
                dispatch("partnersStores/load").then(partnersStores => {
                    commit("setStoresQuestionOptions", { partnersStores })
                }
                );
            }

            if (!getters.airlinesLoaded) {
                dispatch("airlines/load").then(airlines =>
                    commit("setAirlinesQuestionOptions", { airlines })
                );
            }

            if (payload.flow === "long" && !getters.banksLoaded) {
                dispatch("issuers/load").then(issuers =>
                    commit("setBanksQuestionOptions", { issuers })
                );
            }
        },

        setResponseKey({ commit }, payload) {
            commit("addResponseKey", payload);
        },

        setLastQuizResponse({ commit }, payload) {
            if (!payload.expires_at) {
                const date = new Date(new Date().getTime() + 1800000);
                payload.expires_at = {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                    hours: date.getHours(),
                    minutes: date.getMinutes()
                };
            }
            sessionStorage.setItem("lastQuizResponse", JSON.stringify(payload));
            commit("setLastQuizResponse", payload);
        },

        setLead({ commit }, payload) {
            commit("setLead", payload);
        },

        setLoading({ commit }, payload) {
            commit("setLoading", payload);
        },

        setNewsCategoryActive({ commit }, payload) {
            commit("setNewsCategoryActive", payload);
        },

        setRankCardActive({ commit }, payload) {
            commit("setRankCardActive", payload);
        },

        setFilterListCards({ commit }, payload) {
            commit("setFilterListCards", payload);
        },

        clearAskedQuestions({ commit }) {
            commit("clearAskedQuestions");
        },

        clearQuestionsHistory({ commit }) {
            commit("clearQuestionsHistory");
        },

        clearFlow({ commit }) {
            commit("clearFlow");
        },

        clearResponseKey({ commit }) {
            commit("clearResponseKey");
        },

        loadRanks({ commit }) {
            commit("setLoaderRanks", true);
            const getRanks = () => {
                return ranks.find().then(ranks => {
                    const date = new Date();
                    date.setDate(date.getDate() + 1);
                    const object = { value: ranks, timestamp: date.getTime() };
                    try {
                        localStorage.setItem(
                            "plusdin_ranks",
                            JSON.stringify(object)
                        );
                    } catch { } // eslint-disable-line
                    commit("setRanks", ranks);
                    commit("setLoaderRanks", false);
                    return ranks;
                });
            };

            try {
                const ranks = JSON.parse(localStorage.getItem("plusdin_ranks"));
                if (
                    localStorage.getItem("plusdin_ranks") &&
                    new Date().getTime() <= ranks.timestamp
                ) {
                    commit("setRanks", ranks.value);
                    commit("setLoaderRanks", false);
                } else {
                    return getRanks();
                }
            } catch (error) {
                localStorage.removeItem("plusdin_ranks");
                getRanks();
            }
        },

        createQuestion(payload) {
            return faqs.createQuestion(payload).then(faq => {
                return faq;
            });
        },

        createAnswer(payload) {
            return faqs.createAnswer(payload).then(faq => {
                return faq;
            });
        },

        loadFaqs({ commit }, payload) {
            return faqs.byCard(payload).then(faqs => {
                commit("setFaqs", faqs);

                return faqs;
            });
        },

        loadComments({ commit }, payload) {
            return comments.bySlug(payload).then(comments => {
                commit("setComments", comments);

                return comments;
            });
        },

        saveComment({ commit }, payload) {
            return comments.create(payload).then(comments => {
                commit("setComments", comments);

                return comments;
            });
        },

        loadPost({ commit }, payload) {
            return news.detail(payload).then(post => {
                commit("setPost", post);

                return post;
            });
        },

        loadPosts({ commit }, payload) {
            return news.posts(payload).then(posts => {
                commit("setNews", posts);

                return posts;
            });
        },

        loadCategories({ commit }) {
            return news.categories().then(categories => {
                categories.forEach(category =>
                    category.posts.sort(newestSorter)
                );
                commit("setCategories", categories);

                return categories;
            });
        },

        loadMenu({ commit }) {
            return common.loadMenu().then(menu => {
                commit("setMenu", menu);
                return menu;
            });
        },

        removeAskedQuestion({ commit }, key) {
            commit("removeAskedQuestion", key)
        },

        removeQuestionHistory({ commit }, key) {
            commit("removeQuestionHistory", key)
        },

        async fetchRecommendation({ state }) {
            let retries = 0;
            let recommendation = [];

            do {
                try {
                    recommendation = await cards.recommendation(state);
                } catch (error) {
                    retries++;
                }
            } while (retries > 0 && retries < 3)

            if (recommendation.length === 0) {
                throw 'Recommender is not responding';
            }

            const recommenderVersion = recommendation[recommendation.length - 1]['recommender-version'];

            return { slug: recommendation[0].slug, version: recommenderVersion };
        },

        getFormattedDataFromCardQuiz({ state }) {
            return cards.getFormattedDataFromQuiz(state);
        },

        loadCard({ commit }, { id, slug }) {
            const request = id ? cards.find(id) : cards.bySlug(slug);
            return request.then(card => {
                commit("setCard", { card });

                return card;
            });
        },

        saveConversion({ commit, state }) {
            return leads.conversion(state.lead.id).then(lead => {
                commit("setLead", { lead });

                return lead;
            });
        },

        saveLead({ commit, state }, payload) {
            commit("setContact", payload);

            const data = {
                brand: "any",
                quiz_type: "card",
                ...state.contact,
                ...state.response,
                meta: payload.meta,
                version: state.flow ? 2 : 1
            };

            return leads.create(data).then(lead => {
                commit("setLead", { lead });

                return lead;
            });
        },

        subscribeToNewsletter(context, payload) {
            return emails.subscribe({ ...payload });
        }
    },

    getters: {
        banksLoaded(state) {
            const question = state.questionsV2.find(
                question => question.key === "bank_accounts"
            );

            return question && question.options.length > 1;
        },

        partnersStoresLoaded(state) {
            const question = state.questionsV2.find(
                question => question.key === "stores"
            );

            return question && question.options.length > 1;
        },

        airlinesLoaded(state) {
            const question = state.questionsV2.find(
                question => question.key === "airline"
            );

            return question && question.options.length > 1;
        },

        getNewsCategories(state) {
            return state.categories;
        },

        allPosts(state) {
            let posts = [];

            state.categories.map(category => {
                posts.push(...category.posts);
            });

            return posts.sort(newestSorter);
        },

        loadPosts(state) {
            const category = state.categories.filter(
                category => category.slug.indexOf(state.newsCategoryActive) > -1
            )[0];

            category.posts.sort(newestSorter);

            return category;
        },

        listCategoryAndCards(state) {
            if (state.rankCardActive !== null) {
                state.loading = true;
                let rankCardActive = state.rankCardActive;
                let cardRanks = state.ranks[state.rankCardActive.key];
                if (state.filterListCards.selected === true) {
                    if (state.filterListCards.freeFee) {
                        cardRanks = cardRanks.filter(
                            row =>
                                (row.card.fee !== null && row.card.fee > 0) !==
                                state.filterListCards.freeFee
                        );
                    }
                    if (state.filterListCards.minimumIncome > 0) {
                        cardRanks = cardRanks.filter(
                            row =>
                                row.card.minimum_income !== null &&
                                row.card.minimum_income <=
                                state.filterListCards.minimumIncome
                        );
                    }
                    if (state.filterListCards.acceptInDebit) {
                        cardRanks = cardRanks.filter(
                            row =>
                                row.card.accept_in_debit !== null &&
                                row.card.accept_in_debit ===
                                state.filterListCards.acceptInDebit
                        );
                    }
                    if (state.filterListCards.noMinimumIncome) {
                        cardRanks = cardRanks.filter(
                            row =>
                                (row.card.minimum_income !== null &&
                                    row.card.minimum_income === 0) ===
                                state.filterListCards.noMinimumIncome
                        );
                    }
                    if (state.filterListCards.isInternational) {
                        cardRanks = cardRanks.filter(
                            row =>
                                row.card.is_international ===
                                state.filterListCards.isInternational
                        );
                    }
                }
                //  else {
                //     cardRanks = state.ranks[state.rankCardActive.key].filter(row => row.card.is_international === false)
                // }
                rankCardActive["cards"] = cardRanks;
                state.loading = false;
                return rankCardActive;
            }
        },

        rankCategoriesFeatured(state) {
            let categories = [];
            let index = 0;
            state.rankCategories.forEach(category => {
                categories[index] = category;
                if (state.ranks[category.key]) {
                    let keys = [];
                    let index2 = 0;
                    state.ranks[category.key].forEach(value => {
                        if (index2 < 3) {
                            keys.push(value);
                        }
                        index2++;
                    });
                    categories[index]["featuredCards"] = keys;
                    index++;
                }
            });
            return categories;
        },

        menu(state) {
            return state.menu;
        },

        recomendationSlug(state) {
            return state.recommendation[0].slug;
        },

        currentHasResponse(state) {
            const value = state.response[state.current.key];
            const type = typeof value;

            return (
                (type === "object" && value.length) ||
                (type !== "undefined" && type !== "object")
            );
        },

        firstQuestion(state) {
            return state.questions[0] || null;
        },

        isFirstQuestion(state) {
            return (
                state.flows[state.flow].findIndex(
                    step => step.question === state.current.key
                ) === 0
            );
        },

        isMobile() {
            return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
                navigator.userAgent
            );
        },

        nextQuestion(state, getters) {
            return state.questions[getters.nextQuestionIndex] || null;
        },

        nextQuestionIndex(state) {
            return state.currentIndex === null ? 0 : state.currentIndex + 1;
        },

        previousQuestion(state) {
            return state.questions[state.currentIndex - 1] || null;
        },

        questionsCount(state) {
            return state.questions.length;
        },

        lastQuizResponse(state) {
            if (typeof sessionStorage === "undefined") return {};

            if (Object.keys(state.lastQuizResponse).length > 0) {
                return state.lastQuizResponse;
            }
            try {
                let historic = sessionStorage.getItem("lastQuizResponse")
                    ? JSON.parse(sessionStorage.getItem("lastQuizResponse"))
                    : {};
                if (historic.expires_at) {
                    const expires = historic.expires_at;
                    const lastDate = new Date(
                        expires.year,
                        expires.month - 1,
                        expires.day,
                        expires.hours,
                        expires.minutes
                    );
                    if (lastDate > new Date()) {
                        historic.expires_at = undefined;
                        return historic;
                    }
                }

                sessionStorage.setItem("lastQuizResponse", undefined);
                return {};
            } catch (e) {
                sessionStorage.setItem("lastQuizResponse", undefined);
                return {};
            }
        }
    }
});
