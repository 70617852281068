import { emit } from '@/utils/events'

export default {
    bind: function (el, binding, vnode) {
        el.addEventListener('load', function () {
            const isVertical = el.height > el.width

            if (isVertical) {
                el.toggleAttribute('data-is-vertical')
                emit(vnode, 'is-vertical')
            }
        })
    }
}
